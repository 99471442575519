import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import { CookieService } from 'ngx-cookie-service';
import { UUID } from 'angular2-uuid';
import { UtilsService } from "../../../services/utils.service";
import swal2, { SweetAlertResult } from 'sweetalert2'
import { LoadingLabels } from '../../../enums/loading.enum';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-acl-list',
  templateUrl: './acl-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class AclListComponent implements OnInit {

  @ViewChild('formAcl') aclForm: NgForm;
  //OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  modelsList: any[] = new Array();
  usersList: any[] = new Array();
  eCommerceList: any[] = new Array();
  companiesList: any[] = new Array();
  typesList: any[] = new Array();
  operationsList: any[] = new Array();
  entitiesList: any[] = new Array();

  //PATH CHIAMATE
  urlUsersPath = "/user/users";
  urlEcommercesPath = "/ecommerce/ecommerces";
  urlCompany = "/company/companies";
  urlDeletePath;
  urlTypesPath = "/acl/types";
  urlEntitiesPath = "/acl/entities";
  urlOperationsPath = "/acl/operations";
  errorMessage: string = "";
  machineUUID;
  companyVisible;
  activeStatus;

  visibleFilter;
  defaultValue;
  UpOrDown;
  typeUser;
  isSoluzione1;
  isResale;
  isOperator;

  visibleCreation;

  constructor(
    private utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private cookieService: CookieService,
    private loadingService: LoadingService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall("/acl/list",false,true,1,10);
    this.listService.restURL = "/acl/acls";

    this.typeUser = this.utils.getTypeUser();

    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.isResale = this.utils.isRes(this.typeUser);
    this.isOperator = this.utils.isOp();

    if (this.isResale){
      this.visibleCreation = false
    }else{
      this.visibleCreation = true
    }
    
  }

  async ngOnInit() {
    this.activeStatus = "btn btn-danger btn-circle";
    //CHIAMATE PER SELECT
    await this.getUsers();
    await this.getEcommerces();
    this.companiesList = await this.utils.getCompanyList();
    await this.getTypes();
    await this.getEntities();
    await this.getOperations();

    //CHIAMATA LISTA ELEMENTI
    this.loadElements();

    //CHECK SOLUZIONE1
    this.companyVisible = this.utils.visibleCompanyAndSol(this.companyVisible);
    this.UpOrDown = true;
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  changeStatus(){
    if(this.UpOrDown == true){
      this.UpOrDown = false;
    }else{
      this.UpOrDown = true;
    }
  }



  //FILTRI
  filterSearch(){
    this.dataFilter = this.aclForm.value;
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset(){
    this.aclForm.value.serialNumber = ""
    this.aclForm.value.model = ""
    this.aclForm.value.status = ""
    this.aclForm.value.idResale = ""
    this.aclForm.value.idDesk = ""
    this.listService.visibleFilter = false;
    this.loadElements();
    this.aclForm.reset();
    //this.loadElements();
  }

  //SERVIZI SELECT
  async getUsers() {
    this.usersList = await this.listService.manageListValues(this.urlUsersPath, 'Lista utenti');
  }

  async getEcommerces() {
    this.eCommerceList = await this.listService.manageListValues(this.urlEcommercesPath, 'Lista eCommerce');
  }

  async getTypes() {
    this.typesList = await this.listService.manageListValues(this.urlTypesPath, 'Lista compagnie');
  }

  async getEntities() {
    this.entitiesList = await this.listService.manageListValues(this.urlEntitiesPath, 'Lista entity');
  }

  async getOperations() {
    this.operationsList = await this.listService.manageListValues(this.urlOperationsPath, 'Lista operazioni');
  }

  //SERVIZIO LISTA ELEMENTI
  loadElements(){
    if (this.isOperator && this.utils.getSelectedDesk() != null) {
      this.dataFilter["idDesk"] = this.utils.getSelectedDesk();
    }
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;
        this.logger.log("Response:",response,300);
        this.errorMessage = "";
      },    
      (error) => { 
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  selectRow(dataElement) {
    this.listService.backToList = true;
    localStorage.setItem("dataPassed",JSON.stringify(dataElement));
    this.router.navigate(['/utility/acl/edit']);
  }

  checkStatus(status){
    return this.utils.checkStatus(status);
  }


  changeCompany(idCompany){
    this.filterSearch();
  }

  newAcl() {
    this.listService.backToList = true;
    this.router.navigate(['/utility/acl/new']);
  }
  
  deleteACL(idAcl) {
    var mySelf = this;

    swal2.fire({
      title: 'Eliminazione Regola',
      text: "Sei sicuro di voler eliminare la regola?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,

    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.deleteElement(idAcl);
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })

  }

  deleteElement(idElement) {
    this.urlDeletePath =  "/acl/"+ idElement;

    this.loadingService.presentLoader(LoadingLabels.Delete);
    
    this.logger.log("ID DELETE:",idElement,200);
    
    this.listService.delete(this.urlDeletePath).subscribe(
      (response) => {
     
        if (response.outcome.success === true) {
               
          this.logger.log("Response value",response.value,200);
          swal2.fire("Eliminazione completata", "La regola è stato eliminata con successo","success").then((ev: SweetAlertResult) => {
            if (ev.value) {
              this.loadElements();
            }
          });
        } else {   
          this.logger.log("Response",response,200);
          this.errorMessage =  response.outcome.code +  response.outcome.description
          swal2.fire("Attenzione", this.errorMessage,"warning");
        }
      },    
      (error) => {
        this.logger.log("Error",error,200);
        swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.Riprova più tardi","error");
      }
    );
    
  }

  
  getUserName(idUser){
    var stringUserName;
    for(let resaleModel of this.usersList){
      if(resaleModel.id == idUser){
        stringUserName = resaleModel.legalBusinessName;
      }
    }
    return stringUserName;

  }

  getECommerceName(idECommerce) {
    var stringECommerceName;
    for(let deskModel of this.eCommerceList) {
      if (deskModel.id == idECommerce) {
        stringECommerceName = deskModel.name;
        break;
      }
    }
    return stringECommerceName;
  }

  getBusinessName(id){
    var string;
    for(let company of this.companiesList)
    if(company.idCompany == id){
      return string = company.legalBusinessName
    }
  }
 
  getType(nameType) {
    var stringTypeName;
    for(let type of this.typesList) {
      if (type == nameType) {
        stringTypeName = type;
        break;
      }
    }
    return stringTypeName;
  }

  getEntity(nameEntity) {
    var stringEntityName;
    for(let type of this.entitiesList) {
      if (type == nameEntity) {
        stringEntityName = type;
        break;
      }
    }
    return stringEntityName;
  }

  getOperation(nameOp) {
    var stringOperationName;
    for(let type of this.operationsList) {
      if (type == nameOp) {
        stringOperationName = type;
        break;
      }
    }
    return stringOperationName;
  }

}

