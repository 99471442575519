<div class="switch-button">
    <label for="validityPeriod">{{ config?.title || '-' }}</label>
    <div class="btn-group" role="group" aria-label="validityPeriod">
        <button type="button" class="btn"
            [ngClass]="{'active btn-primary': active == 0}"
            (click)="toggle()">
            {{ config?.btn1?.label || '-' }}
        </button>
        <button type="button" class="btn"
            [ngClass]="{'active btn-success': active == 1}"
            (click)="toggle()">
            {{ config?.btn2?.label || '-' }}
        </button>
    </div>
</div>