import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ListService, SubmitActionType } from "../../../services/list.service";
import { LoggingService } from "../../../services/logging.service";
import { UtilsService } from "../../../services/utils.service";
import { BsDatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import { defineLocale } from "ngx-bootstrap/chronos";
import { itLocale } from "ngx-bootstrap/locale";
import { listLocales } from "ngx-bootstrap/chronos";
import { DatePipe } from "@angular/common";
import { IConfigValue } from "../../../interfaces/configuration.interface";
import { LoadingLabels } from "../../../enums/loading.enum";
import { LoadingService } from "../../../services/loading.service";
import { SwitchConfig } from "../../../common/components/switch-button/switch-button.component";
import swal2, { SweetAlertResult } from "sweetalert2";
defineLocale("it", itLocale);

@Component({
  selector: 'app-ticket-promotions-manage',
  templateUrl: './ticket-promotions-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class TicketPromotionsManageComponent implements OnInit {
  locale = "it";
  locales = listLocales();
  //testo in HTML per tipo pagina
  kindTitle;
  kindManage;
  //URL servizio da chiamare, se post o put dipenderà da dove arriviamo
  serviceToCallPath = "/ticket/promotion";
  //form html
  bsConfig: Partial<BsDatepickerConfig>;
  //oggetto contenente dati component
  ticketFaresData: any;
  stops: any[] = new Array();
  filterStops: any[] = new Array();
  lines: any[] = new Array();
  companies: any[] = new Array();
  blocks: any[] = new Array();
  pathListBlocks = "/block/blocks";
  zones: any[] = new Array();
  pathListZones = "/zone/zones";
  categories: any[] = new Array();
  pathListCategories = "/category/categories-grouped?type="; // filtro per ticket
  originStops: any[] = new Array();
  daterangepickerModel: Date[];
  bsRangeValue: Date[];
  sol1OrSa = false;
  tripsResponse: any[] = new Array();
  counterRules: any[] = new Array();
  pathListStops = "/stop/stops/v2";
  errorMessage;
  stopsListPath;
  lineListPath;
  pathToCallCompaniesPath;
  urlChannelPath;
  tempLineValue;

  // gestione categorie
  ticketTypes: IConfigValue[];
  typeTicket: string; // T o H o altri tipi in futuro, presi dalla Utils per ogni compagnia
  groupCategories;
  groupCategoryId;
  passengers: any[] = new Array();

  pathRules = "/ticket/promotion/promotions/rules/"
  destinationStops: any[] = new Array();
  channelArray: any[] = new Array();
  dataChannel: any[] = new Array();
  colorTheme = "theme-orange";
  model = {
    code: "",
    name: "",
    idCompany: 1
  };
  pathListTrips = "/trip/trips/v2";
  originalStops: any[] = new Array();
  validity: string;
  switchButtonConfig: SwitchConfig;
  nmaxForTrip: boolean;

  constructor(
    public utils: UtilsService,
    private localeService: BsLocaleService,
    private route: ActivatedRoute,
    private logger: LoggingService,
    public listService: ListService,
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
    this.localeService.use(this.locale);
    
    //Se creo nuova promozione
    if (this.route.snapshot.toString().indexOf("new") != -1) {
      this.kindTitle = "Nuova";
      this.tempLineValue = "New";
      this.kindManage = 1;
    } else {
      //se modifico promozione
      this.kindTitle = "Modifica";
      this.kindManage = 0;
      if (localStorage.getItem("dataPassed") == "") {
        this.router.navigate(["/voucher/promotion"]);
        return;
      }

      this.ticketFaresData = JSON.parse(localStorage.getItem("dataPassed"));

      let dateString = this.ticketFaresData['dateFrom']
      let newDate = new Date(dateString);
      let dateStringTo = this.ticketFaresData['dateTo']
      let newDateTo = new Date(dateStringTo);

      var date = new Date();
      var firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
      var lastDay = new Date(newDateTo.getFullYear(), newDateTo.getMonth(), newDateTo.getDate());
      this.bsRangeValue = [firstDay, lastDay];

      this.channelArray = this.ticketFaresData['ticketChannel'];

      this.getRules(this.ticketFaresData['idTicketPromotion']);

      this.nmaxForTrip = this.ticketFaresData.nmaxForTrip == 'T' ? true : false;

      localStorage.setItem("dataPassed", ""); //svuoto dato in localstorage
    }
    this.typeTicket = this.utils.manageTicketTypes()[0].id;
    this.switchButtonConfig = this.utils.configureFareValiditySwitchBtn(this.ticketFaresData?.dateType);
  }

  @ViewChild("ticketRatesForm")
  ticketRatesForm: NgForm;

  async ngOnInit() {
    this.listService.resetList();
    this.ticketTypes = this.utils.manageTicketTypes();
    this.listService.backToList = true;
    this.listService.listRouting = "/voucher/promotion";
    this.stopsListPath = "/stop/stops";
    this.lineListPath = "/route/routes";
    this.pathToCallCompaniesPath = "/company/companies";
    this.urlChannelPath = "/ticket/channel/channels";

    await this.getSelectStops();
    await this.getSelectLines();
    await this.getCategories();
    await this.getBlocks();
    await this.getZones();
    
    this.getCompanies();
    this.getStopsFrom();
    this.getChannel();
    this.sol1OrSa = this.utils.checkSadminOrSol1();
  }

  removeRules(data) {
    this.counterRules = this.counterRules.filter(obj => obj !== data);
  }

  async getStopsFrom(): Promise<void> {
    this.originStops = await this.listService.manageListValues(this.pathListStops, 'Lista fermate partenza');
  }

  async getRules(id: number): Promise<void> {
    this.counterRules = await this.listService.manageListValues(this.pathRules + id, 'Lista rules');
    this.counterRules.forEach(c => {
      if (c.routeId != null) {
        c.kindName = "LINEA"
      }
      if (c.blockId != null) {
        c.kindName = "TRATTA"
      }
      if (c.originId != null) {
        c.kindName = "TRA DUE SPECIFICHE FERMATE"
      }
      if (c.originZoneId != null) {
        c.kindName = "ZONA"
      }
      if (c.tripId != null) {
        c.kindName = "CORSA"
      }
      if (c.tag != null) {
        c.kindName = "TAG"
      }
      if (this.isRuleForEveryone(c)) {
        c.kindName = "PER TUTTI"
      }
    });
  }

  isRuleForEveryone(rule) {
    if (rule.routeId == null && rule.blockId == null && rule.originId == null && rule.destinationId == null && rule.tripId == null) {
      return true
    }
    return false
  }

  addNewRules() {

    var model = {
      routeId: null,
      blockId: null,
      originId: null,
      destinationId: null,
      originZoneId: null,
      destinationZoneId: null,
      tripId: null,
      choseKind: null,
      decided: false,
      kindName: null,
      tag: null
    };
    this.counterRules.push(model);
  }

  confirmNewRules() {
    this.counterRules[this.counterRules.length - 1]["decided"] = true;
  }

  isRulesAllConfirmed() {
    var confirmed = true;

    if (!!this.counterRules.length) {
      this.counterRules.forEach(function(rule) {
        if (rule.decided == false) {
          confirmed = false;
        }
      });
    } else {
      confirmed = false;
    }

    return confirmed;
  }

  async getStopsTo(idStop: number): Promise<void> {
    this.destinationStops = await this.listService.manageListValues(this.pathListStops + "/" + idStop, 'Lista fermate fine');
  }

  choseKindRules(value, data) {
    data["choseKind"] = value;
    var name = "";

    if (value == "1") {
      data["kindName"] = "LINEA";
    } else if (value == "2") {
      data["kindName"] = "TRA DUE SPECIFICHE FERMATE";
    } else if (value == "3") {
      data["kindName"] = "TRATTA";
    } else if (value == "4") {
      data["kindName"] = "CORSA";
    } else if (value == "5") {
      data["kindName"] = "TAG";
    } else if (value == "6") {
      data["kindName"] = "PER TUTTE LE LINEE/CORSE";
    } else if (value == "7") {
      data["kindName"] = "ZONE";
    }
  }

  async onChangeType(value) {
    this.typeTicket = value;
    await this.getCategories();
  }

  onChangeStop(id, data) {
    data["destinationId"] = null;
    data["destinationZoneId"] = null;
    data["originZoneId"] = null;
    data["tripId"] = null;
    data["blockId"] = null;
    data["routeId"] = null;
    data["originId"] = id;
    data["tag"] = null;
    this.getStopsTo(id);
  }

  onChangeStopTrip(id) {
    this.getStopsTo(id);
  }

  onChangeStopDestination(id, data) {
    data["destinationId"] = id;
    data["destinationZoneId"] = null;
    data["originZoneId"] = null;
    data["tripId"] = null;
    data["blockId"] = null;
    data["routeId"] = null;
    data["tag"] = null;
  }

  onChangeZoneOrigin(id, data) {
    data["destinationId"] = null;
    data["originZoneId"] = id;
    data["tripId"] = null;
    data["blockId"] = null;
    data["routeId"] = null;
    data["originId"] = null;
  }

  onChangeZoneDestination(id, data) {
    data["originId"] = null;
    data["destinationId"] = null;
    data["destinationZoneId"] = id;
    data["tripId"] = null;
    data["blockId"] = null;
    data["routeId"] = null;
  }

  onChangeBlockId(id, data) {
    data["originId"] = null;
    data["destinationId"] = null;
    data["destinationZoneId"] = null;
    data["originZoneId"] = null;
    data["tripId"] = null;
    data["blockId"] = id;
    data["routeId"] = null;
    data["tag"] = null;
  }

  onChangeTripId(id, data) {
    data["tripId"] = id;
    data["originId"] = null;
    data["destinationId"] = null;
    data["destinationZoneId"] = null;
    data["originZoneId"] = null;
    data["blockId"] = null;
    data["routeId"] = null;
    data["tag"] = null;
  }

  choseKindRoute(value, data) {
    data["originId"] = null;
    data["destinationId"] = null;
    data["destinationZoneId"] = null;
    data["originZoneId"] = null;
    data["tripId"] = null;
    data["blockId"] = null;
    data["routeId"] = value;
    data["tag"] = null;
  }

  onInputTag(input, data) {
    data["tripId"] = null;
    data["originId"] = null;
    data["destinationId"] = null;
    data["destinationZoneId"] = null;
    data["originZoneId"] = null;
    data["blockId"] = null;
    data["routeId"] = null;
    data["tag"] = input;
  }

  getRouteName(id) {
    var string = id;

    for (let r of this.lines) {
      if (r.routeId == id) {
        string = r.routeLongName;
      }
    }
    return string;
  }

  getStopName(id) {


    var string = id;

    for (let s of this.originStops) {
      if (s.stopId == id) {
        string = s.stopName;
      }
    }
    return string;
  }

  async getChannel() {
    this.dataChannel = await this.listService.manageListValues(this.urlChannelPath, 'Lista canali');
  }


  getBlockName(id) {
    var string = id;

    for (let b of this.blocks) {
      if (b.id == id) {
        string = b.description;
      }
    }
    return string;
  }

  getZoneName(id) {
    var string = id;

    for (let b of this.zones) {
      if (b.zoneId == id) {
        string = b.description;
      }
    }
    return string;
  }

  getForAll(c) {
    if (c.choseKind == 6)
      return "Verrà applicata la tariffa a qualunque linea della compagnia"
  }


  isChecked(event) {
    if (event.target.checked) {
    } else {
      this.ticketRatesForm.value.toStop = null;
      this.filterStops = [];
    }
  }

  findList(idLine) {
    if (this.tempLineValue != "New") {
      this.filterStops = [];
      for (let stop of this.stops) {
        if (stop.routeShortName == idLine) {
          this.filterStops.push(stop);
        }
      }
    }
  }

  onChangeLine(idLine) {
    const string = idLine.substring(3);
    this.filterStops = [];
    for (let stop of this.stops) {
      if (stop.routeShortName == string) {
        this.filterStops.push(stop);
      }
    }
  }

  async getSelectStops() {
    this.stops = await this.listService.manageListValues(this.stopsListPath, 'Lista aziende');
    
    if (this.stops) {
      this.findList(this.tempLineValue);
    }
  }

  async getSelectLines() {
    this.lines = await this.listService.manageListValues(this.lineListPath, 'Lista linee');
  }

  getCompanies() {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe(
      response => {
        this.companies = response.results;
        this.logger.log("Lista aziende", this.companies, 300);
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  // Legge le categorie previste dall'azienda
  async getCategories(): Promise<void> {
    return new Promise((res, rej) => {
      this.listService.getListSelect(this.pathListCategories + this.typeTicket).subscribe({
        next: response => {
          this.groupCategories = response.results;
          this.groupCategoryId = 0;
          this.loadCategories();
          this.logger.log("Lista categorie ", this.groupCategories, 300);
          res();
        },
        error: error => {
          this.logger.log("Error", error, 200);
          rej();
        }
      });
    });
  }

  //  dato il groupCategory carica le categorie da selezionare
  loadCategories() {
    const group = this.kindManage === 0 ? [this.groupCategories[this.groupCategoryId]] : this.groupCategories;
    this.categories = group.map(g => g?.categories?.map(c => { return { ...c, groupedName: `${g.name} - ${c.description}` } })).reduce((acc, val) => acc.concat(val), [])

    if (this.kindManage === 0) {
      const category = this.categories.find(c => c.categoryId === this.ticketFaresData['idCategory']);
      if (category) {
        this.ticketRatesForm.controls['categoriesList'].patchValue([category]);
      }
    }
  }

  onChangeBtn(index) {
    this.groupCategoryId = index;
    this.logger.log("Cat selezionata", this.groupCategoryId, 200);
    this.loadCategories();
  }

  async getBlocks() {
    this.blocks = await this.listService.manageListValues(this.pathListBlocks, 'Lista categorie');
  }


  async getZones() {
    this.zones = await this.listService.manageListValues(this.pathListZones, 'Lista zone');
  }


  setIdChannel(kind) {
    let i = 0;
    for (let val of this.channelArray) {

      if (val.toString() == kind.toString()) {
        this.channelArray.splice(i, 1);
        return;
      }
      i++;
    }
    this.channelArray.push(kind.toString());
  }


  isCheckChannel(idChannel) {
    for (let i of this.channelArray) {
      if (i.toString() == idChannel.toString()) return true;
    }
    return false;
  }

  onSubmit(): void {
    swal2.fire({
      title: "Operazione in corso...",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Indietro",
      text: "Stai creando una promozione con " + this.counterRules.length + " regola/e. Continuare?",
      confirmButtonText: "Procedi"
    }).then((ev: SweetAlertResult) => {
      if (ev.value) {
        var dateFrom = new DatePipe("en-EN").transform(
          this.bsRangeValue[0],
          "yyyy-MM-dd"
        );
        var dateTo = new DatePipe("en-EN").transform(
          this.bsRangeValue[1],
          "yyyy-MM-dd"
        );

        const requestData: any = new Object();
        requestData.dateFrom = dateFrom;
        requestData.dateTo = dateTo;
        requestData.description = this.ticketRatesForm.value.description;
        requestData.price = this.ticketRatesForm.value.price;
        requestData.idTicketChannel = this.channelArray;
        requestData.promotionRules = this.counterRules;
        requestData.nMax = this.ticketRatesForm.value.nMax;
        requestData.dateType = this.validity;
        requestData.nmaxForTrip = this.nmaxForTrip ? 'T' : null;

        if (this.kindManage) {
          requestData.type = this.ticketRatesForm.value.typology;
          requestData.format = this.ticketRatesForm.value.format;
          requestData.categoryIds = this.ticketRatesForm.value.categoriesList.map(c => c.categoryId);
        } else {
          requestData.promotionId = this.ticketFaresData['idTicketPromotion'];
          requestData.categoryId = this.ticketFaresData['idCategory'];
          requestData.format = this.ticketFaresData['format'];
          requestData.type = this.ticketFaresData['typology'];
        }

        this.listService.manageSubmitAction(
          this.kindManage ? SubmitActionType.CREATE : SubmitActionType.EDIT,
          requestData,
          this.serviceToCallPath,
          '/voucher/promotion',
          'promozione ticket'
        );
      }
    }, _ => {
      swal2.close()
    });

  }

  returnToList() {
    this.router.navigate(["/voucher/promotion"]);
  }

  choseTripList(stopto, stopfrom) {
    const dataRequest = {};
    dataRequest["origin"] = stopfrom;
    dataRequest["destination"] = stopto;

    var date = new Date();
    dataRequest["dateA"] = new DatePipe("en-EN").transform(date, "yyyy-MM-dd");

    this.getTrips(dataRequest);
  }

  getTrips(dataRequest): void {
    this.loadingService.presentLoader(LoadingLabels.Default);

    this.listService.newObject(dataRequest, this.pathListTrips).subscribe(
      response => {
        swal2.close();
        this.tripsResponse = response.andata;
      },
      error => {
        swal2.close();
        this.logger.log("Error", error, 200);
      }
    );
  }

  validitySwitch(val: string): void {
    this.validity = val;
  }

}

