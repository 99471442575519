import { Component, OnInit, ViewChild} from '@angular/core';
import { Router , ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import { UtilsService } from '../../../services/utils.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { IConfigValue } from '../../../interfaces/configuration.interface';
import swal2 from 'sweetalert2'
import { LoadingLabels } from '../../../enums/loading.enum';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-voucher-new',
  templateUrl: './voucher-new.component.html',
  styleUrls: ['../../../app.component.css']
})
export class VoucherNewComponent implements OnInit {

  @ViewChild('formVoucherNew') formVoucherNew: NgForm;

  public locale = 'it';

  channelList: any[] = new Array();
  codetypeList: any[] = new Array();
  categoriesList: any[] = new Array();

  routeList = null;
  tripList = null;
  ticketFaresData = null;

  categories;
  groupCategories;
  groupCategoryId;
  ticketTypes: IConfigValue[];
  typeTicket: string; // T o H o altri tipi in futuro, presi dalla Utils per ogni compagnia

  urlChannelsPaths = '/voucherEmitChannel/channels?enabledlots=true'
  urlCodetypesPaths = '/voucherEmitChannel/code-types'
  pathListCategories = '/category/categories-grouped?type='; // filtro per ticket

  serviceCreateVoucherPath = '/voucher/make'
  serviceExportVoucherPath = '/voucher/export/'
  errorMessage: string;

  pathListRoutes = '/route/routes'
  showTrip = false;
  urlLine = '/route/routes'

  fromComponent = ''
  modDirectionList: any[];
  lineList: any;
  modTicketRoute: any;
  pathRoutesList = '/route/routes';
  modTicketTrip: any = 'null';
  modStringDataLine: any;
  pathLinetripList = '/trip/linetriplist'
  modTicketDir: any;
  modTripList: any[] = new Array();
  requestData: any;
  ticketOptionId: string;
  partner: any = null;
  voucherChannel: string;
  selectedCodeType: string;

  colorTheme = "theme-orange";
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(public utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private activeRouter: ActivatedRoute,
    private loadingService: LoadingService
  ) {
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
  }

  async ngOnInit() {
    this.ticketTypes = this.utils.manageTicketTypes();
    this.typeTicket = this.ticketTypes[0].id;
    await this.getAllChannel()
    await this.getAllCodetype()
    await this.getCategories();
    await this.getAllRoutes();
    await this.getLineList();

    this.activeRouter.params.subscribe( (params: Params) => {
      this.fromComponent = params['caller']
      this.listService.backToList = true;
      /**
       * caller = 1 -> Lot
       * caller = 2 -> List
       */
      if ( this.fromComponent === '1') {
        this.listService.listRouting = '/voucher/lot';
      }
      if ( this.fromComponent === '2') {
        this.listService.listRouting = '/voucher/list';
      }
    })

    this.showTrip = this.utils.manageShowTrips();
  }

  async getAllChannel() {
    this.channelList = await this.listService.manageListValues(this.urlChannelsPaths, 'Lista channels');
  }

  async getAllCodetype() {
    this.codetypeList = await this.listService.manageListValues(this.urlCodetypesPaths, 'Lista tipologie');
  }

  // dato il groupCategory carica le categorie da selezionare
  loadCategories() {
    this.categories = this.groupCategories[this.groupCategoryId].categories;
    // this.createPassengers();
  }

  // Legge le categorie previste dall'azienda
  async getCategories(): Promise<void> {
    this.groupCategories = await this.listService.manageListValues(this.pathListCategories + this.typeTicket, 'Lista categorie');
    
    if (this.groupCategories) {
      this.groupCategoryId = 0;
      this.loadCategories();
    }
  }

  async getAllRoutes() {
    this.routeList = await this.listService.manageListValues(this.pathListRoutes, 'Lista rotte');
  }

  async getLineList() {
    this.lineList = await this.listService.manageListValues(this.urlLine, 'Lista linee');
  }

  onChangeType(value) {
    this.typeTicket = value;
    this.logger.log( 'onChangeType' , value , 200 )
    this.getCategories();
  }

  onChangeRoute(value) {
    this.modTicketRoute = value;
    if ( value !== 'all' ) {
      this.getDirections();
    }
  }

  onChangeBtn(index) {
    this.logger.log( 'onChangeBtn' , index , 200 )
    this.groupCategoryId = index;
    this.logger.log('Cat selezionata', this.groupCategoryId, 200);
    this.loadCategories();
  }

  async getDirections() {
    this.modDirectionList = [];
    let stringPath = '?routeId=' + this.lineList[this.modTicketRoute].routeId;
    this.modDirectionList = await this.listService.manageListValues(this.pathRoutesList + stringPath, 'Ticket');
  }

  onDateChangedModal(stringData: Date) {
    this.modTicketTrip = null;
    if (stringData) {
      this.modStringDataLine = this.utils.convertDateToISO(stringData);
      this.getHourTrip();
    }
  }

  getDirection(indexDir) {
    this.modTicketDir = indexDir;
    this.getHourTrip();
  }

  async getHourTrip() {
    this.modTripList = []
    const filterPAth =
      '?date=' +
      this.modStringDataLine +
      '&line=' +
      this.lineList[this.modTicketRoute].routeId +
      '&directionId=' +
      this.modDirectionList[this.modTicketDir].directionId + '&paging=false';

    this.modTripList = await this.listService.manageListValues(this.pathLinetripList + filterPAth, 'Lista corse');
  }

  ticketToModified(arg0: string, ticketToModified: any, arg2: number) {
    throw new Error('Method not implemented.');
  }

  returnToList() {
    if ( this.fromComponent === '1') {
      this.router.navigate(['/voucher/lot']);
    }
    if ( this.fromComponent === '2') {
      this.router.navigate(['/voucher/list']);
    }
  }

  onSubmit() {
    // cerco il partner in configurazione
    this.partner = this.listService.partners3rd.find(x => x.name === this.formVoucherNew.value.selectChannel)
    this.logger.log( 'partner' , this.partner , 200)
    let activateVoucher = true;
    // trovato il partner controllo la tipologia di attivazione presente e la inserisco nei dati per creare voucher
    if ( this.partner ) { activateVoucher = this.partner.activateVoucher; }
    this.logger.log( 'onSubmit() - formVoucherNew' , this.formVoucherNew.value , 200)

    this.requestData = {
      'idCategory' : this.formVoucherNew.value.categoryId ? this.formVoucherNew.value.categoryId : null,
      'numberOfCodes' : this.formVoucherNew.value.numberOfCodes ,
      'codeType' : this.formVoucherNew.value.codeType ,
      'channel' : this.formVoucherNew.value.selectChannel ,
      'price' : this.formVoucherNew.value.price ,
      'activate' : activateVoucher ,
      'validFrom' : this.formVoucherNew.value.dateFrom ? this.utils.convertDateToISO(this.formVoucherNew.value.dateFrom) + ' 00:00:00' : null ,
      'validTo' : this.formVoucherNew.value.dateTo ? this.utils.convertDateToISO(this.formVoucherNew.value.dateTo) + ' 23:59:59' : null ,
      'routeId' : this.formVoucherNew.value.routeId === 'all' || this.formVoucherNew.value.routeId === undefined
        ? null
        : this.lineList[this.modTicketRoute].routeId,
      'tripId' : this.formVoucherNew.value.tripId ? this.formVoucherNew.value.tripId : null,
      'description' : this.formVoucherNew.value.description ? this.formVoucherNew.value.description : '',
      'prefixVoucher': this.formVoucherNew.value.prefix ? this.formVoucherNew.value.prefix : null
    }

    this.logger.log( 'requestData' , this.requestData, 200 )

    if ( this.partner ) {
      if ( this.partner.needTicketOptionId ) {
        swal2.fire({
          title: 'TICKET OPTION ID',
          input: 'text'
        }).then( (code) => {
          this.ticketOptionId = code.value.toString()
          this.createVoucher(this.formVoucherNew.value.selectChannel);
        });
      } else {
        this.createVoucher(this.formVoucherNew.value.selectChannel)
      }
    } else {
      this.createVoucher(this.formVoucherNew.value.selectChannel)
    }

  }

  createVoucher(emit_channel) {
    this.listService.newObject( this.requestData , this.serviceCreateVoucherPath).subscribe( (response) => {
      if (response.outcome.success === true) {
        let arrayGeneratedVoucherId = new Array()
        let dataForExport
        for ( let i of response.results) {
          this.logger.log( 'Generated Vouchers' , i.id , 200)
          arrayGeneratedVoucherId.push(i.id)
        }

        dataForExport = {
          'idCompany' : null,
          'optionId' : null,
          'voucherIds' : arrayGeneratedVoucherId
        }

        if ( this.partner ) {
          if ( this.partner.needTicketOptionId ) {
            dataForExport.optionId = this.ticketOptionId
          }
        }
        
        this.loadingService.presentLoader(LoadingLabels.Operation);

        this.listService.getDoc( this.serviceExportVoucherPath + emit_channel , dataForExport ).subscribe((resp) => {
          if (resp) {
            let blob = new Blob([resp], { type: 'application/vnd.ms-excel' });
            let ieEDGE = navigator.userAgent.match(/Edge/g);
            let ie = navigator.userAgent.match(/.NET/g); // IE 11+
            let oldIE = navigator.userAgent.match(/MSIE/g);

            let fileName = ''
            let currentDate = new Date()
            let day = currentDate.getDate()
            let month = currentDate.getMonth() + 1
            let year = currentDate.getFullYear()
            fileName = day + '/' + month + '/' + year + '_export.xls';

            if (ie || oldIE || ieEDGE) {
              (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
            } else {
              let elem = window.document.createElement('a');
              elem.href = window.URL.createObjectURL(blob);
              elem.download = fileName;
              document.body.appendChild(elem);
              elem.click();
              document.body.removeChild(elem);
            }

            swal2.fire('Creazione del nuovo lotto di voucher terminata con successo', '', 'success');
            this.router.navigate(['/voucher/lot']);
          } else {
            this.errorMessage = 'Ops! Si è verificato un errore imprevisto durante l\'esportazione dei voucher';
            swal2.fire('Errore', 'Ops! Si è verificato un errore imprevisto', 'error');
          }
        })

        // this.router.navigate(['/voucher/lot']);
        // swal2.fire('Creazione del nuovo lotto di voucher terminata con successo', '', 'success');
      } else {
        this.errorMessage = 'Ops! Si è verificato un errore imprevisto';
        swal2.fire('Errore', 'Ops! Si è verificato un errore imprevisto', 'error');
      }
    } , error => {
      this.logger.log('Error', error, 200);
      this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      swal2.fire( 'Errore', 'Spiacente, si è verificato un errore tecnico.', 'error' );
    })
  }

  /**
   * Method that manages the expected behaviour on code type select, that must be valorized
   * to its option 'QRCODE' when PRATICKO is selected as channel.
   */
  manageChannelChange() {
    if (this.voucherChannel === 'PRATICKO') {
      this.selectedCodeType = 'QRCODE';
    } else {
      this.selectedCodeType = null;
    }
  }

}
