import { Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import swal2, { SweetAlertResult } from 'sweetalert2'
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UtilsService } from '../../../services/utils.service';
import { LoadingLabels } from '../../../enums/loading.enum';
import { LoadingService } from '../../../services/loading.service';

enum ExportOperation {
  exportLot,
  printLot
}

@Component({
  selector: 'app-voucher-lotti',
  templateUrl: './voucher-lotti.component.html',
  styleUrls: ['../../../app.component.css']
})

export class VoucherLottiComponent implements OnInit {

  @ViewChild('formPrintLot') printLotForm: NgForm;

  dataFilter: any[] = new Array();
  dataList: any[] = new Array();
  companiesList: any[] = new Array();
  printersList: any[] = new Array();
  vouchersLotList: any[] = new Array();

  showList: any[] = new Array();
  prepareShowList: any[] = new Array();

  typologyList: any[] = new Array();

  errorMessage = '';
  visibleFilter;
  defaultValue;
  UpOrDown;
  typeUser;
  isSoluzione1;
  isMobileOperator;
  roleUser;
  dataForPrint;


  urlCompaniesPath = '/company/companies';
  urlPrintersPath = '/printer/printers';
  serviceExportVoucherPath = '/voucher/export/'
  servicePrintVoucherPath = '/voucher/print';

  modalRef: BsModalRef;

  constructor(
    private utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private modalService: BsModalService,
    private loadingService: LoadingService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall('/voucher/list', false, true, 1, 15);
    this.listService.restURL = '/voucher/lots';
  }

  async ngOnInit() {
    this.loadElements();
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.getCompanies();
    await this.getPrinters();
  }

  pageChange(page) {
    this.listService.page = page;
    // this.logger.log( 'page' , page , 200 )
    this.loadElements();
    // this.logger.log( 'prepareShowList on pageChange' , this.prepareShowList , 200 )
    // this.showList = this.getPageFromArray( page , this.prepareShowList )
  }

  changeStatus() {
    if (this.UpOrDown === true) {
      this.UpOrDown = false;
    } else {
      this.UpOrDown = true;
    }
  }

  // SERVIZIO LISTA ELEMENTI
  loadElements() {
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {

        this.dataList = []
        this.dataList = response.results;
        this.listService.totalRows = response.total;
        this.errorMessage = '';
      },
      (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    );
  }

  //  SERVIZIO LISTA ELEMENTI
  loadVoucherByDateLot(dateLot, otherData, typeExpOp) {

    //  azzero la lista
    this.vouchersLotList = [];
    //  controllo i filtri usati, passando data lotto
    let voucherFilter: any[] = new Array();
    voucherFilter['lotCode'] = dateLot.lotCode;
    this.logger.log( 'voucherOfLot - loadElements' , voucherFilter , 200 )
    this.listService.restURL = '/voucher/vouchers';
    this.listService.getListFilter(voucherFilter).subscribe(
      (response) => {
        this.vouchersLotList = []
        this.listService.totalRows = response.total;

        this.vouchersLotList = response.results
        this.logger.log('vouchersLotList', this.vouchersLotList, 200);
        this.listService.restURL = '/voucher/lots';
        switch (typeExpOp) {
          case ExportOperation.exportLot:
            this.lotExport(this.vouchersLotList, otherData)
            break;
          case ExportOperation.printLot:
            this.lotPrint(this.vouchersLotList, otherData);
            break;
        }
      },
      (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
        this.listService.restURL = '/voucher/lots';
      }
    );
  }

  groupByLot( list, keyGetter ) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
  }

  selectUnique( arrayList ) {
    let arrayUnique = new Array()
    for ( let element of arrayList ) {
      if ( arrayUnique.find( x => x === element.tsCreated ) === undefined ) {
        arrayUnique.push( element.tsCreated )
      }
    }
    return arrayUnique
  }

  selectRow(dataElement) {
    this.router.navigate(['/voucher/list/' + btoa(dataElement.lotCode)])
  }

  newTicketList() {
    this.listService.backToList = true;
    this.router.navigate(['/voucher/new' , '1']);
  }

  getButtonCircle(statusNumber) {
    let buttonColor;
    if (statusNumber === '1') {
   return   buttonColor =  'btn btn-primary btn-circle'
    }
    if (statusNumber === '0') {
      return   buttonColor =  'btn btn-danger btn-circle'
       }
  }

  getStatusDescription(statusNumber) {
    let buttonColor;
    if (statusNumber === '1') {
   return   buttonColor =  'Attivo'
    }
    if (statusNumber === '2') {
      return   buttonColor =  'Non Attivo'
       }
  }

  getCompanyName(id) {
    for (let company of this.companiesList) {
      if (company.idCompany === id) {
        return company.legalBusinessName;
      }
    }
  }

  getCompanies() {
    this.listService.getListSelect(this.urlCompaniesPath).subscribe((response) => {
      this.companiesList = response.results;
      this.logger.log('Lista Aziende', this.typologyList, 300);
    },
      (error) => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  async getPrinters() {
    this.printersList = await this.listService.manageListValues(this.urlPrintersPath, 'Lista stampanti');
  }

  getPageFromArray( page , array = null ) {
    if ( array === null ) {
      array = this.dataList
    }
    this.logger.log( 'getPageFromArray - array' , array , 200 )
    page--
    return array.slice(page * 15, (page + 1) * 15)
  }

  // stampa con la stampante desk i voucher selezionati
  lotPrint(listVouchersOfLot, template: TemplateRef<any>) {

    let arrayGeneratedVoucherId = new Array()
    for ( let i of listVouchersOfLot) {
      this.logger.log( 'Generated Vouchers' , i , 200)
      arrayGeneratedVoucherId.push(i.id)
    }
    this.dataForPrint = {
      'printerSerialNumber' : null,
      'voucherIds' : arrayGeneratedVoucherId
    }

    this.openModal(template)

  }

  confirmPrint() {

    this.dataForPrint.printerSerialNumber = this.printLotForm.value.printerSerialNumber;

    this.loadingService.presentLoader(LoadingLabels.Default);

    this.listService.newObject(this.dataForPrint, this.servicePrintVoucherPath).subscribe(
      response => {
        if ( response.outcome.success) {
          swal2.fire('Stampa terminata', 'I voucher sono stati stampati con successo', 'success');
        } else {
          swal2.fire('Errore di stampa', 'I voucher non sono stati stampati. Seleziona la stampante corretta e riprova', 'warning');
        }
        this.closeModal();
      },
      error => {
        swal2.fire('Errore', error, 'error');
        this.logger.log('Error', error, 200);
      }
    );
  }

  // export lotto voucher da Praticko
  lotExport( listVouchersOfLot, emit_channel ) {

      // controllo il partner
      let partner = this.listService.partners3rd.find(x => x.name === emit_channel)

      let arrayGeneratedVoucherId = new Array()
      let dataForExport;

      for ( let i of listVouchersOfLot) {
        this.logger.log( 'Generated Vouchers' , i , 200)
        arrayGeneratedVoucherId.push(i.id)
      }

      dataForExport = {
        'idCompany' : null,
        'optionId' : null,
        'voucherIds' : arrayGeneratedVoucherId
      }

      // se esiste un partner associato che ha bisogno di codice, glielo chiede così lo importa nel file
      if ( partner ) {
        if ( partner.needTicketOptionId ) {
          swal2.fire({
            title: 'TICKET OPTION ID',
            input: 'text'
          }).then( (code) => {
            dataForExport.optionId = code.value;
            this.serviceLotExport(dataForExport, emit_channel);
          });
        } else {
          this.serviceLotExport(dataForExport, emit_channel);
        }
      } else {
        swal2.fire('Errore', 'Attenzione, nessun partner configurato a sistema. Verifica e riprova', 'error');
      }
  }

  serviceLotExport(dataForExport, emit_channel ) {
    this.listService.getDoc( this.serviceExportVoucherPath + emit_channel, dataForExport ).subscribe((resp) => {
      if (resp) {
        let blob = new Blob([resp], { type: 'application/vnd.ms-excel' });
        let ieEDGE = navigator.userAgent.match(/Edge/g);
        let ie = navigator.userAgent.match(/.NET/g); //  IE 11+
        let oldIE = navigator.userAgent.match(/MSIE/g);

        let fileName = ''
        let currentDate = new Date()
        let day = currentDate.getDate()
        let month = currentDate.getMonth() + 1
        let year = currentDate.getFullYear()
        fileName = day + '/' + month + '/' + year + '_export.xls';

        if (ie || oldIE || ieEDGE) {
          (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
        } else {
          let elem = window.document.createElement('a');
          elem.href = window.URL.createObjectURL(blob);
          elem.download = fileName;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        }

        swal2.fire('Creazione del nuovo lotto di voucher terminata con successo', '', 'success');
      } else {
        this.errorMessage = 'Ops! Si è verificato un errore imprevisto durante l\'esportazione dei voucher';
        swal2.fire('Errore', 'Ops! Si è verificato un errore imprevisto', 'error');
      }
    })

  }

  sortArrayByDate( array ) {
    array.sort((a, b) => {
      let d1 = new Date(a.tsCreated)
      let d2 = new Date(b.tsCreated)
      return d2.getTime() - d1.getTime()
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide()
  }

  deleteLot( lotData ) {
    swal2.fire({
      title: 'Attenzione',
      text : 'Sei sicuro di voler eliminare il lotto di voucher ' + lotData.description + '?',
      icon : 'warning',
      showCancelButton : true,
      cancelButtonText : 'Indietro',
      confirmButtonText : 'Si, elimina'
    }).then(
      (ev: SweetAlertResult) => {
        if (ev.value) {
          this.loadingService.presentLoader(LoadingLabels.Default);
          let queryObj = {
            body: { //  Ho messo in ROOT il Body per sopperire a una mancanza del httpRequestObject. Verificare la corretta implementazione
              idCompany : lotData.idCompany ,
              lotCode : lotData.lotCode
            }
          }
          this.listService.deleteWithQuery( '/voucher/vouchers' , queryObj ).subscribe( (resp) => {
            swal2.fire('Voucher eliminati', 'Voucher attivi del lotto correttamente eliminati', 'success')
              .then((ev: SweetAlertResult) => {
                if (ev.value) {
                  this.loadElements();
                }
              });
          })
        }
      },
      (dismiss) => {
        swal2.close()
      }
    )
  }

}
