import { Component, OnInit,TemplateRef,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import { UtilsService } from '../../../services/utils.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import swal2, { SweetAlertResult } from 'sweetalert2'
import { LoadingLabels } from '../../../enums/loading.enum';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-tickets-fares',
  templateUrl: './tickets-fares.component.html',
  styleUrls: ['../../../app.component.css']
})
export class TicketsFaresComponent implements OnInit {


  @ViewChild('formTicketFares') ticketFaresForm: NgForm;
  //OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  linesList: any[] = new Array();

  //PATH CHIAMATE
  urlLinesPath = "/route/routes";
  urlDeletePath;
  errorMessage: string = "";
  visibleFilter;
  defaultValue;
  UpOrDown;
  pathToCallCompaniesPath = "/company/companies"
  companiesList :any[] = new Array();
  typeUser;
  isSoluzione1;
  roleUser;
  colorTheme = "theme-orange";
  datepickerModel: Date[];
  bsConfig: Partial<BsDatepickerConfig>;
  isMobileOperator
  dataChannel: any[] = new Array();
  urlChannelPath = "/ticket/channel/channels";
  categories: any[] = new Array();
  pathListCategories = '/category/categories?type=T' // filtro per ticket
  today;
  groups: any[] = new Array();
  pathListGroups = '/category/categories-grouped';
  modalRef: BsModalRef;
  //VARIABILI PER SELECT


  constructor(
    private logger: LoggingService,
    public utils:UtilsService,
    private router: Router,
    public listService: ListService,
    private modalService: BsModalService,
    private loadingService: LoadingService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall("/tickets/fares",false,true,1,10);
    this.listService.restURL = "/ticket/fare/fares";
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
  }

  async ngOnInit() {
    var day = ("0" + new Date().getDate()).slice(-2)
    var monthIndex = ("0" + (new Date().getMonth()+1)).slice(-2)
    var year = new Date().getFullYear();
    this.today = year + "/" + (monthIndex) + "/" + day;

    //CHIAMATE PER SELECT
    // await this.getLines();
    this.getCompanies();
    //CHIAMATA LISTA ELEMENTI
    this.loadElements();
    await this.getCategories();
    await this.getGroups();
    await this.getChannel();

    this.UpOrDown = true;
    this.roleUser = this.utils.getRoleUser();
    this.isMobileOperator = this.utils.isOperatorMobile(this.roleUser)

  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  async getChannel() {
    this.dataChannel = await this.listService.manageListValues(this.urlChannelPath, 'Lista canali');
  }


  async getCategories() {
    this.categories = await this.listService.manageListValues(this.pathListCategories, 'Lista categorie');
  }

  async getGroups() {
    this.groups = await this.listService.manageListValues(this.pathListGroups, 'Lista gruppi');
  }

  changeStatus(){
    if(this.UpOrDown == true){
      this.UpOrDown = false;
    }else{
      this.UpOrDown = true;
    }
  
  }

  //FILTRI
  filterSearch(){
    this.dataFilter = this.ticketFaresForm.value;
    this.dataFilter['bsDatepicker'] = 'all';
    if(this.datepickerModel != null)
    {
      var dateOn = this.utils.stringTotalFRomDate(this.datepickerModel);
      this.dataFilter["dateOn"] = dateOn; 
    }
      
      this.listService.visibleFilter = true;
      this.loadElements();
  }

  getCompanyName(id){
    for (let company of this.companiesList){
      if (company.idCompany ==  id){
        return company.legalBusinessName;
      }
    }

  }

  filterReset(){
    this.ticketFaresForm.value.idCompany = ""
    this.ticketFaresForm.value.idCategory = ""
    this.ticketFaresForm.value.idTicketChannel = ""
    this.ticketFaresForm.value.bsDatepicker = ""
    this.ticketFaresForm.value.code = ""
    this.ticketFaresForm.value.name = ""
    this.listService.visibleFilter = false;
    this.ticketFaresForm.reset();
    this.dataFilter['dateOn'] = 'all'
    this.datepickerModel = null;
    this.loadElements();
  }
  

  //SERVIZI SELECT
  async getLines() {
    this.linesList = await this.listService.manageListValues(this.urlLinesPath, 'Lista linee');
  }

  getCompanies() {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe((response) => {
    this.companiesList = response.results;
    this.logger.log("Lista aziende", this.companiesList, 300);
    },
    (error) => {
        this.logger.log("Error", error, 200);
    }
    );
  }

  //FERMATE IN BASE A LINEA????


  //SERVIZIO LISTA ELEMENTI
  loadElements(){
    this.dataFilter['type'] = "T";
    this.dataFilter['orderBy'] = "DATE_TO";
    this.dataFilter['direction'] = "DESC";
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = this.utils.formatDates(response.results);
        this.listService.totalRows = response.total;

        this.logger.log("Response:",response,300);
        this.errorMessage = "";
      },    
      (error) => { 
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  selectRow(dataElement) {
    this.listService.selectedID = dataElement['id'];
    this.listService.backToList = true;
    localStorage.setItem('dataPassed', JSON.stringify(dataElement));
    this.router.navigate(['/tickets/fares/edit']);
  }

  newTicketFares() {
    this.listService.backToList = true;
    this.router.navigate(['/tickets/fares/new']);
  }

  deleteTicketFares(idFares) {
    var mySelf = this;

    swal2.fire({
      title: 'Eliminazione Tariffa',
      text: "Sei sicuro di voler eliminare la tariffa?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,

    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.deleteElement(idFares);
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })

  }

  deleteElement(idElement) {
    this.urlDeletePath =  "/ticket/fare/"+ idElement;

    this.loadingService.presentLoader(LoadingLabels.Delete);
    
    this.logger.log("ID DELETE:",idElement,200);
    
    this.listService.delete(this.urlDeletePath).subscribe(
      (response) => {
    
        if (response.outcome.success === true) {
              
          this.logger.log("Response value",response.value,200);
          swal2.fire("La tariffa è stata eliminata con successo", "","success").then((ev: SweetAlertResult) => {
            if (ev.value) {
              this.loadElements();
            }
          });
        } else {   
          this.logger.log("Response",response,200);
          swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.Riprova più tardi","error");
        }
      },    
      (error) => {
        this.logger.log("Error",error,200);
        swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.Riprova più tardi","error");
      }
    );
    
  }

  getCategoryDescription(idCategory: number): string {
    if (this.categories.length) {
      return this.categories.find(c => c.categoryId === idCategory).description;
    }
  }

  getCategoryGroup(idCategory: number): string {
    if (this.categories.length && this.groups.length) {
      const groupId = this.categories.find(c => c.categoryId === idCategory).groupId;
      return groupId == null ? "-" : this.groups.find(g => g.groupId === groupId).name;
    }
    return "-"
  }

  // --------- CSV MODAL MANAGEMENT --------- //

  openUploadModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

  closeUploadModal(): void {
    this.modalRef.hide();
  }

  getCompanyId(): number {
    return this.isSoluzione1 ? this.ticketFaresForm.value.idCompany : localStorage.getItem('idCompany');
  }

  manageUploadEvent(): void {
    this.modalRef.hide();
    this.loadElements();
  }

}

