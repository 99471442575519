import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IValidation } from "../../../interfaces/configuration.interface";
import { ValidationStatus } from '../../../enums/configuration.enum';
import { ListService } from '../../../services/list.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ControlContainer, NgForm } from '@angular/forms';
import { LoadingService } from '../../../services/loading.service';
import { LoadingLabels, LoadingSubtitles } from '../../../enums/loading.enum';
import swal2 from "sweetalert2";

@Component({
  selector: 'app-customer-info-manager',
  templateUrl: './customer-info-manager.component.html',
  styleUrls: ['./customer-info-manager.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class CustomerInfoManagerComponent {

  @Input() userInfoMode: number;
  @Input() findTripForm: NgForm;
  
  @Output() onClear = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<{id: number, type: string}>();

  customerValidations: IValidation[];
  pathCustomerValidations = '/customer/validation/validations?paging=false';

  constructor(
    private listService: ListService,
    private modalService: BsModalService,
    private loadingService: LoadingService
  ) { }

  /** Method fired on user info search, called with a different param depending on the mode (1 - email, 2 - codePass) */
  checkUserInfo(userParam: string, mode: number): void {
    this.loadingService.presentLoader(LoadingLabels.Default);

    this.onClear.emit();
    this.userInfoMode = mode;

    this.listService.getListSelect(`${this.pathCustomerValidations}${mode === 1 ? '&email=' : '&codePass='}${userParam}`).subscribe({
      next: response => {
        this.customerValidations = response.results.filter((validation: IValidation) => validation.status === ValidationStatus.ACTIVE);
        swal2.close();
        if (this.customerValidations?.length) {
          this.modalService.hide();
        } else {
          this.customerValidations = null;
          this.findTripForm.controls['customerInfoGroup']['controls']['customerValidation']?.setValue('');
          swal2.fire('Attenzione', 'L\'utente indicato non possiede tessere attive, impossibile procedere con la vendita', 'warning');
        }
      },
      error: _ => {
        this.modalService.hide();
        swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.', 'error');
      }
    });
  }


  clearUserInfo(): void {
    this.userInfoMode = null;
    this.customerValidations = null;
    this.findTripForm.controls.customerInfoGroup?.['controls']['customerEmail']?.setValue('');
    this.findTripForm.controls.customerInfoGroup?.['controls']['customerValidation']?.setValue('');
    this.onClear.emit();
  }

  confirmAction(): void {
    this.onConfirm.emit({id: (this.userInfoMode === 2 ? this.customerValidations[0]?.id : null), type: this.customerValidations ? this.customerValidations[0]?.typology?.typology : null});
  }

}
