import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService, SubmitActionType } from "../../../services/list.service";
import { AuthService} from "../../../services/auth.service";
import { UtilsService } from "../../../services/utils.service";
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import swal2 from 'sweetalert2'

@Component({
  selector: 'app-obliterators-manage',
  templateUrl: './obliterators-manage.component.html',
  styleUrls: ['../../../app.component.css']
})

export class ObliteratorsManageComponent implements OnInit {
    //form html
    @ViewChild('obliteratorForm') formObliterator: NgForm;
    
    vehicleList: any[] = new Array;
    //testo in HTML per tipo pagina
    kindTitle;kindManage;
    //URL servizio da chiamare, se post o put dipenderà da dove arriviamo
    serviceToCallPath = "/obliterator";
    pathToCallCompaniesPath = "/company/companies";
    pathToCallVehicles = "/vehicle/vehicles";
    urlVersionObliteratorPath = "/obliterator/apk/apks";
    typeUser;
    isSol1;
    idCompanyTemp;
    softwareVersion;
    dataVersionObliterator:  any[] = new Array;
    clear;
    colorTheme = "theme-orange";
    bsConfig: Partial<BsDatepickerConfig>;

    //oggetto contenente dati component
    dataObliterator: any;
    companiesList: any[] = new Array;
    filterArray: any[] = new Array;

    // Initialized to specific date (09.10.2018).
   
    errorMessage;
    //DATI TEMPORANEI PER TENERE TRACCIA DI ID E DATA ACQUISTO STAMPANTE
    idTemp;
    dateSellTemp;

    constructor(private utils:UtilsService,  private route: ActivatedRoute, private authService: AuthService, private logger: LoggingService, public listService: ListService, private router: Router ) {
        

        //Se creo nuovo veicolo
        if(route.snapshot.toString().indexOf("new") != -1) {
            this.kindTitle = "Nuova";
            this.kindManage = 1;
        } else { //se modifico veicolo
            this.kindTitle = "Modifica";
            this.kindManage = 0;
        
            if(localStorage.getItem("dataPassed")=="")  {this.router.navigate(['/obliterators/list']); return;}
            this.dataObliterator = JSON.parse(localStorage.getItem("dataPassed"));
            
            this.idTemp = this.dataObliterator["idObliterator"];
            this.dateSellTemp = this.dataObliterator["dateSell"];
            this.idCompanyTemp = this.dataObliterator["idCompany"]
            this.softwareVersion = this.dataObliterator["softwareVersion"]

            //TO DO DA ELIMINARE
            localStorage.setItem("dataPassed",""); //svuoto dato in localstorage

            this.clear = false;
        }

        this.bsConfig = Object.assign(
            {},
            { containerClass: this.colorTheme },
            { dateInputFormat: "DD-MM-YYYY" },
            { rangeInputFormat: "DD-MM-YYYY" }
        );
    }

    async ngOnInit() {
        await this.getVehicles();
        await this.getVersion();
        this.listService.resetList();
        this.listService.backToList = true;
        this.listService.listRouting= "/obliterators/list"
        this.getCompanies();
        this.typeUser = this.utils.getTypeUser();
        this.isSol1 = this.utils.isSol1(this.typeUser);
    }


    getCompanies() {
        this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe((response) => {
            this.companiesList = response.results;
            this.logger.log("Lista rivenditori", this.companiesList, 300);
        },
        (error) => {
            this.logger.log("Error", error, 200);
        }
        );
    }

    async getVehicles() {
        this.vehicleList = await this.listService.manageListValues(this.pathToCallVehicles, 'Lista rivenditori');
    }


    async getVersion() {
        this.dataVersionObliterator = await this.listService.manageListValues(this.urlVersionObliteratorPath, 'Lista veicoli');
    }

    onSubmit()  : void {
        this.dataObliterator = this.formObliterator.value;
        this.dataObliterator["status"] = "1";
        if (!this.kindManage) {
            this.dataObliterator["idObliterator"] = this.idTemp;
            this.dataObliterator["softwareVersion"] = this.softwareVersion;
            if (this.formObliterator.value.dateSell == null || this.formObliterator.value.dateSell == undefined)
                this.dataObliterator["dateSell"] = this.dateSellTemp;
            else
                this.dataObliterator["idCompany"] = this.idCompanyTemp;
        }

        this.listService.manageSubmitAction(
            this.kindManage ? SubmitActionType.CREATE : SubmitActionType.EDIT,
            this.dataObliterator,
            this.serviceToCallPath,
            '/obliterators/list',
            'obliteratrice'
        );
    }


    checkVehicle(id){
        this.filterArray = []
        this.clear = true;

        this.formObliterator.value.idVehicle = "";
        this.formObliterator.controls["idVehicle"].reset();
        
        var stringTest = id.split(":")[1];
        var stringCompany = stringTest.substring(1);

        for(let v of this.vehicleList){
            if(v.idCompany == stringCompany){
                this.filterArray.push(v)


            }
        }

        this.clear = false;
    }

    checkToModify(id){
        this.filterArray = []

        for(let v of this.vehicleList){
            if(v.idCompany == id){
                this.filterArray.push(v)


            }
        }
    }


    back(){
        this.router.navigate(['/obliterators/list']);
    }
}
