import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface SwitchConfig {
  title: string;
  btn1: { label: string, value: string };
  btn2: { label: string, value: string };
  defaultValue?: number;
}

@Component({
  selector: 'app-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.css']
})
export class SwitchButtonComponent implements OnInit {

  @Input() config: SwitchConfig;
  @Output() onSwitch = new EventEmitter<string>();
  
  active: number;

  constructor() { }

  ngOnInit(): void {
    this.active = this.config?.defaultValue || 0;
    this.emitSwitch();
  }

  toggle(): void {
    this.active = this.active === 0 ? 1 : 0;
    this.emitSwitch();
  }

  // --------- PRIVATE METHODS --------- //

  private emitSwitch(): void {
    this.onSwitch.emit(this.config?.[`btn${this.active + 1}`]?.['value']);
  }

}
