import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import swal2 from 'sweetalert2';
import { UtilsService } from '../../../services/utils.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { LoadingService } from '../../../services/loading.service';
import { LoadingLabels, LoadingSubtitles } from '../../../enums/loading.enum';
defineLocale('it', itLocale);

@Component({
  selector: 'app-statistics-obliteration',
  templateUrl: './statistics-obliteration.component.html',
  styleUrls: ['../../../app.component.css']
})
export class StatisticsObliterationComponent implements OnInit {
  @ViewChild('formStatsTicket') statsTicketForm: NgForm;
  @ViewChild('formInvalidTicket') invalidTicketForm: NgForm;

  //  OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();
  kindMenu;
  dataFilter: any[] = new Array();
  dataOperator: any[] = new Array();
  dataResale: any[] = new Array();
  dataDesk: any[] = new Array();
  dataCompany: any[] = new Array();
  dataObliterator: any[] = new Array();
  dataVehicle: any[] = new Array();
  dataOptionTicket: any[] = new Array();
  dataSubOptionTicket: any[] = new Array();
  urlTransactionPath = '/stats/sold/analysis/ticket?idTransaction=';
  name;
  urlLine = '/route/routes'
  lineList: any[] = new Array();

  qrcodeString;
  typeUser;
  isResale;
  paginationVisible;
  idRoute;
  ticketFareName;
  destination;
  origin;
  usableOn;
  dateSell;
  price;
  paymentType;
  idTransaction;
  sellerName;
  sellerOperator;
  ticketCategoryName;
  cardId;
  optionSelected = null;
  viewSubOption  = false;


  //  PATH CHIAMATE
  urlDeletePath = '';
  urlResalePath = '/resale/resales';
  urlDeskPath = '/desk/desks'
  urlCsvObliterationsPath = '/obliterator/obliterations/csv'
  urlCompanyPath = '/company/companies';
  urlObliteratorsPath = '/obliterator/obliterators';
  urlVehiclesPath = '/vehicle/vehicles';
  pathStopsList = '/stop/stops/v2';
  pathRoutesList = '/route/routes';
  pathLinetripList = '/trip/linetriplist';
  pathModifyTicket = '/ticket/move-date';

  errorMessage = '';
  roleUser = '';
  qrCodeWindow = false;
  stringQrCode = 'ND';
  visibleFilter;
  defaultValue;
  UpOrDown;
  classString;
  isSol1;
  idTicketToInvalidate;
  modalRef: BsModalRef;
  colorTheme = 'theme-orange';
  bsConfig: Partial<BsDatepickerConfig>;

  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();

  locale = 'it';
  locales = listLocales();
  stringFirstDay;
  stringLastDay;
  totalRecords = 0;

  //  MODIFICA TICKET
  modTripList: any[] = new Array();
  // modIdLine;
  // modDirectionValue;
  // modDirectionString;
  modDirectionList: any[] = new Array();
  modOriginList: any[] = new Array();
  modDestinationList: any[] = new Array();
  modStringDataLine;
  // modRouteLongName;
  modTicketRoute = null;
  modTicketDir = null;
  modTicketOrig = null;
  // modTicketData = null;
  modTicketTrip = null;
  // modTicketTransaction;
  ticketToModified: TicketModified = new TicketModified();

  //  DATEPICKER FILTRI
  public saleTo = {
    dateFormat: 'dd-mm-yyyy',
    inline: false,
    editableDateField: false
  };

  public saleFrom = {
    dateFormat: 'dd-mm-yyyy',
    inline: false,
    editableDateField: false
  };

  public modTicketDate = {
    //  other options...
    dateFormat: 'dd-mm-yyyy',
    inline: false,
    editableDateField: false
  };

  private placeholderFrom = 'Da:';
  private placeholderTo = 'A:';

  constructor(
    public utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private modalService: BsModalService,
    private localeService: BsLocaleService,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {
    this.listService.resetList();

    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.setDateFilter(firstDay, lastDay);

    this.localeService.use(this.locale);
    this.listService.configurationServiceCall(
      '/statistics/obliteration',
      false,
      true,
      1,
      10
    );
    this.listService.restURL = '/obliterator/obliterations';
  }

  async ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.listService.backToList = params.fromVouchers ? true : false;
      this.listService.listRouting = '/statistics/vanalysis';
    });

    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      {dateInputFormat: 'DD-MM-YYYY'},
      {rangeInputFormat: 'DD-MM-YYYY'}
    );

    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);


    this.stringFirstDay = this.utils.formatDate(firstDay)
    this.stringLastDay = this.utils.formatDate(lastDay)

    this.typeUser = this.utils.getTypeUser();
    this.roleUser = this.utils.getRoleUser();

    this.isSol1 = this.utils.isSol1(this.typeUser);
    this.isResale = this.utils.isRes(this.typeUser);

    this.paginationVisible = false;

    //  CHIAMATE PER SELECT
    await this.getLineList();

    this.isSol1 = this.utils.isSol1(this.typeUser);
    if (this.isSol1) {
      await this.getCompanies();
    }

    await this.getObliterators();
    await this.getVehicles();

    //  CHIAMATA LISTA ELEMENTI

    this.UpOrDown = true;
    this.classString = 'theme-config-box';

    if ( JSON.parse(sessionStorage.getItem('dataFilter')) ) {
      let receivedParams = JSON.parse(sessionStorage.getItem('dataFilter'));
      sessionStorage.removeItem('dataFilter');
      Object.keys(receivedParams).map( key => {
        this.dataFilter[key] = receivedParams[key];
        if (key.includes('idTicket')) {
          date = new Date();
          firstDay = new Date(date.getFullYear() - 1, date.getMonth(), 1);
          lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
          this.setDateFilter(firstDay, lastDay);
        }
      })
    }
  }

  close() {
    this.modalRef.hide()
  }

  openModal(qrCode, template: TemplateRef<any>) {

    this.qrcodeString = qrCode;
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide()
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  // FILTRI
  filterSearch() {
    this.getFilter();

    this.listService.visibleFilter = true;
    this.loadElements();
  }

  getFilter() {
    this.dataFilter = this.statsTicketForm.value;
    this.dataFilter['bsDaterangepicker'] = 'all'
    // SE CI SONO, ALTRIMENTI BYPASSA
    if (this.bsRangeValue != null && this.bsRangeValue !== undefined) {
      this.stringFirstDay = this.utils.stringTotalFRomDate(this.bsRangeValue[0])
      // this.dataFilter['dateFrom'] = this.stringFirstDay
    } else {
      this.stringFirstDay = null
    }

    if (this.bsRangeValue !== null && this.bsRangeValue !== undefined && this.bsRangeValue.length > 1) {
      this.stringLastDay = this.utils.stringTotalFRomDate(this.bsRangeValue[1])
      // his.dataFilter['dateTo'] = this.stringLastDay
    } else {
      this.stringLastDay = null
    }

    if (this.modStringDataLine !== null && this.modStringDataLine !== '--') { this.dataFilter['tripDate'] =  this.modStringDataLine; }
    // se uso la corsa non uso la linea e viceversa.
    if (this.modTicketTrip !== null ) {
      this.dataFilter['idTrip'] =  this.modTripList[this.modTicketTrip].tripId;
      this.dataFilter['routeId'] = null;
    } else {
      if (this.modTicketRoute !== null) {
        this.dataFilter['idTrip'] = null;
        this.dataFilter['routeId'] = this.lineList[this.modTicketRoute].routeId;
      }
      if (this.modTicketDir !== null) {
        this.dataFilter['direction'] = this.lineList[this.modTicketRoute].directionId;
      }
    }
    // elimino la direzione che non mi serve nei filtri
    this.dataFilter['direction'] = null;
  }

  getCsv() {
    this.getFilter();
    const queryString = this.utils.getQueryWithFilters(this.dataFilter);
    let finalQueryString;
    if (queryString === '' || queryString === null) {
      finalQueryString = this.urlCsvObliterationsPath;
    } else {
      finalQueryString = this.urlCsvObliterationsPath + '?' + queryString;
    }

    this.listService.getCsv(finalQueryString).subscribe(
      response => {
        if (response) {
          const blob = new Blob([response], { type: 'text/csv' });
          const ieEDGE = navigator.userAgent.match(/Edge/g);
          const ie = navigator.userAgent.match(/.NET/g); //  IE 11+
          const oldIE = navigator.userAgent.match(/MSIE/g);

          let fileName = ''
          const currentDate = new Date()
          const day = currentDate.getDate()
          const month = currentDate.getMonth() + 1
          const year = currentDate.getFullYear()
          fileName = day + '/' + month + '/' + year + '_statistics_obliterations.csv';

          if (ie || oldIE || ieEDGE) {
            (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
          } else {
            let elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = fileName;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          }

          swal2.fire('Il csv è stato scaricato', '', 'success');
        } else {
          //  response.outcome.code + response.outcome.description;
          swal2.fire('Attenzione', this.errorMessage, 'warning');
        }
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
        swal2.fire(
          'Errore',
          'Spiacente, si è verificato un errore tecnico.',
          'error'
        );
      }
    );
  }

  filterReset() {
    this.statsTicketForm.value.idTicketFare = '';
    this.statsTicketForm.value.idTicketType = '';
    this.statsTicketForm.value.dateFrom = '';
    this.statsTicketForm.value.dateTo = '';
    this.statsTicketForm.value.status = '';
    this.statsTicketForm.value.idResale = '';
    this.statsTicketForm.value.idTicketChannel = '';
    this.statsTicketForm.value.idTicket = '';
    this.statsTicketForm.value.idCompany = '';
    this.statsTicketForm.value.routeId = '';
    this.statsTicketForm.value.tripDate = '';
    this.statsTicketForm.reset();

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.setDateFilter(firstDay, lastDay);

    this.listService.visibleFilter = false;
    this.loadElements();
  }

  setDateFilter(firstDay, lastDay) {
    this.maxDate.setDate(lastDay.getDate());
    this.bsRangeValue = [firstDay, this.maxDate];

    this.stringFirstDay = this.utils.stringTotalFRomDate(this.bsRangeValue[0])
    this.stringLastDay = this.utils.stringTotalFRomDate(this.bsRangeValue[1])
  }

  closeFilter() {
    if (this.classString === 'theme-config-box show') {
      this.classString = 'theme-config-box ';
    } else {
      this.classString = 'theme-config-box show';
    }
  }

  // SERVIZI SELECT

  async getCompanies() {
    this.dataCompany = await this.listService.manageListValues(this.urlCompanyPath, 'Lista canali');
  }

  async getLineList() {
    this.lineList = await this.listService.manageListValues(this.urlLine, 'Lista linee');
  }

  async getObliterators() {
    this.dataObliterator = await this.listService.manageListValues(this.urlObliteratorsPath, 'Lista obliteratrici');
  }

  async getVehicles() {
    this.dataVehicle = await this.listService.manageListValues(this.urlVehiclesPath, 'Lista linee');
  }

  getNameVehicle(idVehicle) {
    for (let v of this.dataVehicle) {
      if (v.id === idVehicle) {
        return v.name;
      }
    }
    return '-';
  }

  getNameObliterator(idObliterator) {
    for (let o of this.dataObliterator) {
      if (o.idObliterator === idObliterator) {
        return o.name;
      }
    }
    return '-';
  }



  // SERVIZIO LISTA ELEMENTI
  loadElements() {
    this.loadingService.presentLoader(LoadingLabels.DataLoading, LoadingSubtitles.BigDataLoading);
    this.dataFilter['dateFrom'] = this.stringFirstDay;
    this.dataFilter['dateTo'] = this.stringLastDay;

    this.listService.getListFilter(this.dataFilter).subscribe(
      response => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;

        if (this.listService.totalRows > this.listService.rows) {
          this.paginationVisible = true;
        }
        this.totalRecords = this.listService.totalRows;

        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
        this.totalRecords = 0;
      }
    ).add(() => {
      this.loadingService.hideLoader();
    });
  }

  selectRow(dataElement) {
    this.listService.selectedID = dataElement['id'];
    this.listService.backToList = true;
    localStorage.setItem('dataPassed', JSON.stringify(dataElement));
    this.router.navigate(['/statistics/ticket']);
  }

  viewQrCode(stringQr) {
    this.qrCodeWindow = true;
    this.stringQrCode = stringQr;
  }

  changeStatus() {
    if (this.UpOrDown === true) {
      this.UpOrDown = false;
    } else {
      this.UpOrDown = true;
    }
  }

  closeQrCodeTicketDetails() {
    this.qrCodeWindow = false;
  }

  //  RICERCA TICKET DA CORSA

  async onChange(indexRoute) {
    this.modTicketTrip = null;
    // salvo valore route selezionata
    this.modTicketRoute = indexRoute;
    this.modTripList = [];
    await this.getDirections();
    await this.getDirectionsFrom()
  }

  async getDirections() {
    this.modDirectionList = [];
    const stringPath = '/' + this.lineList[this.modTicketRoute].routeId;
    this.modDirectionList = await this.listService.manageListValues(this.pathRoutesList, 'Ticket');
  }

  getDirection(indexDir) {
    this.modTicketDir = indexDir;
    this.getHourTrip();
  }

  onDateChanged(stringData: Date) {
    if (stringData) {
      this.modStringDataLine = this.utils.convertDateToISO(stringData);
    }
    if (this.statsTicketForm.value.routeId !== undefined && this.statsTicketForm.value.direction !== undefined) {
      this.logger.log('Entro', this.modStringDataLine, 200);
      this.modTicketTrip = null;
      this.getHourTrip();
    }
  }

  onDateChangedModal(stringData) {
    this.modTicketTrip = null;
    this.modStringDataLine = this.utils.stringToServerFormat(stringData.formatted);
    this.getHourTrip();
  }

  async getHourTrip() {
    this.modTicketTrip = null;
    // setto il ticket modificato alla data prevista
    this.ticketToModified.usableFrom = this.modStringDataLine;
    this.ticketToModified.idTrip = null

    this.modTripList = []
    const filterPAth =
      '?date=' +
      this.modStringDataLine +
      '&line=' +
      this.lineList[this.modTicketRoute].routeId +
      '&directionId=' +
      this.modDirectionList[this.modTicketDir].directionId
      + '&paging=false';

    this.modTripList = await this.listService.manageListValues(this.pathLinetripList + filterPAth);
  }

  getTrip(idArrayTrip) {
    this.modTicketTrip = idArrayTrip;
    this.ticketToModified.idTrip = this.modTripList[this.modTicketTrip].tripId;
    this.ticketToModified.usableFrom = this.modStringDataLine + ' ' + this.modTripList[idArrayTrip].departureTime;
    this.logger.log('Ticket', this.ticketToModified, 300);
  }

  async getDirectionsFrom() {
    this.modOriginList = [];
    const stringPath = '?routeId=' + this.lineList[this.modTicketRoute].routeId;
    this.modOriginList = await this.listService.manageListValues(this.pathStopsList + stringPath, 'From');
  }

  async getDirectionsTo(value) {
    this.modDestinationList = [];
    this.modTicketOrig = this.modOriginList[value].stopName;
    const stringPath = '/' + this.modOriginList[value].stopId + '?routeId=' + this.lineList[this.modTicketRoute].routeId;
    this.modDestinationList = await this.listService.manageListValues(this.pathStopsList + stringPath, 'To');
  }
  // FINE RICERCA TICKET PER CORSA
  selectOption(pos) {
    pos = pos.split(':')[0];
    // SE NON ESISTONO FIGLI
    if (this.dataOptionTicket[pos].items === null) {
      this.optionSelected = this.dataOptionTicket[pos].value;
      this.viewSubOption = false;
    } else {
      this.dataSubOptionTicket = this.dataOptionTicket[pos].items;
      this.optionSelected = null;
      this.viewSubOption = true;
    }
  }

  selectSubOption(value) {
      value = value.split(': ')[1];
      this.optionSelected = value;
  }

  checkNameKind(string) {
    let ret = ''
    if (string === 'T') {
      ret = 'Biglietto'
    } else if (string === 'S') {
      ret = 'Abbonamento';
    } else if (string === 'H') {
      ret = 'Hop On Hop Off';
    } else {
      ret = 'N/A';
    }
    return ret;
  }

}
export class TicketModified {

  usableFrom;
  origin;
  destination;
  idTrip;
  id;

  constructor() {}
}
