import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService, SubmitActionType } from '../../../services/list.service';
import { LoggingService } from '../../../services/logging.service';
import { UtilsService } from '../../../services/utils.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { listLocales } from 'ngx-bootstrap/chronos';
import { DatePipe } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoadingLabels } from '../../../enums/loading.enum';
import { LoadingService } from '../../../services/loading.service';
import { SwitchConfig } from '../../../common/components/switch-button/switch-button.component';
import swal2, { SweetAlertResult } from 'sweetalert2';
defineLocale('it', itLocale);

@Component({
  selector: 'app-tickets-fares-manage',
  templateUrl: './tickets-fares-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class TicketsFaresManageComponent implements OnInit, AfterViewInit {
  locale = 'it';
  locales = listLocales();
  // testo in HTML per tipo pagina
  kindTitle;
  kindManage;
  // URL servizio da chiamare, se post o put dipenderà da dove arriviamo
  serviceToCallPath = '/ticket/fare';
  // form html
  bsConfig: Partial<BsDatepickerConfig>;
  // oggetto contenente dati component
  ticketFaresData: any;
  stops: any[] = new Array();
  filterStops: any[] = new Array();
  lines: any[] = new Array();
  companies: any[] = new Array();
  blocks: any[] = new Array();
  pathListBlocks = '/block/blocks';
  zones: any[] = new Array();
  pathListZones = '/zone/zones';
  categories: any[] = new Array();
  pathListCategories = '/category/categories-grouped?type='; //  filtro per ticket
  originStops: any[] = new Array();
  daterangepickerModel: Date[];
  bsRangeValue: Date[];
  sol1OrSa = false;
  tripsResponse: any[] = new Array();
  counterRules: any[] = new Array();
  pathListStops = '/stop/stops/v2';
  errorMessage;
  stopsListPath;
  lineListPath;
  pathToCallCompaniesPath;
  tempLineValue;
  //  gestione categorie
  typeTicket; // T o H o altri tipi in futuro, presi dalla Utils per ogni compagnia
  config;
  groupCategories;
  basicGroupCategories: any[] = [];
  groupCategoryId;
  passengers: any[] = new Array();
  // gestione canali di accesso automatici
  dataChannel: any[] = new Array();
  validity: string;

  urlChannelPath = '/ticket/channel/channels';
  pathRules = '/ticket/fare/fares/rules/'

  destinationStops: any[] = new Array();
  channelArray: any[] = new Array();
  categoryArray: any[] = new Array();
  colorTheme = 'theme-orange';
  model = {
    code: '',
    name: '',
    idCompany: 1
  };
  pathListTrips = '/trip/trips/v2';
  originalStops: any[] = new Array();

  showInfo = false;

  modalRef: BsModalRef;
  switchButtonConfig: SwitchConfig;

  @ViewChild('ticketRatesForm') ticketRatesForm: NgForm;

  constructor(
    public utils: UtilsService,
    private localeService: BsLocaleService,
    private route: ActivatedRoute,
    private logger: LoggingService,
    public listService: ListService,
    private router: Router,
    private modalService: BsModalService,
    private loadingService: LoadingService
  ) {
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: 'DD-MM-YYYY' },
      { rangeInputFormat: 'DD-MM-YYYY' }
    );
    this.localeService.use(this.locale);

    this.getChannel();

    // Se nuova tariffa ticket
    if (this.route.snapshot.toString().indexOf('new') !== -1) {
      this.kindTitle = 'Nuova';
      this.tempLineValue = 'New';
      this.kindManage = 1;
    } else {
      // se modifico
      this.kindTitle = 'Modifica';
      this.kindManage = 0;
      if (localStorage.getItem('dataPassed') === '') {
        this.router.navigate(['/tickets/rates']);
        return;
      }

      this.ticketFaresData = JSON.parse(localStorage.getItem('dataPassed'));

      let dateString = this.ticketFaresData['dateFrom']
      let newDate = new Date(dateString);
      let dateStringTo = this.ticketFaresData['dateTo']
      let newDateTo = new Date(dateStringTo);

      const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
      const lastDay = new Date(newDateTo.getFullYear(), newDateTo.getMonth() , newDateTo.getDate());
      this.bsRangeValue = [firstDay, lastDay];

      this.channelArray = this.ticketFaresData['ticketChannel'];
      this.categoryArray = this.ticketFaresData['idCategory'];

      this.getRules(this.ticketFaresData['idTicketFare']);

      localStorage.setItem('dataPassed', ''); // svuoto dato in localstorage
    }

    this.config = this.utils.manageTicketTypes();
    this.typeTicket = this.utils.manageTicketTypes()[0].id;
    this.switchButtonConfig = this.utils.configureFareValiditySwitchBtn(this.ticketFaresData?.dateType);
  }

  ngOnInit() {
    this.listService.resetList();
    this.listService.backToList = true;
    this.listService.listRouting = '/tickets/fares';
    this.stopsListPath = '/stop/stops';
    this.lineListPath = '/route/routes';
    this.pathToCallCompaniesPath = '/company/companies';
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.ticketFaresData && this.ticketFaresData['idCompany']) {
      this.ticketRatesForm.value.idCompany = this.ticketFaresData['idCompany'];
    }

    this.getCategories();
    
    await this.getSelectStops();
    await this.getSelectLines();
    await this.getBlocks();
    await this.getZones();
    await this.getStopsFrom();

    /** SA features */
    this.sol1OrSa = this.utils.checkSadminOrSol1();
    if (this.sol1OrSa) {
      this.getCompanies();
    }
  }

  removeRules(data) {
    this.counterRules = this.counterRules.filter(obj => obj !== data);
  }

  async getStopsFrom(): Promise<void> {
    let requestUrl = this.pathListStops;
    if (this.ticketRatesForm && this.ticketRatesForm.value.idCompany) {
      requestUrl += `?idCompany=${this.ticketRatesForm.value.idCompany}`;
    }
    this.originStops = await this.listService.manageListValues(requestUrl, 'Lista fermate partenza');
  }

  async getChannel() {
    this.dataChannel = await this.listService.manageListValues(this.urlChannelPath, 'Lista canali');
  }

  async getRules(id: number): Promise<void> {
    this.counterRules = await this.listService.manageListValues(this.pathRules + id, 'Lista rules');
    this.counterRules.forEach(c => {
      if (c.routeId !== null) {
        c.kindName = 'LINEA'
      }
      if (c.blockId !== null ) {
        c.kindName  =  'TRATTA'
      }
      if (c.originZoneId !== null ) {
        c.kindName  =  'ZONA'
      }
      if (c.originId !== null ) {
        c.kindName  =  'TRA DUE SPECIFICHE FERMATE'
      }
      if (c.tripId !== null ) {
        c.kindName  =  'CORSA'
      }
      if (this.isRuleForEveryone(c)) {
        c.kindName = 'PER TUTTI'
      }
      c.decided = true;
    });
  }

  isRuleForEveryone(rule) {
    if (
      rule.routeId === null && rule.blockId === null
      && rule.originId === null && rule.destinationId === null
      && rule.tripId === null && rule.originZoneId === null
    ) {
      return true
    }
    return false
  }

  countDecidedRules() {
    let countDecidedRules = 0;
    for (let i = 0; i < this.counterRules.length; i++) {
      if (this.counterRules[i]['decided'] === true) { countDecidedRules++; }
    }
    return countDecidedRules;
  }

  addNewRules() {
    const model = {
      routeId: null,
      blockId: null,
      originZoneId: null,
      destinationZoneId: null,
      originId: null,
      destinationId: null,
      tripId: null,
      choseKind: null,
      decided: false,
      kindName: null
    };
    this.counterRules.push(model);
  }

  confirmNewRules() {
    const rule = this.counterRules[this.counterRules.length - 1];
    this.counterRules[this.counterRules.length - 1]['decided'] = true;
    this.choseKindRules(rule['choseKind'].toString(), this.counterRules[this.counterRules.length - 1]);
  }

  isRulesAllConfirmed() {
    let confirmed = true;

    this.counterRules.forEach(function(rule) {
      if (rule.decided === false) {
        confirmed = false;
      }
    });

    return confirmed;
  }

  async getStopsTo(idStop: number): Promise<void> {
    this.destinationStops = await this.listService.manageListValues(this.pathListStops + '/' + idStop, 'Lista fermate fine');
  }

  choseKindRules(value, data) {
    data['choseKind'] = value;
    const name = '';

    if (value === '1') {
      data['kindName'] = 'LINEA';
    } else if (value === '2') {
      data['kindName'] = 'TRA DUE SPECIFICHE FERMATE';
    } else if (value === '3') {
      data['kindName'] = 'TRATTA';
    } else if (value === '4') {
      data['kindName'] = 'CORSA';
    } else if (value === '5') {
      data['kindName'] = 'PER TUTTE LINEE / CORSE';
    } else if (value === '6') {
      data['kindName'] = 'ZONE';
    }
  }

  onChangeType(value) {
    this.typeTicket = value;
  }

  onChangeStop(id, data) {
    data['destinationId'] = null;
    data['destinationZoneId'] = null;
    data['originZoneId'] = null;
    data['tripId'] = null;
    data['blockId'] = null;
    data['routeId'] = null;
    data['originId'] = id;
    this.getStopsTo(id);
  }

  onChangeStopTrip(id) {
    this.getStopsTo(id);
  }

  onChangeStopDestination(id, data) {
    data['destinationId'] = id;
    data['destinationZoneId'] = null;
    data['originZoneId'] = null;
    data['tripId'] = null;
    data['blockId'] = null;
    data['routeId'] = null;
  }

  onChangeBlockId(id, data) {
    data['originId'] = null;
    data['destinationId'] = null;
    data['destinationZoneId'] = null;
    data['originZoneId'] = null;
    data['tripId'] = null;
    data['blockId'] = id;
    data['routeId'] = null;
  }

  onChangeZoneOrigin(id, data) {
    data['destinationId'] = null;
    data['originZoneId'] = id;
    data['tripId'] = null;
    data['blockId'] = null;
    data['routeId'] = null;
    data['originId'] = null;
  }

  onChangeZoneDestination(id, data) {
    data['originId'] = null;
    data['destinationId'] = null;
    data['destinationZoneId'] = id;
    data['tripId'] = null;
    data['blockId'] = null;
    data['routeId'] = null;
  }

  onChangeTripId(id, data) {
    data['tripId'] = id;
    data['originId'] = null;
    data['destinationId'] = null;
    data['destinationZoneId'] = null;
    data['originZoneId'] = null;
    data['blockId'] = null;
    data['routeId'] = null;
  }


  choseKindRoute(value, data) {
    data['originId'] = null;
    data['destinationId'] = null;
    data['destinationZoneId'] = null;
    data['originZoneId'] = null;
    data['tripId'] = null;
    data['blockId'] = null;
    data['routeId'] = value;
  }

  getRouteName(id) {
    let string = id;

    for (let r of this.lines) {
      if (r.routeId === id) {
        string = r.routeLongName;
      }
    }
    return string;
  }

  getStopName(id) {
    let string = id;

    for (let s of this.originStops) {
      if (s.stopId === id) {
        string = s.stopName;
      }
    }
    return string;
  }

  getBlockName(id) {
    let string = id;

    for (let b of this.blocks) {
      if (b.id === id) {
        string = b.description;
      }
    }
    return string;
  }

  getZoneName(id) {
    let string = id;

    for (let b of this.zones) {
      if (b.zoneId === id) {
        string = b.description;
      }
    }
    return string;
  }

  getForAll(c) {
    if (c.choseKind === 5) {
      return 'Verrà applicata la tariffa a qualunque linea della compagnia';
    }
  }

  isChecked(event) {
    if (event.target.checked) {
    } else {
      this.ticketRatesForm.value.toStop = null;
      this.filterStops = [];
    }
  }

  findList(idLine) {
    if (this.tempLineValue !== 'New') {
      this.filterStops = [];
      for (let stop of this.stops) {
        if (stop.routeShortName === idLine) {
          this.filterStops.push(stop);
        }
      }
    }
  }

  onChangeLine(idLine) {
    let string;

    string = idLine.substring(3);
    this.filterStops = [];
    for (let stop of this.stops) {
      if (stop.routeShortName === string) {
        this.filterStops.push(stop);
      }
    }
  }

  async getSelectStops() {
    this.stops = await this.listService.manageListValues(this.stopsListPath, 'Lista aziende');
    
    if (this.stops) {
      this.findList(this.tempLineValue);
    }
  }

  async getSelectLines() {
    let requestUrl = this.lineListPath;
    if (this.ticketRatesForm && this.ticketRatesForm.value.idCompany) {
      requestUrl += `?idCompany=${this.ticketRatesForm.value.idCompany}`;
    }
    this.lines = await this.listService.manageListValues(requestUrl, 'Lista linee');
  }

  getCompanies() {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe(
      response => {
        this.companies = response.results;
        this.logger.log('Lista aziende', this.companies, 300);
      },
      error => {
        this.logger.log('Error', error, 200);
      }
    );
  }


  //  Legge le categorie previste dall'azienda
  getCategories(): void {
    this.showInfo = false;
    let requestUrl = this.pathListCategories + this.typeTicket;
    if (this.ticketRatesForm && this.ticketRatesForm.value.idCompany) {
      requestUrl += `&idCompany=${this.ticketRatesForm.value.idCompany}`;
    }
    this.listService.getListSelect(requestUrl).subscribe({
      next: response => {
        this.groupCategories = response.results;
        this.basicGroupCategories = this.groupCategories;
        
        if (this.ticketFaresData && !this.ticketFaresData['idCategory']) {
          this.ticketFaresData['idCategory'] = [];
        } 

        if (this.kindManage === 0) {
          this.assignCategoryGroupId(this.ticketFaresData['idCategory']);
        } else {
          this.groupCategoryId = 0;
        }

        this.loadCategories();
        this.logger.log('Lista categorie ', this.groupCategories, 300);

        this.showInfo = true;
      },
      error: error => {
        this.logger.log('Error', error, 200);
        this.showInfo = true;
      }
    });

  }

  //  dato il groupCategory carica le categorie da selezionare
  loadCategories() {
    const group = this.kindManage === 0 ? [this.groupCategories[this.groupCategoryId]] : this.groupCategories;
    this.categories = group.map(g => g.categories.map(c => { return {...c, groupedName: `${g.name} - ${c.description}`} })).reduce((acc, val) => acc.concat(val), [])
  
    if (this.kindManage === 0) {
      const category = this.categories.find(c => c.categoryId === this.ticketFaresData['idCategory']);
      if (category) {
        this.ticketRatesForm.controls['categoriesList'].patchValue([category]);
      }
    }
  }

  assignCategoryGroupId(categoryId: number | number[]) {
    let pageComponent = this;
    let count = -1;
    let foundCategoryGroupId = this.groupCategories.forEach(function (k) {
      count = count + 1;
      let val = k.categories.find(y => y.categoryId === categoryId);
        if (val !== null && val !== undefined) {
          pageComponent.ticketFaresData['categoryGroupId'] = count;
          pageComponent.ticketRatesForm.value.categoryGroupId = count;
          pageComponent.groupCategoryId = count;
          return;
        }
      });
  }

  async getBlocks() {
    let requestUrl = this.pathListBlocks;
    if (this.ticketRatesForm && this.ticketRatesForm.value.idCompany) {
      requestUrl += `?idCompany=${this.ticketRatesForm.value.idCompany}`;
    }
    this.blocks = await this.listService.manageListValues(requestUrl, 'Lista blocchi');
  }

  async getZones() {
    let requestUrl = this.pathListZones;
    if (this.ticketRatesForm && this.ticketRatesForm.value.idCompany) {
      requestUrl += `?idCompany=${this.ticketRatesForm.value.idCompany}`;
    }
    this.zones = await this.listService.manageListValues(requestUrl, 'Lista zone');
  }

  setIdChannel(kind) {
    // this.logger.log('PRIMA', this.channelArray, 300);
    let i = 0;
    for (let val of this.channelArray) {
      if ( val.toString() === kind.toString()) {
        this.channelArray.splice(i, 1);
        // this.logger.log('DOPO', this.channelArray, 300);
        return;
      }
      i++;
    }
    this.channelArray.push(kind.toString());
    // this.logger.log('DOPO', this.channelArray, 300);
  }

  isCheckChannel(idChannel) {
    for (let i of this.channelArray) {
      // this.logger.log('i '+ i + 'idC' + idChannel, i, 300);
      if ( i.toString() === idChannel.toString()) { return true; }
    }
    return false;
  }

  onSubmit(): void {
    swal2.fire({
      title: 'Operazione in corso...',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Indietro',
      text: 'Stai creando una tariffa con ' + this.counterRules.length + ' regola/e. Continuare?',
      confirmButtonText: 'Procedi'
    }).then((ev: SweetAlertResult) => {
      if (ev.value) {
        const dateFrom = new DatePipe('en-EN').transform(
          this.bsRangeValue[0],
          'yyyy-MM-dd'
        );
        const dateTo = new DatePipe('en-EN').transform(
          this.bsRangeValue[1],
          'yyyy-MM-dd'
        );

        const requestData: any = new Object();
        requestData.dateFrom = dateFrom;
        requestData.dateTo = dateTo;
        requestData.description = this.ticketRatesForm.value.description;
        requestData.price = this.ticketRatesForm.value.price;
        requestData.idTicketChannel = this.channelArray;
        requestData.fareRules = this.counterRules;
        requestData.dateType = this.validity;
        if (this.sol1OrSa) {
          requestData.idCompany = this.ticketRatesForm.value.idCompany
        }

        if (this.kindManage) {
          requestData.categoryIds = this.ticketRatesForm.value.categoriesList.map(c => c.categoryId);
        } else {
          requestData.categoryId = this.ticketFaresData['idCategory'];
          requestData.fareId = this.ticketFaresData['idTicketFare']
        }

        this.listService.manageSubmitAction(
          this.kindManage ? SubmitActionType.CREATE : SubmitActionType.EDIT,
          requestData,
          this.serviceToCallPath,
          '/tickets/fares',
          'tariffa ticket'
        );
      }
    }, _ => {
      swal2.close();
    })
  }

  returnToList() {
    this.router.navigate(['/tickets/fares']);
  }

  choseTripList(stopto, stopfrom) {
    const dataRequest = {};
    dataRequest['origin'] = stopfrom;
    dataRequest['destination'] = stopto;

    const date = new Date();
    dataRequest['dateA'] = new DatePipe('en-EN').transform(date, 'yyyy-MM-dd');

    this.getTrips(dataRequest);
  }

  getTrips(dataRequest): void {
    this.loadingService.presentLoader(LoadingLabels.Default);

    let requestUrl = this.pathListTrips + '?idTicketChannel=3';
    const stopCompanyId = this.originStops.find(s => s.stopId = this.ticketRatesForm.value.fromStopTrip).idCompany;
    this.listService.newObject(dataRequest, requestUrl += `&idCompany=${stopCompanyId}`).subscribe(
      response => {
        swal2.close();
        this.tripsResponse = response.andata;
      },
      error => {
        swal2.close();
        this.logger.log('Error', error, 200);
      }
    );
  }

  async onChangeCompany(): Promise<void> {
    this.getCategories();
    await this.getSelectLines();
    await this.getBlocks();
    await this.getZones();
    await this.getStopsFrom();

    this.config = this.utils.getJSONConfigurationByCompanyId(this.ticketRatesForm.value.idCompany.toString());
    this.typeTicket = this.config.typeTicket[0].id
  }

  getCompanyId(): number {
    return this.sol1OrSa ? this.ticketRatesForm.value.idCompany : +localStorage.getItem('idCompany');
  }

  validitySwitch(val: string): void {
    this.validity = val;
  }

  // --------- CSV MODAL MANAGEMENT --------- //

  openUploadModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

  closeUploadModal(): void {
    this.modalRef.hide();
  }

  manageUploadEvent(): void {
    this.closeUploadModal();
    this.getRules(this.ticketFaresData['idTicketFare']);
  }

}
