import { Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';

import swal2, { SweetAlertResult } from 'sweetalert2'
import { UtilsService } from '../../../services/utils.service';
import { LoadingLabels } from '../../../enums/loading.enum';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class UsersListComponent implements OnInit {

  // FORM
  @ViewChild('formUser') userForm: NgForm;

  idCompany = localStorage.getItem('idCompany');

  // OGGETTO LISTA ELEMENTI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  typeList: any[] = new Array();
  roleList: any[] = new Array();
   companiesList: any[] = new Array();
   filterFilterRole: any[] = new Array();
  // PATH CHIAMATE
  urlDeletePath = '';
  urlTypesPath = '/user/types';
  urlRolesPath = '/user/roles';
  urlCompany = '/company/companies';
  serviceToEnablePdf = '/ticket/pdfenable';
  urlDeletePdfPath = '/ticket/pdfenable/'
  companyVisible;
  visibleFilter;
  defaultValue;
  UpOrDown;
  roleUser;
  visibleCreationUser;
  typeUser;
  visiblePaging;
  isVisiblePdf;
  isCompany
  isSoluzione1;
  serviceToCallPath = '/account/refresh/password';
  errorMessage = '';

  constructor(
    private utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private loadingService: LoadingService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall('/users/list', false, true, 1, 10);
    this.listService.restURL = '/user/users';
  }

  async ngOnInit() {

    await this.getRoles();
    await this.getTypology();
    this.companiesList = await this.utils.getCompanyList();
    this.companyVisible = this.utils.visibleCompany(this.companyVisible);
    this.UpOrDown = true;
    this.roleUser = this.utils.getRoleUser();
    this.typeUser = this.utils.getTypeUser();
    this.visibleCreationUser = this.utils.checkCreationUser(this.roleUser);
    this.isCompany = this.utils.isComp(this.typeUser);
    this.isVisiblePdf = this.utils.isVisiblePdf();
    this.isSoluzione1 = this.utils.isSol1(this.utils.getTypeUser())

    // passo webapp/user/users?deleted=false : torna solo gli utenti che NON sono stati eliminati -> DELETED = 0
    this.dataFilter['deleted'] = false;

    this.loadElements()
    this.listService.paging = true;

  }

  idCompanyAdmin() {
    return this.utils.isAdmin(this.utils.getRoleUser());
  }

  translateTypeUser(typeUser) {
    return this.utils.translateTypeUser(typeUser);
  }

  changeStatus() {
    if (this.UpOrDown === true) {
      this.UpOrDown = false;
    } else {
      this.UpOrDown = true;
    }
  }

  getStatusDescription(statusNumber) {
    let buttonColor;
    if (statusNumber === true) {
   return   buttonColor =  'Attivo'
    }
    if (statusNumber === false) {
      return   buttonColor =  'Non Attivo'
       }
  }


  getButtonCircle(statusNumber) {
    let buttonColor;
    if (statusNumber === true) {
   return   buttonColor =  'btn btn-primary btn-circle'
    }
    if (statusNumber === false) {
      return   buttonColor =  'btn btn-danger btn-circle'
       }
  }

  getBusinessName(id) {
    let string;
    for (let company of this.companiesList) {
      if (company.idCompany === id) {
        return string = company.legalBusinessName
      }
    }
  }

  translateCodeRole(codeRole) {
    return this.utils.translateCodeRole(codeRole);
  }

  translateCodeRoleDiff(typeUser) {
    return this.utils.translateCodeRoleDiff(typeUser);
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  // FILTRI
  filterSearch() {
    this.listService.page = 1;
    this.dataFilter = this.userForm.value;
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset() {
    this.userForm.value.typeUser = ''
    this.userForm.value.roleUser = ''
    this.userForm.value.username = ''
    this.userForm.value.status = ''
    this.dataFilter = []
    this.userForm.reset();
    this.listService.visibleFilter = false;
    this.loadElements();
  }

  refreshPassword(data: any) {

   let model =  {
     idUser: data.idUser
   }

    this.listService.edit(model, this.serviceToCallPath).subscribe(
      response => {
        if (response.outcome.success === true) {
          swal2.fire('Cambio password sbloccato correttamente', '', 'success');
          this.loadElements()
        } else {
          this.errorMessage =
            response.outcome.code + response.outcome.description;
          swal2.fire('Attenzione', this.errorMessage, 'warning');
        }
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
        swal2.fire(
          'Errore',
          'Spiacente, si è verificato un errore tecnico.',
          'error'
        );
      }
    );
  }


  enablePdf(id, pdf) {

    if (pdf === null) {

    let model = {
      idResale: null,
      idUser: id
    };
    this.listService.newObject(model, this.serviceToEnablePdf).subscribe(
      response => {
        if (response.outcome.success === true) {
          swal2.fire('Stampa pdf Abilitata', '', 'success');
          this.loadElements()
        } else {
          this.errorMessage =
            response.outcome.code + response.outcome.description;
          swal2.fire('Attenzione', this.errorMessage, 'warning');
        }
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
        swal2.fire(
          'Errore',
          'Spiacente, si è verificato un errore tecnico.',
          'error'
        );
      }

    );
  } else {
      this.listService.delete(this.urlDeletePdfPath + pdf).subscribe(
        response => {
          if (response.outcome.success === true) {
            this.logger.log('Response value', response.value, 200);
            swal2.fire('L\'operatore non può stampare PDF', '', 'success')
              this.loadElements();
          } else {
            this.errorMessage =
              response.outcome.code + response.outcome.description;
            swal2.fire('Attenzione', this.errorMessage, 'warning');
          }
        },
        error => {
          this.logger.log('Error', error, 200);
          swal2.fire(
            'Errore',
            'Spiacente, si è verificato un errore tecnico.Riprova più tardi',
            'error'
          );
        }
      );
    }
  }
  getClassEnablePdf(pdf) {
    let value;

    if (pdf !== null) {

      value = 'btn btn-primary'
    } else {
      value = 'btn btn-danger'
    }

    return value;
  }


  getClassRefresh(refresh) {
    let value;

    if (refresh === false) {

      value = 'btn btn-warning'
    } else {
      value = 'btn btn-warning'
    }

    return value;
  }


  getClassIcon(pdf) {
    let value;
    if (pdf !== null) {
      value = 'fa fa-check';
    } else {
      value = 'fa fa-square';
    }

    return value;
  }

  getClassIconRefresh(refresh: boolean) {
    let value: string;
    if (refresh === false) {
      value = 'fas fa-lock'

    } else {
      value = 'fas fa-lock-open';
    }

    return value;
  }

  // SERVIZIO LISTA ELEMENTI
  loadElements() {
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;

        this.listService.totalRows = response.total;

        if (  this.listService.totalRows > 10) {
          this.visiblePaging = true;
        } else {
          this.visiblePaging = false;
        }

        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
      },
      (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    );
  }

  // SERVIZI SELECT
  async getTypology() {
    this.typeList = await this.listService.manageListValues(this.urlTypesPath, 'Lista tipologie');
  }

  async getRoles() {
    this.roleList = await this.listService.manageListValues(this.urlRolesPath, 'Lista ruoli');

    this.roleList.forEach(r => {
      if (this.isCompany) {
        if (
          r.codeRole === 'COMPANY_ADMINISTRATOR' ||
          r.codeRole === 'COMPANY_OPERATOR' ||
          r.codeRole === 'RESALE_ADMINISTRATOR'
        ) {
          this.filterFilterRole.push(r);
        }
      } else {
        this.filterFilterRole.push(r);
      }
    });
  }

  selectRow(idElement) {
    this.listService.selectedID = idElement;
    this.listService.backToList = true;
    localStorage.setItem('dataPassed', idElement);
    this.router.navigate(['/users/edit']);
  }

  newUser() {
    this.listService.backToList = true;
    this.router.navigate(['/users/new']);
  }

  askForDelete(idElement, deleted) {
    swal2.fire({
      icon: 'warning',
      title: 'Attenzione',
      text: 'Sei sicuro di voler eliminare l\'account utente selezionato? Si ricorda che non sarà possibile annullare la modifica in caso di errore.',
      confirmButtonText: 'Sì, sono sicuro!',
      showCancelButton: true,
      cancelButtonText: 'No, non sono sicuro!',
    }).then( res => {
      if ( res ) {
        this.deleteElement(idElement, deleted)
      } else {
        swal2.close()
      }
    })
  }

  // Cambiato 2021 elimina non DELETE ma con PUT e cambio campo deleted
  deleteElement(idElement, deleted) {

    this.urlDeletePath =  '/user/delete/' + idElement;

    let objPayload = {'deleted': deleted};

    this.loadingService.presentLoader(LoadingLabels.Edit);

    this.logger.log('ID DELETE:', idElement, 200);

    this.listService.edit(objPayload, this.urlDeletePath).subscribe(
      (response) => {
        if (response.outcome.success === true) {
          this.logger.log('Response value', response.value, 200);
          if (deleted === true) {
            swal2.fire('Eliminazione completata', 'L\'utente è stato eliminato con successo', 'success').then((ev: SweetAlertResult) => {
              if (ev.value) {
                this.loadElements();
              }
            });
          } else {
            swal2.fire('Ripristino completato', 'L\'utente è stato riabilitato con successo', 'success').then((ev: SweetAlertResult) => {
              if (ev.value) {
                this.loadElements();
              }
            });
          }
        } else {
          this.errorMessage =  response.outcome.code +  response.outcome.description
          swal2.fire('Attenzione', this.errorMessage, 'warning');
        }
      },
      (error) => {
        this.logger.log('Error', error, 200);
        swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.Riprova più tardi', 'error');
      }
    );
  }
}
