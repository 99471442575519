import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import { CookieService } from 'ngx-cookie-service';
import { UUID } from "angular2-uuid";
import { UtilsService } from "../../../services/utils.service";
import { ToastrService } from 'ngx-toastr';
import { SoldService } from "../../../services/soldHelper.service";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import swal2, { SweetAlertResult } from "sweetalert2";
import { LoadingLabels } from "../../../enums/loading.enum";
import { LoadingService } from "../../../services/loading.service";

@Component({
  selector: "app-sales-new",
  templateUrl: "./sales-new.component.html",
  styleUrls: ["../../../app.component.css"]
})
export class SalesNewComponent implements OnInit {

  @ViewChild("formSalesNew") salesNewForm: NgForm;

  constructor(
    private logger: LoggingService,
    private router: Router,
    private listService: ListService,
    private utils: UtilsService,
    private cookieService: CookieService,
    private toastr: ToastrService,
    private soldHelper: SoldService,
    private modalService: BsModalService,
    private loadingService: LoadingService
  ) {
    this.listService.restURL = "/printer/printers";
    this.listService.backToList = false;
    this.listService.paging = true; // server pagination
    this.listService.rows = 10;
    this.listService.page = 1;
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
  }

  errorMessage: string = "";

  //Array
  urlCredit;
  modalRef: BsModalRef;
  ticketCart: any[] = new Array();
  dataFilter: any[] = new Array();
  dataList: any[] = new Array();
  filterPriceList: any[] = new Array();
  typologyDataList: any[] = new Array();
  directionList: any[] = new Array();
  directionListR: any[] = new Array();
  tripList: any[] = new Array();
  tripListR: any[] = new Array();
  fareList: any[] = new Array();
  lineList: any[] = new Array();
  printerList: any[] = new Array();
  stopsDataList: any[] = new Array();
  stopsDataListA: any[] = new Array();
  stopsDataListR: any[] = new Array();
  dataTicketSell: any[] = new Array();
  dataTicketTypologyExtra: any[] = new Array();
  dataPriceList;
  constraintDataReturnWithoutLine;

  isVisibleLoader
  isVisibleLoaderR
  avaiableCredit;

  dataResponseTickets: any[] = new Array();

  //Model To add cart
  dataPricelistModel;

  //Value PcUUID
  defaultValuePcUUID;
  defaultIdPrinter;
  serialNumber;

  //Variable sold
  ticketSum;
  ticketTotalPrice;
  cartIsVoid;
  commonPrice;
  singlePrice;
  idPriceList;
  idCompany;
  tolerance;
  toleranceR;
  price;
  idTrip;
  idTripR;

  //Path
  pathRoutesList;
  pathLinetripList;
  pathUrlTypologyList;
  pathUrFareList;
  pathUrlLineList;
  pathUrlList;
  pathCompleteSell;
  pathCompleteSellWithPdf;
  pathConfirmedPayment;
  pathChekPdfAvaiable;

  //Constraint View
  constraintHour;
  constraintData;
  constraintDataReturn;
  visiblePriceList;
  directionsView;
  directionsViewR;

  idReturnDirectionModel;

  //Value
  //idLine
  valueIdLine;
  //description long line
  lineLongNameString;
  //type description
  ticketTypeNameString;
  existLine;
  directionValue;
  directionValueR;
  stringDataLine;
  stringDataLineR;
  listNameString;
  fareNameString;
  viewData;
  chooseList;
  hourString;
  directionString;
  pathUrlPrinterList;
  directionStringR;
  hourStringR;

  resetValue;

  showModal;
  visibleExtra;
  typeUser;
  soldChannel;

  isPdfEnabled; 
  todayString;
  channel;
  colorTheme = "theme-orange";
  bsConfig: Partial<BsDatepickerConfig>;

  public dateFrom = {
    // other options...
    dateFormat: "dd-mm-yyyy",
    inline: false,
    editableDateField: false,
    disableUntil: this.utils.yesterdayDate(),
    dayLabels: {
      su: "Dom",
      mo: "Lun",
      tu: "Mar",
      we: "Mer",
      th: "Gio",
      fr: "Ven",
      sa: "Sab"
    },
    monthLabels: {
      1: "Gen",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "Mag",
      6: "Giu",
      7: "Lug",
      8: "Ago",
      9: "Set",
      10: "Ott",
      11: "Nov",
      12: "Dic"
    }
  };
  public dateToReturn = {
    // other options...
    dateFormat: "dd-mm-yyyy",
    inline: false,
    editableDateField: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dayLabels: {
      su: "Dom",
      mo: "Lun",
      tu: "Mar",
      we: "Mer",
      th: "Gio",
      fr: "Ven",
      sa: "Sab"
    },
    monthLabels: {
      1: "Gen",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "Mag",
      6: "Giu",
      7: "Lug",
      8: "Ago",
      9: "Set",
      10: "Ott",
      11: "Nov",
      12: "Dic"
    }
  };

  async ngOnInit() {

    this.typeUser = this.utils.getTypeUser();

    this.soldChannel =  this.soldHelper.getTicketChannel(this.typeUser);
    this.avaiableCredit = 0
    this.urlCredit = "/transaction/resalescredit/"

    this.todayString = this.utils.today()
    this.channel = this.utils.getSoldChannel()

    if(this.channel == "3"){
      await this.getCredit()
    }

    this.pathUrlList = "/ticket/pricelist/pricelists?subscription=no&validOn="+this.todayString+"&idChannel="+this.soldChannel;
    this.pathRoutesList = "/route/routes";
    this.pathLinetripList = "/trip/linetriplist";
    this.pathUrFareList = "/ticket/fare/fares";
    this.pathUrlTypologyList = "/ticket/type/types";
    this.pathUrlLineList = "/route/routes";
    this.pathUrlPrinterList = "/printer/printers";
    this.pathCompleteSell = "/ticket";
    this.pathConfirmedPayment = "/ticket/sellconfirm";
    this.pathCompleteSellWithPdf = "/ticket/sellconfirm/PDF";
    this.pathChekPdfAvaiable = "/ticket/pdfenable"
 
    this.isVisibleLoader = false;
    this.isVisibleLoaderR = false;

    this.ticketSum = 1;
    this.chooseList = false;
    this.constraintHour = false;
    this.constraintData = false;
    this.constraintDataReturn = false;
    this.visiblePriceList = false;

    this.listNameString = "";
    this.fareNameString = "";
    this.ticketTypeNameString = "";
    this.lineLongNameString = "";
    if (this.ticketCart.length === 0) {
      this.cartIsVoid = true;
    } else {
      this.cartIsVoid = false;
    }
    this.ticketTotalPrice = this.ticketSum * 0.0;
    this.commonPrice = 0.0;
    
    await this.getPriceList();
    await this.getFareList();
    await this.getTypology();
    await this.getLineList();
    await this.getPrinterList();

    await this.checkEnable();

    this.existLine = false;
    this.viewData = false;
    this.directionsView = false;

    this.detectUUID();
    this.resetValue = null;
    this.showModal = false;

   
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  async checkEnable() {
    const res = await this.listService.manageListValues(this.pathChekPdfAvaiable, 'Risposta PDF', false);
    this.isPdfEnabled = res.outcome.success;
  }

  async getPrinterList() {
    this.printerList = await this.listService.manageListValues(this.pathUrlPrinterList, 'Stampanti');
    this.retrievePcUUID(this.printerList);
  }

  async getFareList() {
    this.fareList = await this.listService.manageListValues(this.pathUrFareList, 'Tariffe');
  }

  async getPriceList() {
    this.dataPriceList = await this.listService.manageListValues(this.pathUrlList, 'Listini');
  }

  async getTypology() {
    this.typologyDataList = await this.listService.manageListValues(this.pathUrlTypologyList, 'Tipologie');
  }

  async getLineList() {
    this.lineList = await this.listService.manageListValues(this.pathUrlLineList, 'Linee');
  }

  getTypologyName(idTypology) {
    return this.soldHelper.getTypologyName(idTypology, this.typologyDataList);
  }

  getdataTicketTypologyExtra(){
    this.dataTicketTypologyExtra = this.soldHelper.getDataPriceListFilter(this.dataPriceList, this.typologyDataList)
  }

  getLineDescriptionName(idLine) {
    return this.soldHelper.getLineDescriptionName(idLine, this.lineList);
  }

  getSerialNumber(id) {

    this.serialNumber = null
    var stringTest = id.split(":")[1];
    var stringtrip = stringTest.substring(1);
    var idPrinter = stringtrip;
    for (let p of this.printerList) {
      if (p.id == idPrinter) {
        this.serialNumber = p.serialNumber;
      }
    }
  }

  changeHourTrip(idTrip) {
    var stringTest = idTrip.split(":")[1];
    var stringtrip = stringTest.substring(1);

    this.hourString = "";

    this.idTrip = stringtrip;

    for (let trip of this.tripList) {
      if (trip.tripId == stringtrip) {
        this.hourString = trip.departureTime;
        this.stopsDataListA = trip.stopsData;
      }
    }
  }

  changeHourTripR(idTrip) {
    var stringTest = idTrip.split(":")[1];
    var stringtrip = stringTest.substring(1);

    this.hourStringR = "";

    this.idTripR = stringtrip;

    for (let trip of this.tripListR) {
      if (trip.tripId == stringtrip) {
        this.hourStringR = trip.departureTime;
        this.stopsDataListR = trip.stopsData;
      }
    }
  }

  changeTicketExtraValue() {}

  clearAll() {
    this.visiblePriceList = false;
    this.directionsView = false;
    this.constraintData = false;
    this.constraintDataReturn = false;
    this.constraintDataReturnWithoutLine = false;
    this.constraintHour = false;
    this.chooseList = false;
    this.directionsViewR = false;
    this.visibleExtra = false;
    this.isVisibleLoader = false;
    this.isVisibleLoaderR = false;

    this.listNameString = "";
    this.ticketTotalPrice = "";
    this.fareNameString = "";
    this.valueIdLine = "";
    this.lineLongNameString = "";
    this.ticketTypeNameString = "";
    this.directionString = "";
    this.hourString = "";
    this.hourStringR = "";
    this.directionStringR = "";
  }

  clearConstraint() {
    this.directionsView = false;
    this.constraintData = false;
    this.constraintDataReturn = false;
    this.constraintDataReturnWithoutLine = false;
    this.constraintHour = false;
    this.chooseList = false;
    this.hourString = null;
    this.directionsViewR = false;
    this.visibleExtra = false;
    this.isVisibleLoader = false;
    this.isVisibleLoaderR = false;
  }

  clearDataList() {
    this.tolerance = "";
    this.toleranceR = "";
    this.listNameString = "";
    (this.price = 0.0), (this.idCompany = 0);
    this.directionString = "";
    this.directionStringR = "";
    this.hourStringR = "";
    this.hourString = "";
  }

  onChange(dataList) {
    this.idReturnDirectionModel = "";

    if (
      this.salesNewForm.value.direction != null &&
      this.salesNewForm.value.direction != ""
    ) {
      this.salesNewForm.value.direction = " ";
      this.salesNewForm.controls["direction"].reset();
    }

    if (
      this.salesNewForm.value.date != null &&
      this.salesNewForm.value.date != ""
    ) {
      this.salesNewForm.value.date = " ";
      this.salesNewForm.controls["date"].reset();
    }

    this.clearConstraint();
    this.clearDataList();
    this.chooseList = true;
    this.ticketSum = 1;
    this.ticketTotalPrice = 0.0;
    var SubStringValue;
    SubStringValue = dataList.substring(3);

    this.idPriceList = SubStringValue;
    for (let priceListModel of this.dataPriceList) {
      if (priceListModel.idTicketPricelist == SubStringValue) {
        if (priceListModel.tolerance == null) {
          this.tolerance = priceListModel.validityOpen;
        } else {
          this.tolerance = priceListModel.tolerance;
        }

        if (priceListModel.toleranceR == null) {
        } else {
          this.toleranceR = priceListModel.tolerance;
        }

        if (priceListModel.tolerance != null && priceListModel.ar == true) {
          this.directionsViewR = true;
        }

        if(priceListModel.extra == true){
          this.visibleExtra = false
        }else{
          this.visibleExtra = true
        }

        this.listNameString = priceListModel.name;
        this.price = priceListModel.price;
        this.idCompany = priceListModel.idCompany;
        this.fareNameString = this.getFareName(priceListModel.idTicketFare);
        this.ticketTypeNameString = this.getTypologyName(
          priceListModel.idTicketType
        );
        this.lineLongNameString = this.getLineDescriptionName(
          priceListModel.idTrip
        );

        if (priceListModel.idTrip != null && priceListModel.extra != true) {
          this.existLine = true;
          this.valueIdLine = priceListModel.idTrip;
          this.directionsView = true;
        } else {
          this.valueIdLine = "Vincolata a nessuna linea";
          this.existLine = false;
          this.directionsView = false;
        }

        if (priceListModel.tolerance != null && priceListModel.extra != true) {
          this.directionsView = true;

          this.constraintData = true;
          this.constraintHour = true;
          this.getDirections();
        }

        if(priceListModel.validityOpen != null && priceListModel.validityOpenR != null && priceListModel.idTrip == null ) {
          this.constraintDataReturnWithoutLine = true
        }

        if (
          priceListModel.idTrip != null &&
          priceListModel.validityOpen != null &&
          priceListModel.tolerance == null &&
          priceListModel.validityOpenR == null && priceListModel.extra != true
        ) {
          this.directionsView = true;
          this.constraintHour = false;
          this.constraintData = true;
          this.constraintDataReturn = false;

          this.getDirections();
        }

        if (
          priceListModel.idTrip == null &&
          priceListModel.validityOpen != null
        ) {
          this.constraintData = true;
        }

        if (
          priceListModel.idTrip != null &&
          priceListModel.validityOpen != null &&
          priceListModel.validityOpenR != null
          && priceListModel.extra != true
        ) {
          this.directionsView = true;
          this.constraintData = true;
          this.constraintDataReturn = true;
          this.getDirections();
        }
        if (
          priceListModel.validityOpen != null &&
          priceListModel.validityOpenR == null
        ) {
          this.viewData = true;
        }


        if (
          priceListModel.extra == true

        ) {
          this.constraintData = true;
        }

        this.dataPricelistModel = priceListModel;
        this.singlePrice = this.dataPricelistModel.price;
      }
    }

    this.ticketTotalPrice = this.ticketSum * this.singlePrice;
  }

  retrievePcUUID(printerList) {
    for (let p of printerList) {
      if (p.pcUUID == this.defaultValuePcUUID) {
        this.defaultIdPrinter = p.id;
        this.serialNumber = p.serialNumber;
      }
    }
  }

  onChangeFare(idFare) {
    this.clearAll();

     this.getdataTicketTypologyExtra();

    if (
      this.salesNewForm.value.list != null &&
      this.salesNewForm.value.list != ""
    ) {
      this.salesNewForm.value.list = " ";
      this.salesNewForm.controls["list"].reset();
    }

    this.filterPriceList = [];

    var stringFare = idFare.substring(3);

    for (let p of this.dataPriceList) {
      if (p.idTicketFare == stringFare && p.tripMaxDay == null) {
        this.filterPriceList.push(p);
      }
    }
    if (this.dataPriceList.length > 0) {
      this.visiblePriceList = true;
    } else {
      this.visiblePriceList = false;
    }
  }

  onDateChanged(stringData: Date) {

    this.stringDataLine = this.utils.convertDateToISO(stringData);

    let copy = this.getCopyOfOptions();
    copy.disableUntil = this.utils.disableReturnData(this.stringDataLine);
    this.dateToReturn = copy;

    var ar = false;
    this.isVisibleLoader = true;
    this.getHourTrip(ar);
  }

  getCopyOfOptions() {
    return JSON.parse(JSON.stringify(this.dateToReturn));
  }

  onDateChangedR(stringData) {
    this.stringDataLineR = this.utils.stringToServerFormat(
      this.utils.convertDateToISO(stringData)
    );

    var ar = true;
    this.isVisibleLoaderR = true;
    this.getHourTrip(ar);
  }

  getDirection(directionValue) {
    var string = directionValue.substring(3);
    this.directionValue = string;

    for (let d of this.directionList) {
      if (d.directionId == this.directionValue) {
        this.directionString = d.arrival;
      }
    }
    this.directionListR = [];

    if (this.directionsViewR == true || this.constraintDataReturn) {
      for (let d of this.directionList) {
        if (d.directionId != this.directionValue) {
          this.idReturnDirectionModel = d.directionId;

          this.directionValueR = this.idReturnDirectionModel;
          this.directionStringR = d.arrival;

          this.directionListR.push(d);
        }
      }
    }
  }

  getDirectionR(directionValue) {
    var string = directionValue.substring(3);
    this.directionValueR = string;

    for (let d of this.directionList) {
      if (d.directionId == this.directionValueR) {
        this.directionStringR = d.arrival;
      }
    }
  }

  getHourTrip(ar) {

    var stringData;
    var stringDirection;
    if (ar == true) {
      stringData = this.stringDataLineR;
      stringDirection = this.directionValueR;
    } else {
      stringData = this.stringDataLine;
      stringDirection = this.directionValue;
    }

    //DIRECTION VALUE
    var filterPAth =
      "?date=" +
      stringData +
      "&line=" +
      this.valueIdLine +
      "&directionId=" +
      stringDirection;

    this.listService.getListSelect(this.pathLinetripList + filterPAth).subscribe(
      {
        next: response => {
          if (ar == true) {
            this.tripListR = response.results;
            this.isVisibleLoaderR = false;
          } else {
            this.tripList = response.results;
            this.isVisibleLoader = false;
          }
        
          this.logger.log("Lista Corse", this.tripList, 300);
        },
        error: error => {
          if( this.isVisibleLoader == true){
            this.isVisibleLoader = false;
          }

          if( this.isVisibleLoaderR == true){
            this.isVisibleLoaderR = false;
          }
         
          this.logger.log("Error", error, 200);
        }
      });
  }

  async getDirections() {
    const line = this.valueIdLine;
    const stringPath = "/" + line;

    this.directionList = await this.listService.manageListValues(this.pathRoutesList + stringPath)
  }

  pageChange(page) {
    this.listService.page = page;
  }

  detectUUID() {
    if (
      this.cookieService.get("BU-UUID") == undefined
    ) {
      let uuid = UUID.UUID();
      this.cookieService.set("BU-UUID", uuid);
    }
    this.defaultValuePcUUID = this.cookieService.get("BU-UUID");
  }

  putInCart() {
    var ticketModel = new TicketModel();
    this.cartIsVoid = true;

    if (this.idTripR != null && this.idTripR != "") {
      ticketModel.idTripR = this.idTripR;
    }

    if (
      this.valueIdLine != null &&
      this.valueIdLine != "Vincolata a nessuna linea"
    ) {
      ticketModel.idTrip = this.idTrip;
    }

    // ticketModel.idTrip = this.idTrip;
    ticketModel.ar = this.dataPricelistModel.ar;
    ticketModel.idPriceList = +this.idPriceList;

    if(this.tolerance == null || this.tolerance == 0){
      ticketModel.tolerance = 999999;
    }else{
      ticketModel.tolerance = +this.tolerance;
    }

    ticketModel.price = this.price;
    ticketModel.idCompany = +this.idCompany;
    ticketModel.rate = this.dataPricelistModel.idTicketFare;

    ticketModel.amount = this.ticketSum;
    ticketModel.totalPrice = this.ticketTotalPrice;
    ticketModel.typeTicketString = this.ticketTypeNameString;
    ticketModel.line = this.valueIdLine;
    ticketModel.listNameString = this.listNameString;

    if (this.directionString != null && this.directionString != "") {
      ticketModel.direction = this.directionString;
    }
    if (this.directionStringR != null && this.directionStringR != "") {
      ticketModel.directionR = this.directionStringR;
    }

    if (this.hourStringR != null && this.hourStringR != "") {
      ticketModel.hourReturn = this.hourStringR;
      ticketModel.dateReturn = this.stringDataLineR;
    }

    if (this.stringDataLineR != null && this.stringDataLineR != "") {
      ticketModel.dateReturn = this.stringDataLineR;
    }

    if (this.hourString != null && this.hourString != "") {
      ticketModel.hour = this.hourString;
      ticketModel.date = this.stringDataLine;
    }

    if (
      this.stringDataLine != null &&
      this.stringDataLine != "" &&
      (this.hourString == null || this.hourString == "")
    ) {
      ticketModel.date = this.stringDataLine;
      ticketModel.hour = this.utils.getTime();
    }
    this.ticketCart.push(ticketModel);

    if(this.salesNewForm.value.extra != null &&  this.salesNewForm.value.extra != undefined && this.salesNewForm.value.extra != "noExtra" )
    // if (this.salesNewForm.value.extra != "noExtra") 
    {
      var idPriceListInfo = this.salesNewForm.value.extra;

      var ticketExtra = new TicketModel();

      if(ticketModel.ar == true){
        // x 2
      }

      if(ticketModel.amount){
        // x amunt
      }
    
      ticketExtra = this.soldHelper.getInfoPriceList(
        idPriceListInfo,
        this.dataPriceList,
        this.stringDataLine, 
        ticketModel
      );

      this.ticketCart.push(ticketExtra);
    }

    this.cartIsVoid = false;
    Math.ceil(this.ticketTotalPrice * 100) / 100;

    this.commonPrice = this.soldHelper.getCommonPrice(this.ticketCart)

   // this.commonPrice = this.commonPrice + this.ticketTotalPrice;
    this.commonPrice = Math.ceil(this.commonPrice * 100) / 100;
    this.ticketSum = 1;
    this.ticketTotalPrice = this.ticketSum * this.singlePrice;
    this.ticketTotalPrice = Math.ceil(this.ticketTotalPrice * 100) / 100;
  }

  ticketsDetails() {
    swal2.fire("Dettaglio Biglietto!", "", "success");
  }

  nullSold() {
    this.ticketCart = [];
    this.cartIsVoid = true;
    this.commonPrice = 0.0;
    swal2.fire("Vendita Annullata!", "", "success");
  }

  completeSold() {
    var count = 0;
    for (let model of this.ticketCart) {
      count = count + model.totalPrice;
    }

    swal2.fire("Confermata Vendita!", "la somma è !" + count, "success");
    this.ticketCart = [];
    this.commonPrice = 0.0;
    this.cartIsVoid = true;
  }

  addTicket() {
    this.ticketSum = this.ticketSum + 1;
    this.ticketTotalPrice = this.ticketSum * this.singlePrice;
    this.ticketTotalPrice = Math.ceil(this.ticketTotalPrice * 100) / 100;
  }

  removeTicket() {
    if (this.ticketSum > 1) {
      this.ticketSum = this.ticketSum - 1;
      this.ticketTotalPrice = this.ticketSum * this.singlePrice;
      this.ticketTotalPrice = Math.ceil(this.ticketTotalPrice * 100) / 100;
    }
  }

  deleteFromCart(data) {
    var price = data.totalPrice;
    this.commonPrice = this.commonPrice - price;
    this.commonPrice = Math.ceil(this.commonPrice * 100) / 100;
    this.ticketCart = this.ticketCart.filter(obj => obj !== data);


    if (this.ticketCart.length == 0) {
      this.nullSold();
    }
  }

  ticketExtraRemove(data){

    if(data.amount == 1){

    }else{
      data.amount = data.amount - 1;
      data.totalPrice = data.totalPrice - data.price
      data.totalPrice = Math.ceil( data.totalPrice * 100) / 100;

      this.commonPrice = this.commonPrice - data.price;
      this.commonPrice = Math.ceil(this.commonPrice * 100) / 100;
    }

  }

  ticketExtraAdd(data){
    data.amount = data.amount  + 1;
    data.totalPrice = data.totalPrice + data.price
    data.totalPrice = Math.ceil( data.totalPrice * 100) / 100;

    this.commonPrice = this.commonPrice + data.price;
    this.commonPrice = Math.ceil(this.commonPrice * 100) / 100;
  }

  completeSell() {
    var arrayList: any[] = new Array();
    //   var filterArray: any = new Array();

    var count = 0;

    for (let t of this.ticketCart) {

      if(this.serialNumber == undefined || this.serialNumber == null){
        //CONTROLLO POI ELIMINO
        localStorage.setItem("PDF", "printPDF")
      }else{
        localStorage.setItem("PDF", "noPDF")
      }
      
      if (t.amount > 1 && t.ar == false) {
        for (var i = 0; i < t.amount; i++) {
          var ticket = new Ticket();
          ticket.idCompany = t.idCompany;
          ticket.usableFrom = t.date + " " + t.hour;

          ticket.idTrip = t.idTrip;
          ticket.idTicketPricelist = t.idPriceList;
          ticket.tolerance = t.tolerance;
          ticket.price = t.price;

          ticket.printerSerialNumber = this.serialNumber;
        
          //     count = count + 1
          //     ticket.id = count
          ticket.idTicketChannel = this.soldChannel;
          arrayList.push(ticket);
        }
      } else if (t.amount > 1 && t.ar == true) {
        for (var i = 0; i < t.amount; i++) {
          var ticket = new Ticket();
          var ticketReturn = new Ticket();

          ticket.idCompany = t.idCompany;
          ticket.usableFrom = t.date + " " + t.hour;

          ticket.idTrip = t.idTrip;
          ticket.idTicketPricelist = t.idPriceList;
          ticket.tolerance = t.tolerance;
          ticket.price = t.price;
          ticket.idTicketChannel = this.soldChannel;
          ticket.printerSerialNumber = this.serialNumber;

          count = count + 1;
          ticket.id = count;
          arrayList.push(ticket);

          ticketReturn.idCompany = t.idCompany;

          if (t.hourReturn != null && t.hourReturn != "") {
            ticketReturn.usableFrom = t.dateReturn + " " + t.hourReturn;
          } else {
            ticketReturn.usableFrom = t.dateReturn + " " + "00:00:01";
          }

          ticketReturn.idTrip = t.idTripR;
          ticketReturn.idTicketPricelist = t.idPriceList;
          ticketReturn.tolerance = t.tolerance;

          //DA TOGLIERE
          ticketReturn.price = 0.0;

          ticketReturn.idLinkedTicket = count;
          ticketReturn.printerSerialNumber = this.serialNumber;
          ticketReturn.idTicketChannel = this.soldChannel;

          //   count = count + 1
          //  ticketReturn.id = count
          arrayList.push(ticketReturn);
        }
      } else if (t.amount == 1 && t.ar == true) {
        var ticket = new Ticket();
        var ticketReturn = new Ticket();

        ticket.idCompany = t.idCompany;
        ticket.usableFrom = t.date + " " + t.hour;

        ticket.idTrip = t.idTrip;
        ticket.idTicketPricelist = t.idPriceList;
        ticket.tolerance = t.tolerance;
        ticket.price = t.price;
        ticket.printerSerialNumber = this.serialNumber;
        ticket.idTicketChannel = this.soldChannel;
        count = count + 1;
        ticket.id = count;
        arrayList.push(ticket);

        ticketReturn.idCompany = t.idCompany;

        if (t.hourReturn != null && t.hourReturn != "") {
          ticketReturn.usableFrom = t.dateReturn + " " + t.hourReturn;
        } else {
          ticketReturn.usableFrom = t.dateReturn + " " + "00:00:01";
        }

        ticketReturn.idTrip = t.idTripR;
        ticketReturn.idTicketPricelist = t.idPriceList;
        ticketReturn.tolerance = t.tolerance;

        //DA TOGLIERE
        ticketReturn.price = 0.0;

        ticketReturn.idLinkedTicket = count;
        ticketReturn.idTicketChannel = this.soldChannel;
        ticketReturn.printerSerialNumber = this.serialNumber;
        // count = count + 1
        //  ticketReturn.id = count
        arrayList.push(ticketReturn);
      } else if (t.amount == 1 && t.ar == false) {
        var ticket = new Ticket();
        ticket.idCompany = t.idCompany;
        ticket.usableFrom = t.date + " " + t.hour;

        ticket.idTrip = t.idTrip;
        ticket.idTicketPricelist = t.idPriceList;
        ticket.tolerance = t.tolerance;
        ticket.price = t.price;
        ticket.printerSerialNumber = this.serialNumber;
        ticket.idTicketChannel = this.soldChannel;
        //   count = count + 1
        //   ticket.id = count
        arrayList.push(ticket);
      }
    }

    var ticketToSend = new TicketCreate();
    ticketToSend.tickets = arrayList;
    var errorTicketList: any = new Array();

    //SCELTA DEL PATH PER STAMPARE IL PDF O CON STAMPANTE TERMICA
    var checkPdfPrinteUrl;
    if (this.serialNumber != null || this.serialNumber != undefined) {
      checkPdfPrinteUrl = this.pathCompleteSell;
    } else {
      checkPdfPrinteUrl = this.pathCompleteSellWithPdf;
    }

    this.listService.newObject(ticketToSend, this.pathCompleteSell).subscribe(
      response => {
        if (response.outcome.success === true) {
          var count = 0;

          for (let t of response.results) {
            if (t.outcome.success == true) {
              this.toastr.success('Biglietto correttamente stampato', 'Vendita completata');
              this.dataResponseTickets.push(t);
            }
            if (t.outcome.code == "0019") {
              count = count + 1;
            this.toastr.error(
             "",
             "Il biglietto non è stato stampato perchè la stampante non è pronta per la stampa"
           );
             this.dataResponseTickets.push(t);
            }
            if (t.outcome.code == "0018") {
              count = count + 1;
              this.toastr.error(
                "",
                "Il biglietto non è stato stampato perchè la stampante non è raggiungibile"
              );
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0020") {
              count = count + 1;
              this.toastr.error("", "Il biglietto non è stato stampato");
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0021") {
              count = count + 1;
              this.toastr.error(
                "",
                "Il biglietto non è stato stampato, perche non è stata individuata la stampante"
              );
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0022") {
              count = count + 1;
             this.toastr.error(
               "",
               "Il biglietto non è stato stampato, perche la stampante non è raggiungibile"
             );
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0023") {
             count = count + 1;
             this.toastr.error("", "Il biglietto non è stato stampato");
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0024" || t.outcome.code == "0025") {
              count = count + 1;
             this.toastr.error("", "Errore nella stampa del biglietto");
              this.dataResponseTickets.push(t);
            }
          }

          if (count == 0 && (this.serialNumber != null || this.serialNumber != undefined)) {
            swal2.fire("La stampa è avvenuta con successo", "", "success");
          } else if(count > 0 ) {
            swal2.fire(
              "Attenzione",
              "Alcuni biglietti potrebbero non essere stati stampati",
              "warning"
            );
          }

          //PASSARE AL SOMMARIO IN QUESTO PUNTO PASSANDOGLI L'ARRAY RICEVUTO DAL SERVER
          localStorage.setItem(
            "dataResponseTicket",
            JSON.stringify(this.dataResponseTickets)
          );
          this.router.navigate(["/summarySale"]);
        } else {
          if (response.outcome.code == "0013") {
            this.errorMessage =
              "Il credito all'interno del wallet non è sufficiente per completare l'acquisto";
          } else if (response.outcome.code == "0015") {
            this.errorMessage =
              "Il credito all'interno del wallet non è sufficiente per completare l'acquisto";
          } else {
            this.errorMessage = "Generic Error";
          }

          swal2.fire("Attenzione", this.errorMessage, "warning");
        }
      },
      error => {
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        swal2.fire(
          "Errore",
          "Spiacente, si è verificato un errore tecnico.",
          "error"
        );
      }
    );

    var count = 0;
    for (let model of this.ticketCart) {
      count = count + model.totalPrice;
    }

    if(this.serialNumber != null || this.serialNumber != undefined)
    
    this.loadingService.presentLoader(LoadingLabels.TicketPrint);  

    this.ticketCart = [];
    this.commonPrice = 0.0;
    this.cartIsVoid = true;
  }

  getFareName(fareid) {
    var string;
    for (let f of this.fareList) {
      if (fareid == f.idTicketFare) {
        return (string = f.name);
      }
    }
  }

  choseList(v, template: TemplateRef<any>) {

        this.openModal(template)

    if (v == "a") {
      this.stopsDataList = this.stopsDataListA;
    } else {
      this.stopsDataList = this.stopsDataListR;
    }
  }

  confirmPayments(ticketConfirmed) {
    var mySelf = this;

    swal2.fire({
      title: "Conferma Pagamento",
      text: "Il pagamento è avvenuto correttamente?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sì, convalida il biglietto",
      cancelButtonText: "No",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then(
      function(ev: SweetAlertResult) {
        if (ev.value) {
          mySelf.confirm(ticketConfirmed);
        }
      },
      function(dismiss) {
        if (dismiss === "cancel") {
          swal2.close();
        }
      }
    );
  }

  confirm(tickets) {
    this.listService.edit(tickets, this.pathConfirmedPayment).subscribe(
      response => {
        if (response.outcome.success === true) {
          swal2.fire("I biglietti sono stati validati", "", "success");
          //  this.router.navigate(["/vehicles/list"]);
        } else {
          this.errorMessage =
            response.outcome.code + response.outcome.description;
          swal2.fire("Attenzione", this.errorMessage, "warning");
        }
      },
      error => {
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        swal2.fire(
          "Errore",
          "Spiacente, si è verificato un errore tecnico.",
          "error"
        );
      }
    );
  }

  async getCredit() {
    const credit = await this.listService.manageListValues(this.urlCredit);
    credit.forEach(c => {
      this.avaiableCredit = c.creditAmount;
    });
  }
}

export class TicketModel {
  ar;
  rate;
  amount;
  totalPrice;
  idPriceList;
  tolerance;
  price;
  idCompany;
  typeTicketString;
  listNameString;
  hour;
  idTrip;
  date;
  dateReturn;
  hourReturn;
  directionIdR;
  directionR;
  idTripR;
  rateId;
  direction;
  directionId;
  line;
  lineID;
  constructor() {}
}

export class Ticket {
  id;
  idCompany;
  idLinkedTicket;
  idTicketPricelist;
  idTicketChannel;
  idTrip;
  usableFrom;
  usableTo;
  tolerance;
  price;
  printerSerialNumber;
  
  constructor() {}
}

export class TicketCreate {
  tickets: any[] = Array();
  constructor() {}
}

export class TicketConfirmed {
  tickets: any[] = Array();
  total;
  constructor() {}
}

export class TicketToConfirm {
  idTicket;
  codeEncrypted;
  constructor() {}
}
