<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-10 col-sm-offset-1 col-md-offset-1">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h2>{{kindTitle}} tariffa abbonamento</h2>
        </div>
        <form (ngSubmit)="onSubmit()" #ticketRatesForm="ngForm">
          <div class="ibox-content">
            <div class="col-md-12">
              <div *ngIf="sol1OrSa" style="padding-left: 30px" class="form-group col-md-12">
                <label for="idCompany"> Azienda di trasporto </label>
                <select type="text" id="idCompany" class="form-control" ngModel name="idCompany"
                  (change)="onChangeCompany($event.target)" idCompany #idCompany="ngModel"
                  [ngModel]="ticketFaresData?.idCompany" required>
                  <option *ngFor="let o of companies" [ngValue]='o.idCompany'>{{o.legalBusinessName}}</option>
                </select>
              </div>
            </div>

            <div class="col-md-12">
              <h2 style="padding-left: 30px"> Seleziona le caratteristiche della tariffa</h2>
              <div style="padding-left: 30px" class="form-group col-md-12">
                <label for="description">Descrizione</label>
                <input type="text" id="description" name="description" class="form-control" ngModel
                  [ngModel]="ticketFaresData?.name" required #name="ngModel">
              </div>
            </div>

            <div style="padding-left: 30px" class="col-md-6">
              <div class="form-group col-md-12">
                <label for="description">Tipologia</label>
                <select [disabled]="kindManage == 0" type="text" id="categoryId" class="form-control" ngModel
                  name="categoryId" required (change)="onChangeLine($event.target.value)" categoryId
                  #categoryId="ngModel" [ngModel]="ticketFaresData?.idCategory" required>
                  <option *ngFor="let c of categories" [ngValue]="c.categoryId">{{c.description}}</option>
                </select>
              </div>


              <div class="form-group col-md-12">
                <label for="description">Prezzo</label>
                <div class="input-group">
                  <input type="number" id="price" min="0" name="price" class="form-control" ngModel
                    [ngModel]="ticketFaresData?.price" required price #price="ngModel">
                  <span class="input-group-addon">€</span>
                </div>
              </div>

              <div class="form-group col-md-12 col-xl-8">
                <label for="description">Validità</label>
                <input class="form-control" id="bsDaterangepicker" name="bsDaterangepicker" [(ngModel)]="bsRangeValue"
                  ngModel bsDaterangepicker #bsDaterangepicker required placement="right" [bsConfig]="bsConfig"
                  placement="bottom" autocomplete="off">
              </div>

              <div class="form-group col-md-12 col-xl-4">
                <app-switch-button [config]="switchButtonConfig" (onSwitch)="validitySwitch($event)"></app-switch-button>
              </div>

            </div>

            <div class="col-md-6">
              <h4>Canali di vendita abilitati</h4>
              <ng-container>
                <div *ngFor="let chan of dataChannel; let i = index" clas="col-md-6">
                  <div class="checkbox">
                    <input type="checkbox" name="listChannels" id="listChannels" listChannels
                      [checked]=isCheckChannel(chan.idChannel) (change)="setIdChannel(chan.idChannel)">
                    <label>
                      <b>{{chan.name}}</b>
                    </label>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="col-md-12" style="padding-left: 30px">
              <h2> Seleziona le caratteristiche dell'abbonamento</h2>
              <div class="col-md-12">
                <label>
                  <b>Biglietti validi per la LINEA ( tutte le corse entrambe le direzioni)</b>
                </label>
              </div>
              <div class="form-group col-md-12" style="margin-top: 10px; margin-bottom: 10px">
                <select placeholder="LINEA" type="text" id="routeId" name="routeId" class="form-control" ngModel
                  [ngModel]="ticketFaresData?.routeId" required #routeId="ngModel"
                  (ngModelChange)="chooseLineSubscription()">
                  <option *ngFor="let l of lines" [ngValue]="l.routeId">{{l.routeLongName}}</option>
                </select>
              </div>

              <div class="col-md-6">
                <label>
                  <b>Numero massimo di obliterazioni per GIORNO</b>
                </label>
                <div>
                  <input type="number" id="tripMaxDay" min="0" name="tripMaxDay" class="form-control" ngModel
                    [ngModel]="ticketFaresData?.tripMaxDay" required tripMaxDay #tripMaxDay="ngModel">
                </div>
              </div>

              <div class="form-group col-md-6">
                <label>
                  <b>Numero massimo di obliterazioni per PERIODO</b>
                </label>
                <div>
                  <input type="number" id="tripMaxPeriod" min="0" name="tripMaxPeriod" class="form-control" ngModel
                    [ngModel]="ticketFaresData?.tripMaxPeriod" required tripMaxPeriod #tripMaxPeriod="ngModel">
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>

          <div class="ibox-footer">
            <button class="btn btn-default" (click)="returnToList()" type="button">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!ticketRatesForm.valid">Salva
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>