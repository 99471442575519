import { Component, OnInit,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";

import swal2 from 'sweetalert2'
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-trips-list',
  templateUrl: './trips-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class TripsListComponent implements OnInit {

  //FORM
  @ViewChild('formTrips') tripsForm: NgForm;
  //OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  companiesList: any[] = new Array();
  routeList: any[] = new Array();
  urlCompany = "/company/companies";
  routeListPath = "/route/routes";
  //PATH CHIAMATE
  urlDeletePath = "";
  errorMessage: string = "";
  typeUser;
  isSoluzione1;

  constructor(private logger: LoggingService, private router: Router, public listService: ListService, public utils: UtilsService){
    this.listService.resetList();
    this.listService.configurationServiceCall("/trips/list",false,true,1,15);
    this.listService.restURL = "/trip/trips";
  }

  async ngOnInit() {
    this.listService.visibleFilter = false;
   
    this.companiesList = await this.utils.getCompanyList();
    await this.getRouteList() ;
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.loadElements();
  }

  pageChange(page){
    this.listService.page = page; 
    this.loadElements();
  }

  selectRow(dataElement){
    this.listService.selectedID = dataElement["id"];
    this.listService.backToList = true;
    localStorage.setItem("dataPassed",JSON.stringify(dataElement));
    this.router.navigate(['/trips/edit']);
  }

  //FILTRI
  filterSearch(){
    this.dataFilter = this.tripsForm.value;
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset(){

    this.tripsForm.value.routeId = ""
    this.tripsForm.value.idCompany = ""
    this.tripsForm.value.routeLongName = ""
    this.tripsForm.value.directionId = ""

    this.listService.visibleFilter = false;
    this.loadElements();
     this.tripsForm.reset();
   
    //this.loadElements();
  }

  newTrip(){
    
  }

  getCompanyName(idCompany){
    var string;
    for(let c of this.companiesList){
      if(c.idCompany == idCompany){
        return string = c.legalBusinessName;
      }
    }
  }

  getDirection(dir){
  var string;
    if(dir == 0){
      return string = "Andata"
    }else{
      return string ="Ritorno"
    }
  }

  async getRouteList() {
    this.routeList = await this.listService.manageListValues(this.routeListPath, 'Lista linee');
  }

  //SERVIZIO LISTA ELEMENTI
  loadElements(){
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;

        this.logger.log("Response:",response,300);
        this.errorMessage = "";
      },    
      (error) => { 
        this.logger.log("Error",error,200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }
}
