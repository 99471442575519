import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import swal2 from "sweetalert2";
import { UtilsService } from "../../../services/utils.service";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";

@Component({
  selector: "app-statistics-sold",
  templateUrl: "./statistics-sold.component.html",
  styleUrls: ["../../../app.component.css"]
})
export class StatisticsSoldComponent implements OnInit {
  @ViewChild("formStatsSold") statsSoldForm: NgForm;

  //OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();

  dataFilter: any[] = new Array();
  dataOperator: any[] = new Array();
  dataChannel: any[] = new Array();
  dataResale: any[] = new Array();
  transactionData: any[] = new Array();
  resale;
  dataCompany: any[] = new Array();

  filterUserData: any[] = new Array();

  //PATH CHIAMATE
  urlDeletePath = "";
  urlChannelPath = "/ticket/channel/channels";
  urlResalePath = "/resale/resales";
  urlOperatorPath = "/user/users?deleted=false";//cerco utenti NON eliminati 
  urlTransactionPath = "/stats/sold/analysis/ticket?idTransaction=";
  urlCompanyPath = "/company/companies";
  urlCsvSoldPath = "/stats/sold/csv";
  totalAmount;
  isSoluzione1;
  typeUser;

  errorMessage: string = "";

  colorTheme = "theme-orange";
  bsConfig: Partial<BsDatepickerConfig>;

  modalRef: BsModalRef;

  constructor(
    public utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private modalService: BsModalService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall(
      "/statistics/sold",
      false,
      true,
      1,
      12
    );
    this.listService.restURL = "/stats/sold";
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
  }

  async ngOnInit() {
    await this.getChannels();
    await this.getOperators();
    await this.getResales();

    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    if (this.isSoluzione1) {
      this.getCompanies();
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements();
  }

  getFilter() {
    this.dataFilter = this.statsSoldForm.value;

    if (
      this.statsSoldForm.value.dateSellFrom != null ||
      this.statsSoldForm.value.dateSellFrom != undefined
    ) {
      if (
        this.statsSoldForm.value.dateSellFrom != null ||
        this.statsSoldForm.value.dateSellFrom != undefined
      )
        this.dataFilter["dateSellFrom"] = this.utils.stringToServerFormat(
          this.utils.convertDateToISO(this.statsSoldForm.value.dateSellFrom)
        );
    }

    if (
      this.statsSoldForm.value.dateSellTo != null ||
      this.statsSoldForm.value.dateSellTo != undefined
    ) {
      if (
        this.statsSoldForm.value.dateSellTo != null ||
        this.statsSoldForm.value.dateSellTo != undefined
      ) {
        this.dataFilter["dateSellTo"] = this.utils.stringToServerFormat(
          this.utils.convertDateToISO(this.statsSoldForm.value.dateSellTo)
        );
      }
    }
  }
  //FILTRI
  filterSearch() {
    this.getFilter();
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset() {
    (this.statsSoldForm.value.dateSellFrom = ""),
      (this.statsSoldForm.value.dateSellTo = "");
    this.statsSoldForm.value.idTicketChannel = "";
    this.statsSoldForm.value.idResale = "";
    this.statsSoldForm.value.idUser = "";
    this.statsSoldForm.value.idCompany = "";
    this.statsSoldForm.reset();
    this.listService.visibleFilter = false;
    this.loadElements();
  }

  //SERVIZI SELECT
  async getChannels() {
    this.dataChannel = await this.listService.manageListValues(this.urlChannelPath, 'Lista canali');
  }

close(){
  this.modalRef.hide()
}


  getCsv() {
    this.getFilter();
    var queryString = this.utils.getQueryWithFilters(this.dataFilter);
    var finalQueryString;
    if (queryString == "" || queryString == null) {
      finalQueryString = this.urlCsvSoldPath;
    } else {
      finalQueryString = this.urlCsvSoldPath + "?" + queryString;
    }

    this.listService.getCsv(finalQueryString).subscribe(
      response => {
        if (response) {
          var blob = new Blob([response], { type: "text/csv" });
          var ieEDGE = navigator.userAgent.match(/Edge/g);
          var ie = navigator.userAgent.match(/.NET/g); // IE 11+
          var oldIE = navigator.userAgent.match(/MSIE/g);
         
          var fileName = ""
          var currentDate = new Date()
          var day = currentDate.getDate()
          var month = currentDate.getMonth() + 1
          var year = currentDate.getFullYear()
          fileName = day + "/" + month + "/" + year + "_statistics_sold.csv";

          if (ie || oldIE || ieEDGE) {
            (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
          } else {
            var elem = window.document.createElement("a");
            elem.href = window.URL.createObjectURL(blob);
            elem.download = fileName;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          }

          swal2.fire("Il csv è stato scaricato", "", "success");
        } else {
          // response.outcome.code + response.outcome.description;
          swal2.fire("Attenzione", this.errorMessage, "warning");
        }
      },
      error => {
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        swal2.fire(
          "Errore",
          "Spiacente, si è verificato un errore tecnico.",
          "error"
        );
      }
    );
  }

  async getOperators() {
    this.dataOperator = await this.listService.manageListValues(this.urlOperatorPath);
  }

  getPaymentType(string) {
    var value;

    if (string == "CASH") {
      value = "Contante";
    } else {
      value = "POS";
    }
    return value;
  }

  async getResales() {
    this.dataResale = await this.listService.manageListValues(this.urlResalePath, 'Lista rivendite');
  }

  filterUser() {
    for (let u of this.dataOperator) {
      if (
        u.roleUser == "OPERATOR" ||
        u.roleUser == "MOBILE" ||
        (u.typeUser == "RESALE" && u.roleUser == "ADMINISTRATOR") ||
        u.roleUser == "SELLER"
      ) {
        this.filterUserData.push(u);
      }
    }

    this.loadElements();
  }

  //SERVIZIO LISTA ELEMENTI
  loadElements() {
    this.listService.getListFilter(this.dataFilter).subscribe(
      response => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;
        this.totalAmount = response.totalAmount;

        this.logger.log("Response:", response, 300);
        this.errorMessage = "";
      },
      error => {
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  selectRow(dataElement) {
    this.listService.selectedID = dataElement["id"];
    this.listService.backToList = true;
    localStorage.setItem("dataPassed", JSON.stringify(dataElement));
    this.router.navigate(["/statistics/ticket"]);
  }

  async viewDetailsTicket(idTransaction, template: TemplateRef<any>) {
    await this.getDetailsTicket(idTransaction);
    this.openModal(template)
  }

  async getDetailsTicket(idTransaction: number) {
    this.transactionData = await this.listService.manageListValues(this.urlTransactionPath + idTransaction);
    this.transactionData.forEach(t => {
      if (t.channel == "Rivendita") {
        this.resale = t.resale;
      } else {
        this.resale = t.channel;
      }
    });
  }

  

  checkName(string) {
    var value;
    if (string == "Wallet recharge") {
      value = "Ricarica";
    } else {
      value = "Vendita Biglietto";
    }
    return value;
  }


  getCompanies() {
    this.listService.getListSelect(this.urlCompanyPath).subscribe(
      response => {
        this.dataCompany = response.results;
        this.logger.log("Lista canali", this.dataChannel, 300);
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }
}
