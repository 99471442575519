import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService, SubmitActionType } from '../../../services/list.service';
import { LoggingService } from '../../../services/logging.service';
import { AuthService } from '../../../services/auth.service';
import { UtilsService } from '../../../services/utils.service';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-users-manage',
  templateUrl: './users-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class UsersManageComponent implements OnInit {
  // testo in HTML per tipo pagina
  kindTitle;
  kindManage;
  // URL servizio da chiamare, se post o put dipenderà da dove arriviamo
  serviceToCallPath = '/user';
  urlSpecificUserPath = '/user/';
  urlTypesPath = '/user/types';
  urlRolesPath = '/user/roles';
  urlResalesPath = '/resale/resales';
  urlCompaniesPath = '/company/companies';

  varSeller;
  varReviewer;
  varObliterator;
  varInternalReseller;

  stringUser;
  // form html
  @ViewChild('formUsers')
  usersForm: NgForm;
  // oggetto contenente dati component
  userData: any;
  roleList: any[] = new Array();
  roleFilterList: any[] = new Array();
  typeList: any[] = new Array();
  resaleList: any[] = new Array();
  companyList: any[] = new Array();
  filterFilterRole: any[] = new Array();

  visibleRole;
  visibleResale;
  visibleCompanyName;
  checkboxVisible;
  disabled;
  editMobileView;

  idUser;

  errorMessage;

  typeUser;
  isCompany;
  isResales;
  isSol1;

  constructor(
    private route: ActivatedRoute,
    private utils: UtilsService,
    private authService: AuthService,
    private logger: LoggingService,
    public listService: ListService,
    private router: Router
  ) {
    this.typeUser = this.utils.getTypeUser();
    this.isCompany = this.utils.isComp(this.typeUser);
    this.isResales = this.utils.isRes(this.typeUser);
    this.isSol1 = this.utils.isSol1(this.typeUser);

    // Se creo nuovo utente
    if (route.snapshot.toString().indexOf('new') !== -1) {
      this.kindTitle = 'Nuovo';
      this.kindManage = 1;
    } else {
      // se modifico utente
      this.kindTitle = 'Modifica';
      this.kindManage = 0;
      if (localStorage.getItem('dataPassed') === '') {
        this.router.navigate(['/users/list']);
        return;
      }

      this.editMobileView = false;
      this.idUser = localStorage.getItem('dataPassed');
      this.disabled = true;
      this.loadSpecificUser();
    }
  }

  async ngOnInit() {
    this.listService.resetList();
    this.listService.backToList = true;
    this.listService.listRouting = '/users/list';
    
    await this.getRoles();
    await this.getResales();
    this.getTypology();
    this.getCompanies();

    this.visibleRole = false;
    this.visibleResale = false;
    this.visibleCompanyName = false;
    this.checkboxVisible = false;
  }

  // TRADUZIONE RUOLI E TIPI
  translateTypeUser(typeUser) {
    return this.utils.translateTypeUser(typeUser);
  }

  translateCodeRole(codeRole) {
    return this.utils.translateCodeRole(codeRole);
  }

  translateCodeRoleDiff(typeUser) {
    return this.utils.translateCodeRoleDiff(typeUser);
  }

  getSkillUser() {
    const arrayChannel: any[] = new Array();
    if (this.usersForm.value.checkSeller === true) {
      arrayChannel.push('1');
    }
    if (this.usersForm.value.checkReviewer === true) {
      arrayChannel.push('2');
    }
    if (this.usersForm.value.checkObliterator === true) {
      arrayChannel.push('3');
    }

    if (this.usersForm.value.checkInternalSeller === true) {
      arrayChannel.push('4');
    }

    this.stringUser = arrayChannel.toString();
  }

  // SERVIZI SELECT
  getTypology() {
    this.listService.getListSelect(this.urlTypesPath).subscribe({
      next: response => {
        if (this.isCompany === true) {
          for (let userType of response.results) {
            if (userType.typeUser === 'COMPANY') {
              this.typeList.push(userType);
            }
          }
        } else if (this.isResales === true) {
          for (let userType of response.results) {
            if (userType.typeUser === 'RESALE') {
              this.typeList.push(userType);
            }
          }
        } else {
          this.typeList = response.results;
        }

        this.logger.log('Lista Tipologie', this.typeList, 300);
      },
      error: error => {
        this.logger.log('Error', error, 200);
      }
    });
  }

  getCompanies() {
    this.listService.getListSelect(this.urlCompaniesPath).subscribe(
      response => {
        this.companyList = response.results;
        this.logger.log('Lista Aziende', this.typeList, 300);
      },
      error => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  async getRoles() {
    this.roleList = await this.listService.manageListValues(this.urlRolesPath, 'Lista ruoli');
  }

  async getResales() {
    this.resaleList = await this.listService.manageListValues(this.urlResalesPath, 'Lista rivendite');
  }

  onchangeMobileOperator(mobile) {
    this.checkboxVisible = false;
    if (mobile === 'COMPANY_OPERATOR') {
      this.checkboxVisible = true;
    }
  }

  onChangeType(type) {
    this.roleFilterList = [];
    this.visibleResale = false;
    this.checkboxVisible = false;
    this.visibleCompanyName = false;

    for (let r of this.roleList) {
      switch (type) {
        case 'COMPANY': {
          if (r.codeRole.indexOf(type) >= 0) {
            if (r.roleUser === 'OPERATOR') {
              this.roleFilterList.push(r);
            } else if (r.roleUser === 'ADMINISTRATOR') {
              this.roleFilterList.push(r);
            }
          }
          this.visibleCompanyName = true;
          this.visibleRole = true;
          break;
        }
        case 'RESALE': {
          if (r.codeRole.indexOf(type) >= 0) {
            if (r.roleUser === 'SELLER') {
              this.roleFilterList.push(r);
            }
          }
          this.visibleRole = true;
          this.visibleResale = true;
          break;
        }
        case 'SOLUZIONE1': {
          if (r.codeRole.indexOf(type) >= 0) {
            this.roleFilterList.push(r);
          }
          this.visibleRole = true;
          break;
        }
        default: {
          this.visibleRole = false;
          break;
        }
      }
    }
  }

  loadSpecificUser() {
    this.listService
      .getObject(this.urlSpecificUserPath + this.idUser)
      .subscribe(
        response => {
          //  Verificare outcome

          if (response.roleUser === 'COMPANY_OPERATOR') {
            this.editMobileView = true;
          }

          this.utils.checkResponseTypeUserManage(
            response.typeUser,
            response.roleUser
          );

          this.onChangeType(response.typeUser);

          this.userData = response;

          if (this.editMobileView === true) {
            //  varSeller;
            //    varReviewer;
            //   varObliterator;
            this.varSeller = this.utils.checkRoleMobileUser(
              this.userData.roleUserMobile,
              '1'
            );
            this.varReviewer = this.utils.checkRoleMobileUser(
              this.userData.roleUserMobile,
              '2'
            );
            this.varObliterator = this.utils.checkRoleMobileUser(
              this.userData.roleUserMobile,
              '3'
            );
            this.varInternalReseller = this.utils.checkRoleMobileUser(
              this.userData.roleUserMobile,
              '4'
            );
          }

          this.disabled = true;

          if (this.userData !== null) {
            this.visibleRole = true;
          } else {
            this.visibleRole = false;
          }

          this.logger.log('Response:', response, 300);
          localStorage.setItem('dataPassed', ''); // svuoto dato in localstorage
        },
        error => {
          this.logger.log('Error', error, 200);
        }
      );
  }

  onSubmit(): void {
    if (this.checkboxVisible || this.editMobileView) {
      this.getSkillUser();
    }

    this.userData = this.usersForm.value;
    this.userData.roleUserMobile = this.stringUser;

    this.listService.manageSubmitAction(
      this.kindManage ? SubmitActionType.CREATE : SubmitActionType.EDIT,
      this.userData,
      this.serviceToCallPath,
      '/users/list',
      'utente'
    );
  }

  back() {
    this.router.navigate(['/users/list']);
  }
}
