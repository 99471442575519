export enum LoadingLabels {
  Loading = 'Caricamento in corso...',
  DataLoading = 'Caricamento dati...',
  Operation = 'Operazione in corso...',
  Activation = 'Attivazione in corso...',
  Deactivation = 'Disattivazione in corso...',
  Edit = 'Modifica in corso...',
  Login = 'Login in corso...',
  Validation = 'Validazione in corso...',
  Download = 'Download in corso...',
  Upload = 'Upload in corso...',
  DocumentUpload = 'Upload documento in corso...',
  Delete = 'Eliminazione in corso...',
  Default = 'Caricamento in corso...',
  TicketPrint = 'Stampa biglietto in corso...',
  Save = 'Salvataggio in corso...',
  Share = 'Condivisione in corso...'
}

export enum LoadingSubtitles {
  BigDataLoading = 'Verifica di molti dati in corso, attendere prego'
}