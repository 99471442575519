
import { throwError as observableThrowError,  Observable, lastValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoggingService } from './logging.service';
import { Router } from '@angular/router';
import { ListService } from './list.service';
import { ICompanyInfo, IConfigValue, IS1Response } from '../interfaces/configuration.interface';
import { RoundTrip, SaleMode, TicketData, TicketType } from './../../app/enums/configuration.enum';
import { LoadingLabels } from '../enums/loading.enum';
import { LoadingService } from './loading.service';
import { SwitchConfig } from '../common/components/switch-button/switch-button.component';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import swal2 from 'sweetalert2';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const pathCompaniesInfo = '/company/companies/allinfo';
const urlChannelsPath = '/voucherEmitChannel/channels?enabledlots=true';
const urlPathReprint = "/ticket/sellprint/bytransaction?idTransaction=";
const pathPdfPrint = '/ticket/sellprint';

@Injectable()
export class UtilsService {
  logActualLevel = 'ALL';
  logLevels = new Array({ ALL: 100, DEBUG: 200, INFO: 300, PROD: 400 });
  urlCompany: string = '/company/companies';
  urlCategories: string = '/category/categories-grouped';
  configurations: ICompanyInfo;

  constructor(
    private http: HttpClient,
    private logger: LoggingService,
    private router: Router,
    private listService: ListService,
    private loadingService: LoadingService
  ) { }

  getMyIdCompany() {
    const result = JSON.parse(localStorage.getItem('idCompany'));
    return result;
  }

  /** Method that sets the configuration file in order to have different infos depending on the company */
  async getJSONConfiguration() {
    await this.mangeAdditionalInfo('configurations');
    await this.mangeAdditionalInfo('partners');
    this.logger.log(`Json data Company : ${this.configurations} JSON partners 3rd part: ${this.listService.partners3rd}`, null, 200);
  }

  /** Method that returns the configuration file section relative to the companyId */
  async getJSONConfigurationByCompanyId(idCompany: string) {
    await this.mangeAdditionalInfo('partners');
    return this.listService.partners3rd.find(x => x.idCompany === idCompany);
  }

  isLike(element, like) {
    return element === like;
  }

  getHours() {
    let hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(i);
    }
    return hours;
  }

  convertDateTimeFromDB(dateToConvert: Date) {
    let datePassed = new Date(dateToConvert);
    const day = datePassed.getDate();
    const monthIndex = datePassed.getMonth();
    const year = datePassed.getFullYear();
    const minutes = datePassed.getMinutes();
    const hours = datePassed.getHours();
    const seconds = datePassed.getSeconds();
    const myFormattedDate =
      day +
      '-' +
      (monthIndex + 1) +
      '-' +
      year +
      ' ' +
      hours +
      ':' +
      minutes +
      ':' +
      seconds;
    return myFormattedDate;
  }

  convertDateFromDB(dateToConvert: Date) {
    let datePassed = new Date(dateToConvert);
    const day = datePassed.getDate();
    const monthIndex = datePassed.getMonth();
    const year = datePassed.getFullYear();
    const minutes = datePassed.getMinutes();
    const hours = datePassed.getHours();
    const seconds = datePassed.getSeconds();
    const myFormattedDate =
      day +
      '-' +
      (monthIndex + 1) +
      '-' +
      year +
      ' ' +
      hours +
      ':' +
      minutes +
      ':' +
      seconds;
    document.getElementById('dateExample').innerHTML = myFormattedDate;
  }

  convertDateToISO(dateToConvert: Date) {
    //Problema: il datePicker quando selezioni un giorno torna la data completa con anche l'ora. Se però passi da un mese
    //all'altro l'orario che passa è la mezzanotte. Facendo il toISOString lo converte in UTC e quindi passava SEMPRE
    //il giorno prima. Questo causava problemi a cascata in tutte le pagine che usava il datePicker.
    //La fix qui sotto risolve il problema, bypassando il passaggio UTC che fa solitamente il metodo ISOString.
    //https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset
    var isoDateTime = new Date(dateToConvert.getTime() - (dateToConvert.getTimezoneOffset() * 60000));

    return isoDateTime.toISOString().slice(0, 10);
  }

  // DA TIMESTAMP A DATA FORMATTATA
  fromTimestampToDataFormatted(timeStamp) {
    let d: Date = new Date(timeStamp * 1000);
    let model = {
      date: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    };
    return model;
  }


  formatDate(date, local: boolean = false) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) { month = '0' + month };
    if (day.length < 2) { day = '0' + day };

    return  local ? [day, month, year].join('-') : [year, month, day].join('-');
  }

  stringTotalFRomDateWithOptions(date, type) {
    let valueDate = this.stringTotalFRomDate(date);
    if (type === 1) { // ORA MEZZANOTTE
      return valueDate + '00:00:00'
    }
    if (type === 2) { // ORA FINE GIORNATA
      return valueDate + '23:59:59'
    }
  }

  stringTotalFRomDate(date) {
    let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if ((d.getMonth() + 1) < 10  ) {
      month = '0' + month
    }

    if ((d.getDate()) < 10  ) {
      day = '0' + day
    }
    this.logger.log('DATA FORMATTATA', `${year}-${month}-${day}`, 200);
    return year + '-' + month + '-' + day;
  }


  disableReturnData(date) {
    const st = date;

    const dt = new Date(st)
    this.logger.log('dt', dt, 200);
    dt.setDate(dt.getDate() - 1)

    let model: any = {
      year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate()
    };
    
    this.logger.log('', model, 200);
    return model;
  }



  getSoldChannel() {
    const role = localStorage.getItem('roleUser')
    const typeUser = localStorage.getItem('typeUser')

    this.logger.log('', typeUser, 200);
    this.logger.log('', role, 200);

    let soldChannel;
    if (typeUser === 'RESALE') {
      soldChannel = '3'
    }
    if (typeUser === 'COMPANY') {
      soldChannel = '5'
    }


    return soldChannel;
  }


  today() {
    let today;
    const currentDate = new Date()
    const day = currentDate.getDate()
    const month = currentDate.getMonth() + 1
    const year = currentDate.getFullYear()
    let monthString = month + ''
    let dayString = day + ''
    if (monthString.length < 2) {
      monthString = '0' + monthString;
    }
    if (dayString.length < 2) {
      dayString = '0' + dayString;
    }

   return today = year + '-' + monthString + '-' + dayString;
  }


  yesterdayDate() {
    const d = new Date();
    d.setDate(d.getDate() - 1);

    // # => Thu Mar 31 2011 11:14:50 GMT+0200 (CEST)
    let model = {
      year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()
    }

    return model;

  }


  fromStringToDataFormatted(StringData) {

    let year;
    let month;
    let day;

    year = StringData.substring(0, 4)
    month = StringData.substring(5, 7)
    day = StringData.substring(8, 10)

    this.logger.log('DATO IN ARRIVO', StringData, 200);
    this.logger.log('', day, 200);

    if (day < 10 ) {
      this.logger.log('SONO MINORE DI 10', null, 200);
      day = day.substring(1, 2)
      this.logger.log('DAY', day, 200);
    }

    if (month < 10 ) {
      this.logger.log('SONO MINORE DI 10', null, 200);
      month = month.substring(1, 2)
      this.logger.log('MONTH', month, 200);
    }

    let model = {
      date: { year: year, month: month, day: day }
    };
    return model;

  }

  stringToServerFormat(StringData) {
    let year;
    let month;
    let day;
    let finalFormat;

    year = StringData.substring(6, 10)
    month = StringData.substring(3, 5)
    day = StringData.substring(0, 2)

    finalFormat = year + '-' + month + '-' + day

    return finalFormat;
  }

  stringToServerFormatTime(StringData, TimeData) {
    let year;
    let month;
    let day;
    let finalFormat;

    year = StringData.substring(6, 10)
    month = StringData.substring(3, 5)
    day = StringData.substring(0, 2)

    finalFormat = year + '-' + month + '-' + day + ' ' + this.formatNumber2Chars(TimeData) + ':00:00'

    return finalFormat;
  }

  addAndFormatTime(StringData, TimeData) {   
    return StringData + ' ' + this.formatNumber2Chars(TimeData) + ':00:00';
  }

  // DA DATA FORMATTATA A TIMESTAMP
  fromDataFormattedToTimestamp(strDate) {
    const datum = Date.parse(strDate);
    return datum / 1000;
  }

  // CONVERTE NUMERO IN NOME MESE
  convertMonthByNum(num) {
    const months = [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre'
    ];
    return months[num - 1];
  }

  // DATO TESTO TORNA TYPE E ROLE USER
  checkResponseTypeUserManage(responsetype, responseroleUser) {
    responsetype = responsetype.toUpperCase();

    if (responsetype === 'TRANSPORT COMPANY') { responsetype = 'COMPANY' };
    if (responsetype === 'SOLUZIONE 1') { responsetype = 'SOLUZIONE1' };
    if (responsetype === 'RESALE') { responsetype = 'RESALE' };

    if (responsetype === 'SOLUZIONE1') {
      if (responseroleUser === 'Administrator') {
        responseroleUser = 'SOLUZIONE1_ADMINISTRATOR';
      } else {
        responseroleUser = 'SOLUZIONE1_OPERATOR';
      }
    } else if (responsetype === 'RESALE') {
      if (responseroleUser === 'Administrator') {
        responseroleUser = 'RESALE_ADMINISTRATOR';
      } else {
        responseroleUser = 'RESALE_SELLER';
      }
    } else if (responsetype === 'COMPANY') {
      if (responseroleUser === 'Administrator') {
        responseroleUser = 'COMPANY_ADMINISTRATOR';
      } else if (responseroleUser === 'Manager') {
        responseroleUser = 'COMPANY_MANAGER';
      } else if (responseroleUser === 'Mobile') {
        responseroleUser = 'COMPANY_MOBILE';
      } else if (responseroleUser === 'Operator') {
        responseroleUser = 'COMPANY_OPERATOR';
      }
    }
  }

  // TRADUTTORE DEGLI STATUS DELLE ENTITA
  checkStatus(status) {
    if (status.toString() === '0') { return 'Non Attiva' };
    if (status.toString() === '1') { return 'Attiva' };
    if (status.toString() === '2') { return 'Guasta' };
  }

  // TRADUTTORE DEL CODE ROLE IN TESTO PARLANTE
  translateCodeRole(codeRole) {
    switch (codeRole) {
      case 'COMPANY_ADMINISTRATOR':
        return 'Amministratore';
      case 'COMPANY_MANAGER':
        return 'Azienda (Manager)';
      case 'COMPANY_MOBILE':
        return 'Azienda (Mobile)';
      case 'COMPANY_OPERATOR':
        return 'Operatore';
      case 'RESALE_ADMINISTRATOR':
        return 'Rivendita (Amm.)';
      case 'RESALE_SELLER':
        return 'Rivendita (Venditore)';
      case 'SOLUZIONE1_ADMINISTRATOR':
        return 'Soluzione1 (Amm.)';
      case 'SOLUZIONE1_OPERATOR':
        return 'Soluzione1 (Oper.)';
      case  'COMPANY_OBLITERATOR':
      return 'Azienda (Obliter)';
      default:
        return 'Altro';
    }
  }

  // TRADUZIONE ROLE
  translateCodeRoleDiff(codeRole) {
    switch (codeRole) {
      case 'SELLER':
        return 'Venditore';
      case 'ADMINISTRATOR':
        return 'Amministratore';
      case 'MANAGER':
        return 'Manager';
      case 'OPERATOR':
        return 'Operatore';
      case 'MOBILE':
        return 'Operatore Mobile';

      default:
        return 'Altro';
    }
  }

 getClass(obj) {
    if (typeof obj === 'undefined') {
      return 'undefined';
    }
    if (obj === null) {
      return 'null';
    }
    return Object.prototype.toString.call(obj)
      .match(/^\[object\s(.*)\]$/)[1];
  }


  // RESTITUISCE CANALE DI VENDITA
  getStringChannel(string) {
    let result = '';

    if ( this.getClass(string) === 'String') {

      if (string.length === 1) {
        switch (string) {
          case '1':
          return result = 'Api';
          case '2':
          return result = 'Operatore Mobile';
          case '3':
          return result = 'Rivendita';
          case '5':
          return result = 'Web';
        }
      }

      if (string.length >= 3) {
        const arrayInt: any[] = new Array();

        let firstMember = string.substring(0, 1)
        arrayInt.push(firstMember);
        let secondMember = string.substring(2, 3)
        arrayInt.push(secondMember);

        if (string.length >= 5) {
          let thirdMember = string.substring(4, 5)
          arrayInt.push(thirdMember);
        }
        if (string.length === 7) {
          let fourthMember = string.substring(6, 7)
          arrayInt.push(fourthMember);
        }

        let line = ''; let y = 0;
        for (let i of arrayInt) {
          if (y !== 0) { line = ' - ' };

          if (i === 3) {
            result = result  + line +  'Rivendita'
          }

          if (i === 2) {
            result = result + line + 'Operatore Mobile'
          }
          if (i === 5) {
            result = result + line + 'Web'
          }
          if (i === 1) {
            result = result + line + 'API'
          }
          y++;
        }

      }

    } else if ( this.getClass(string) === 'Array') {
      let array;

      for (let s of string) {
        // array.push(s)
      }
      result = 'array.toString()'
    }

    return result;
  }

  // TRADUTTORE DEL CODE ROLE IN TESTO PARLANTE
  translateTypeUser(typeUser: string): string {
    switch (typeUser) {
      case 'COMPANY': {
        return 'Azienda di trasporto';
      }
      case 'RESALE': {
        return 'Rivendita';
      }
      case 'SOLUZIONE1': {
        return 'Soluzione1';
      }
      case 'ECOMMERCE': {
        return 'Ecommerce';
      }
      case 'WEBUSER': {
        return 'Utente web';
      }
      case 'QUICKBUY': {
        return 'Quickbuy';
      }
      default:
        return 'Altro';
    }
  }

  // DATO TIPO RESTITUISCE COLORE ICONA
  checkColorTickets(string) {
    switch (string) {
      case 'OBLITERATED': {
        return 'btn btn-primary';
      }
      case 'OBLITERATED_PENDING':
      case 'ACTIVE_PENDING': {
        return 'btn btn-white';
      }
      case 'INVALID': {
        return 'btn btn-danger';
      }
      case 'ACTIVE': {
        return 'btn btn-success';
      }
      case 'SOLD': {
        return 'btn btn-secondary';
      }
      case 'REF_TKT': {
        return 'btn btn-warning';
      }
      default:
        return '';
    }
  }

  checkTextColorTickets(string) {
    switch (string) {
      case 'OBLITERATED': {
        return 'text-green';
      }
      case 'INVALID': {
        return 'text-danger';
      }
      case 'ACTIVE': {
        return 'text-success';
      }
      default:
        return '';
    }
  }

  // SERVIZIO CHE TORNA STATI PARLANTI DI CHIAVI STATUS, REASONSTATUS O TRANSACTIONSTATUS (IN FUTURO)
  checkTitleTickets(statusOrReasonStatus) {
    switch (statusOrReasonStatus) {
      case 'OBLITERATED': {
        return 'Obliterato';
      }
      case 'INVALID': {
        return 'Bloccato';
      }
      case 'ACTIVE': {
        return 'Attivo';
      }
      case 'STOLE': {
        return 'Rubato';
      }
      case 'LOST': {
        return 'Smarrito';
      }
      case 'TECH_ERROR': {
        return 'Errore tecnico stampa';
      }
      case 'REFUND':
      case 'REFUNDED': {
        return 'Rimborsato';
      }
      case 'REFUNDED_PAYPAL': {
        return 'Rimborso PayPal';
      }
      case 'REFUNDED_SERVICEPAY':
      case 'REFUNDED_SPAYG': {
        return 'Rimborso ServicePay';
      }
      case null: {
        return 'N/D'
      }
      default:
        return 'Altro';
    }
  }

  // DATO TIPO STATO TRANSAZIONE E RAGIONE DI INVALIDITA, TORNA UN TITOLO
  checkReasonTitleTickets(transStatus, reasonStatus) {
    if (transStatus === 'UNCONFIRMED') {
      return 'Annullato da operatore';
    } else {
      return this.checkTitleTickets(reasonStatus);
    }
  }

  checkStatusVoucher(data) {
    // CASO TICKET DI UNA TRANSAZIONE ASSOCIATO
    if (data.transactionId !== null) {
      return 'ASSOCIATO';
    } else if (data.ticketIds.length > 0 || data.status === 'VALIDATED') {
      return 'OBLITERATO';
    } else if (data.status === 'ACTIVE') {
      return 'ATTIVO';
    } else if (data.status === 'CANCELED') {
      return 'CANCELLATO';
    }
    return 'NON UTILIZZATO';
  }

  checkMultiselectStatusVoucher(voucherStatus) {
    switch (voucherStatus.value) {
      case 'NEW': {
        return 'NUOVO';
      }
      case 'ACTIVE': {
        return 'ATTIVO';
      }
      case 'VALIDATED': {
        return 'OBLITERATO';
      }
      case 'CANCELED': {
        return 'CANCELLATO';
      }
      default: {
        return 'ASSOCIATO';
      }
      
    }
  }

  // DATO TIPO RESTITUISCE TIPO PAGAMENTO TICKET
  checkTypePayment(string) {
    switch (string) {
      case 'CASH': {
        return 'Contanti';
      }
      case 'POS': {
        return 'POS'
      }
      case 'WALLET': {
        return 'Ricarica'
      }
      case 'POSTAL_PAYMENT': {
        return 'Pagamento postale';
      }
      case 'FLIGHT_COMPANY': {
        return 'Compagnia Aerea'
      }
      case 'TRANSFER': {
        return 'Bonifico';
      }
      case 'CHECK': {
        return 'Assegno bancario';
      }
      case 'CAEREA': {
        return 'Compagnia Aerea';
      }
      case 'MISC': {
        return 'Misto';
      }
      case 'SPAYG': {
        return 'ServicePay';
      }
      case 'SPAYG_POS': {
        return 'ServicePay (POS)'
      }
      default:
        return 'Altro';
    }
  }

  // DATO TIPO RESTITUISCE ICONA
  checkIconTickets(string) {
    switch (string) {
      case 'OBLITERATED': {
        return 'fas fa-ticket-alt';
      }
      case 'OBLITERATED_PENDING':
      case 'ACTIVE_PENDING': {
        return 'fas fa-hourglass-half';
      }
      case 'INVALID': {
        return 'fas fa-lock';
      }
      case 'ACTIVE': {
        return 'fas fa-ticket-alt';
      }
      case 'SOLD': {
        return 'fas fa-ticket-alt';
      }
      case 'REF_TKT': {
        return 'fas fa-undo';
      }
      default:
        return '';
    }
  }

  // DATO CANALE RESTITUISCE ICONA
  checkIconChannel(string) {
    switch (string) {
      case 'Operatore Mobile':
        return 'fas fa-mobile-alt';
      case 'Rivendita':
      case null:
        return 'fa fa-shopping-basket';
      case 'Desk':
        return 'fas fa-store';
      case 'Commerce':
      case 'Quick Buy':
        return 'fa fa-globe';
      case 'Api':
        return 'fa fa-code';
      case 'A Bordo':
        return 'fa fa-bus';
      default:
        return 'fa fa-info';
    }
  }

  checkIconPayment(string) {
    switch (string) {
      case 'POS':
        return 'fas fa-credit-card';
      case 'CASH':
        return 'fas fa-money-bill-alt';
      default:
        return 'fas fa-suitcase';
    }
  }

  // DATO CANALE RESTITUISCE TITOLO
  checkTitleChannel(string) {
    switch (string) {
      case 'Operatore Mobile':
        return 'Operatore Mobile';

      case 'Commerce':
        return 'Operatore Web';

        case 'Desk':
        return 'Operatore Desk';

      case null:
        return 'Rivendita';

      case 'Api':
        return 'Api';

      default:
        return 'Altro';
    }
  }

  checkPayment(string) {
    switch (string) {
    case 'POS':
      return 'Carta di Credito';
    case 'CASH':
      return 'Contante';
    default:
      return 'Altro';
    }
  }

  // DATO CANALE RESTITUISCE COLORE
  checkColorChannel(string) {

    switch (string) {
      case 'Operatore Mobile':
        return 'btn btn-warning btn-opMob';
      case 'Commerce':
      case 'Quick Buy':
        return 'btn btn-danger btn-eCom';
      case null:
        return 'btn btn-success lazur-bg';
      case 'Rivendita':
        return 'btn btn-primary btn-riv';
      case 'Desk':
        return 'btn btn-info btn-desk';
      case 'Api':
        return 'btn btn-info btn-api';
      case 'A Bordo':
          return 'btn btn-info btn-onTruck';
      default:
        return 'btn btn-default';
    }
   }

  // LOG GENRICO CON LIVELLO DI VISUALIZZAZIONE
  consoleLog(message: String, obj: any, level: number) {
    if (level >= this.logLevels[0][this.logActualLevel]) {
      this.logger.log(message, obj, 200);
    }
  }

  visibleCompany(companyVisible) {
    const type = localStorage.getItem('typeUser');
    if (type === 'SOLUZIONE1' || type === 'SUPERADMIN') {
      companyVisible = true;
    } else {
      companyVisible = false;
    }

    return companyVisible;
  }

  visibleCompanyAndSol(companyVisible) {
    const type = localStorage.getItem('typeUser');
    this.logger.log('TIPOLOGIA', type, 200);
    if (type === 'SOLUZIONE1' || type === 'SUPERADMIN' || type === 'AZIENDA DI TRASPORTO') {
      companyVisible = true;
    } else {
      companyVisible = false;
    }

    return companyVisible;
  }

  lineManager(typeUser) {
    let bool;
    if (typeUser === 'AZIENDA DI TRASPORTO' || typeUser === 'SUPER ADMIN' || typeUser === 'SOLUZIONE1' ) {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  }

  vehicleManager(typeUser) {
    let bool;
    if (typeUser === 'AZIENDA DI TRASPORTO' || typeUser === 'SUPER ADMIN' || typeUser === 'SOLUZIONE1' ) {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  }

  ticketManager(typeUser) {
    let bool;
    if (typeUser === 'AZIENDA DI TRASPORTO' || typeUser === 'SUPER ADMIN' || typeUser === 'SOLUZIONE1' ) {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  }

  newResaleManager(typeUser) {
    let bool;
    if (typeUser === 'SUPER ADMIN' || typeUser === 'SOLUZIONE1' ) {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  }

  resaleManager(typeUser) {
    let bool;
    if (typeUser === 'AZIENDA DI TRASPORTO' || typeUser === 'SUPER ADMIN' || typeUser === 'SOLUZIONE1' ) {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  }

  isResaleManager(typeUser) {
    let bool;
    if (typeUser === 'RIVENDITA') {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  }

  isOperatorMobile(codeRole) {
    let bool;
    if (codeRole === 'Operatore Mobile') {
      bool = true;
    } else {
      bool = false
    }
    return bool;
  }

  isOperator(codeRole) {
    let bool;
    if (codeRole === 'Operatore') {
      bool = true;
    } else {
      bool = false
    }
    return bool;
  }

  getSelectedDesk() {
    return localStorage.getItem('deskId')
  }

  viewUserManager(typeUser, codeRole) {
    let bool;
    if (
      (typeUser === 'AZIENDA DI TRASPORTO' || typeUser === 'SUPER ADMIN' || typeUser === 'RIVENDITA' || typeUser === 'SOLUZIONE1')
      && (codeRole === 'Amministratore' || codeRole === 'Manager' || codeRole === 'SUPERADMIN')
    ) {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  }


  viewAdministratorCompany(typeUser, codeRole) {
    let bool;
    if ((typeUser === 'AZIENDA DI TRASPORTO') && (codeRole === 'Amministratore' || codeRole === 'Manager')) {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  }

  isResaleAdministrator(typeUser, codeRole) {
    let bool;
    if ((typeUser === 'RIVENDITA') && (codeRole === 'Amministratore')) {
      bool = true;
    } else {
      bool = false;
    }

    return bool;

  }

  companiesManager(typeUser) {
    let bool;

    if (typeUser === 'SUPER ADMIN' || typeUser === 'SOLUZIONE1' || typeUser === 'AZIENDA DI TRASPORTO') {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  }

  companiesButtonsManager(typeUser) {
    this.logger.log('TEST', typeUser, 200);
    let bool;

    if (typeUser === 'SUPER ADMIN' || typeUser === 'SOLUZIONE1' || typeUser === 'SUPERADMIN') {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  }

  newSellManager(typeUser) {
    let bool;
    if (typeUser === 'SUPER ADMIN' || typeUser === 'RIVENDITA') {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  }

  viewAll(typeUser) {
    let bool;
    if (typeUser === 'AZIENDA DI TRASPORTO' || typeUser === 'SUPER ADMIN' || typeUser === 'RIVENDITA' || typeUser === 'SOLUZIONE1') {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  }


  getUserName() {
    let name;
    name = localStorage.getItem('name');
    return name;
  }

  getSurname() {
    let surname;
    surname = localStorage.getItem('surname');
    return surname;
  }

  getTypeUser() {
    let typeUser;

    if (localStorage.getItem('typeUser') === 'COMPANY') {
      typeUser = 'AZIENDA DI TRASPORTO';
    } else if (localStorage.getItem('typeUser') === 'RESALE') {
      typeUser = 'RIVENDITA';
    } else if (localStorage.getItem('typeUser') === 'SOLUZIONE1') {
      typeUser = 'SOLUZIONE1';
    } else if (localStorage.getItem('typeUser') === 'SUPERADMIN') {
      typeUser = 'SUPER ADMIN';
    }

    return typeUser;
  }

  isVisiblePdf() {
    let isVisible
    if (localStorage.getItem('typeUser') !== 'RESALE' ) {
      isVisible = true
    } else {
      isVisible = false
    }

    return isVisible
  }

  checkSadminOrSol1() {
    let bool;
    if (localStorage.getItem('typeUser') === 'SUPERADMIN' || localStorage.getItem('typeUser') === 'SOLUZIONE1' ) {
      bool = true
    } else {
      bool = false;
    }
    return bool;
  }

  getRoleUser() {
    let roleUser;
    if (localStorage.getItem('roleUser') === 'ADMINISTRATOR') {
      roleUser = 'Amministratore';
    } else if (localStorage.getItem('roleUser') === 'MANAGER') {
      roleUser = 'Manager';
    } else if (localStorage.getItem('roleUser') === 'OPERATOR') {
      roleUser = 'Operatore';
    } else if (localStorage.getItem('roleUser') === 'MOBILE') {
      roleUser = 'Operatore Mobile';
    } else if (localStorage.getItem('roleUser') === 'SELLER') {
      roleUser = 'Venditore';
    } else if (localStorage.getItem('roleUser') === 'SUPERADMIN') {
      roleUser = 'SUPERADMIN';
    }
    return roleUser;
  }

  getClassTypeUser() {
    if (localStorage.getItem('typeUser') === 'COMPANY') { return 'company' };
    if (localStorage.getItem('typeUser') === 'RESALE') { return 'resale' };
    if (localStorage.getItem('typeUser') === 'SOLUZIONE1') { return 'sol1' };
    if (localStorage.getItem('typeUser') === 'SUPERADMIN') { return 'sol1' };
  }

  isAdmin(roleUser) {
    let bool;
    if (roleUser === 'Amministratore') {
      bool = true;
    } else {
      bool = false;
    }
    return bool;
  }

  isMob(roleUser) {
    let bool;
    if (roleUser === 'Operatore Mobile') {
      bool = true;
    } else {
      bool = false;
    }
    return bool;
  }

  isSel(roleUser) {
    let bool;
    if (roleUser === 'Venditore') {
      bool = true;
    } else {
      bool = false;
    }
    return bool;
  }

  isMan(roleUser) {
    let bool;
    if (roleUser === 'Manager') {
      bool = true;
    } else {
      bool = false;
    }
    return bool;
  }

  isRes(typeUser) {
    let bool;
    if (typeUser === 'RIVENDITA') {
      bool = true;
    } else {
      bool = false;
    }
    return bool;
  }

  isOp() {
    let roleUser = this.getRoleUser();
    if (roleUser === 'Operatore') {
      return true;
    }
    return false;
  }

  isSol1(typeUser) {
    let bool;
    if (typeUser === 'SOLUZIONE1' || typeUser === 'SUPER ADMIN') {
      bool = true;
    } else {
      bool = false;
    }
    return bool;
  }

  isComp(typeUser) {
    let bool;
    if (typeUser === 'AZIENDA DI TRASPORTO') {
      bool = true;
    } else {
      bool = false;
    }
    return bool;
  }

  isDesk() {
    return localStorage.getItem('deskId') !== null;
  }

  checkCreationUser(roleUser) {
    let bool;
    if (
      roleUser === 'Amministratore' ||
      roleUser === 'Manager' ||
      roleUser === 'SUPERADMIN'
    ) {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  }

  getDeatilsToDuplicateTicketList(stringChannels) {

    let StringChannels = stringChannels
    let array: any[] = new Array();
    this.logger.log('stringa', StringChannels, 200);
    let subString1 = '1';
    let subString2 = '2';
    let subString3 = '3';
    let subString4 = '4';

    if (StringChannels.includes(subString1)) {
      array.push(1)
    }

    if (StringChannels.includes(subString2)) {
      array.push(2)
    }

    if (StringChannels.includes(subString3)) {
      array.push(3)
    }

    if (StringChannels.includes(subString4)) {
      array.push(4)
    }

    return array;
  }

  checkRoleMobileUser(mobileString, codeString) {
    let booleanMobile
    let test = mobileString;
    if ( test.indexOf(codeString) >= 0) {
      return booleanMobile = true;
    }
    return booleanMobile = false;

   }

  // TOREMOVE
  getReadableActivities(activities) {
    // per ogni elemento che c'è in activities creo la frase che poi pubblico in html
    let tempactivities: any[] = new Array();
    for (let activity of activities) {
      switch (activity.typeActivity) {
        case 'CHECKLIST_NEW':
          activity['msgtypeActivity'] = 'Cartella documenti creata';
          activity['msg'] =
            'Il fornitore ' +
            activity['businessName'] +
            ' ha creato la cartella dei documenti per il mese di ' +
            this.convertMonthByNum(activity['month']) +
            ' ' +
            activity['year'] +
            ' dell\'ordine ' +
            activity['descOrder'] +
            ' (rif. commessa ' +
            activity['descCommission'] +
            ' )';
          break;
        case 'CHECKLIST_SUBMIT':
          activity['msgtypeActivity'] =
            'Richiesta approvazione cartella documenti';
          activity['msg'] =
            'Il fornitore ' +
            activity['businessName'] +
            ' ha richiesto l\'approvazione dei documenti per il mese di ' +
            this.convertMonthByNum(activity['month']) +
            ' ' +
            activity['year'] +
            ' dell\'ordine ' +
            activity['descOrder'] +
            ' (rif. commessa ' +
            activity['descCommission'] +
            ' )';
          break;
        case 'CHECKLIST_REJECT':
          activity['msgtypeActivity'] = 'Cartella documenti non approvata';
          activity['msg'] =
            'L\'utente ' +
            activity['name'] +
            ' ' +
            activity['surname'] +
            ' ha respinto i documenti per il mese di ' +
            this.convertMonthByNum(activity['month']) +
            ' ' +
            activity['year'] +
            ' dell\'ordine ' +
            activity['descOrder'] +
            ' (rif. commessa ' +
            activity['descCommission'] +
            ' ), indicando come motivazione \'' +
            activity['notes'] +
            '\'';
          break;
        case 'CHECKLIST_APPROVE':
          activity['msgtypeActivity'] = 'Approvazione cartella';
          activity['msg'] =
            'L\'utente ' +
            activity['name'] +
            ' ' +
            activity['surname'] +
            ' ha approvato i documenti per il mese di ' +
            this.convertMonthByNum(activity['month']) +
            ' ' +
            activity['year'] +
            ' dell\'ordine ' +
            activity['descOrder'] +
            ' (rif. commessa ' +
            activity['descCommission'] +
            ' )';
          break;
        case 'CHECKLIST_APPROVE_CONDITIONALLY':
          activity['msgtypeActivity'] =
            'Approvazione cartella documenti con riserva';
          activity['msg'] =
            'L\'utente ' +
            activity['name'] +
            ' ' +
            activity['surname'] +
            ' ha approvato con riserva i documenti per il mese di ' +
            this.convertMonthByNum(activity['month']) +
            ' ' +
            activity['year'] +
            ' dell\'ordine ' +
            activity['descOrder'] +
            ' (rif. commessa ' +
            activity['descCommission'] +
            ' ), indicando come motivazione \'' +
            activity['notes'] +
            '\'';
          break;
        case 'DOCUMENT_DELETE':
          activity['msgtypeActivity'] = 'Cancellazione documento';
          activity['msg'] =
            'Il fornitore ' +
            activity['businessName'] +
            ' ha cancellato il documento ' +
            activity['fileName'] +
            ' (' +
            activity['descDocumentType'] +
            ') del fornitore ' +
            activity['businessName'] +
            '';
          break;
        case 'DOCUMENT_APPROVE':
          activity['msgtypeActivity'] = 'Approvazione documento';
          activity['msg'] =
            'L\'utente ' +
            activity['name'] +
            ' ' +
            activity['surname'] +
            ' ha approvato il documento ' +
            activity['fileName'] +
            ' (' +
            activity['descDocumentType'] +
            ') del fornitore ' +
            activity['businessName'] +
            '';
          break;
        case 'DOCUMENT_APPROVE_CONDITIONALLY':
          activity['msgtypeActivity'] =
            'Approvazione con riserva di un documento';
          activity['msg'] =
            'L\'utente ' +
            activity['name'] +
            ' ' +
            activity['surname'] +
            ' ha approvato con riserva il documento ' +
            activity['fileName'] +
            ' (' +
            activity['descDocumentType'] +
            ') del fornitore ' +
            activity['businessName'] +
            ', indicando come motivazione \'' +
            activity['notes'] +
            '\'';
          break;
        case 'DOCUMENT_UPLOAD':
          activity['msgtypeActivity'] = 'Caricamento documento';
          activity['msg'] =
            'Il fornitore ' +
            activity['businessName'] +
            ' ha caricato il documento ' +
            activity['fileName'] +
            ' (' +
            activity['descDocumentType'] +
            ')';
          break;
        case 'DOCUMENT_REJECT':
          activity['msgtypeActivity'] = 'Documento respinto';
          activity['msg'] =
            'L\'utente ' +
            activity['name'] +
            ' ' +
            activity['surname'] +
            ' ha respinto il documento ' +
            activity['fileName'] +
            ' (' +
            activity['descDocumentType'] +
            ') del fornitore ' +
            activity['businessName'] +
            ' indicando come motivazione \'' +
            activity['notes'] +
            '\'';
          break;
        default:
          activity['msgtypeActivity'] = 'Nuova attività';
          activity['msg'] = 'E\' stata effettuata un\'attività';
          break;
      }
      // activity['msg'] = 'testprova';
      tempactivities.push(activity);
    }
    return tempactivities;
  }

  getTime() {

    let string
    let min
    let hour
    let sec
    const m = new Date().getMinutes()
    const h = new Date().getHours()
    const s = new Date().getSeconds()

    if (m < 10) {
      const minute = '0' + String(m)
      min = minute
    } else {
      min = String(m)
    }

    if (h < 10) {
      const hou = '0' + String(h)
      hour = hou
    } else {
      hour = String(h)
    }

    if (s < 10) {
      const second = '0' + String(s)
      sec = second
    } else {
      sec = String(s)
    }

    return string = hour + ':' + min + ':' + sec
  }

  getQueryWithFilters(filters) {
    let queryStr = '';

    // imposto i parametri del filtro
    Object.keys(filters).forEach(function (key) {
      if (filters[key] && filters[key].toString() !== '' && filters[key].toString() !== 'all') {
        queryStr = queryStr + '&' + key + '=' + filters[key];
      }
    });
    this.logger.log('??', queryStr, 200);

    return queryStr;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    this.logger.log('worksheet', worksheet, 200);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  checkResponse( response ) {
    if ( response.results ) {
      return true;
    } else if ( !response.results && response.outcome.success === false ) {
      return false;
    }
  }

  filterUser( items: Array<any> , roleUser: string ): Array<any> {
    const result: Array<any> = new Array();
    for (let u of items) {
      if (u.roleUser === roleUser) {
        result.push(u);
      }
    }
    return result;
  }

  paymentTypes(): Observable<any>  {
    return this.http.get(environment.restBaseUrl + '/paymenttype/paymenttypes').pipe(
      map(
        (response: Response) => {
          let res: any = response;
          if (res.outcome.success === true) {
            return res.results.filter(pT => pT.description !== '-');
          } else {
            return res;
          }
        }
      ),
      catchError(
        (error: Response) => {
          return observableThrowError(error);
        }
      )
    );
  }

  formatDates(dataList: any[]): any[] {
    dataList.map(d => {
      d.dateFrom = d.dateFrom.replace('-', '/');
      d.dateTo = d.dateTo.replace('-', '/');
    });
    return dataList;
  }

  /** Method that checks whether a date returned from the BE is relative to today */
  isToday(dateMillis: number): boolean {
    return new Date().toDateString() === new Date(dateMillis).toDateString();
  }

  /** Checks whether an element must be available for a superadmin user, if a company is selected */
  checkFilesListAvailable(userAdmin: boolean, selectedCompany: string): boolean {
    return userAdmin
      ? selectedCompany && selectedCompany !== 'all'
      : true;
  }

  /** Method that creates a list of available years that must be dislayed in a select, getting the current year and the starting value */
  createAvailableYearsList(currentYear: number, startingYear: number): number[] {
    return [...Array(currentYear - (startingYear - 1))].map((_, i) => currentYear - i).reverse();
  }

  /** Method that formats a number into a string of 2 chars (applies 0 in front of numbers < 9) */
  formatNumber2Chars(numToConvert: number): string {
    return ('0' + numToConvert).slice(-2);
  }

  /** Method that manages the download of a csv file returned from a service */
  csvDownloadManagement(response: any): void {
    if (response) {
      const blob = new Blob([response], { type: 'text/csv' });
      const ieEDGE = navigator.userAgent.match(/Edge/g);
      const ie = navigator.userAgent.match(/.NET/g); // IE 11+
      const oldIE = navigator.userAgent.match(/MSIE/g);

      let fileName = ''
      const currentDate = new Date()
      const day = currentDate.getDate()
      const month = currentDate.getMonth() + 1
      const year = currentDate.getFullYear()
      fileName = day + '/' + month + '/' + year + '_statistics_passengers.csv';

      if (ie || oldIE || ieEDGE) {
        (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
      } else {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = fileName;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }

      swal2.fire('Il csv è stato scaricato', '', 'success');
    } else {
      // response.outcome.code + response.outcome.description;
      swal2.fire('Attenzione', 'Errore scaricamento csv', 'warning');
    }
  }

  /** Method that manages the opening of a pdf file returned from a service */
  pdfDownloadManagement(response: any, alertMsg?: string, path?: string) {
    if (response) {
      const blob = new Blob([response], { type: 'application/pdf' });
      const ieEDGE = navigator.userAgent.match(/Edge/g);
      const ie = navigator.userAgent.match(/.NET/g); //  IE 11+
      const oldIE = navigator.userAgent.match(/MSIE/g);

      if (ie || oldIE || ieEDGE) {
        (window.navigator as any).msSaveOrOpenBlob(blob, 'myPDFdoc.pdf');
      } else {
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }

      swal2.fire((alertMsg ? alertMsg : 'PDF correttamente scaricato'), '', 'success');

      if (path) {
        this.router.navigate([path]);
      }
    } else {
      swal2.fire('Attenzione', 'Errore scaricamento pdf', 'warning');
    }
  }

  /** Method that returns the list of companies that has to be displayed in select components */
  getCompanyList(): Promise<any[]> {
    return new Promise((res, rej) => {
      this.listService.getListSelect(this.urlCompany).subscribe({
        next: response => {
          const companiesList = response.results;
          this.logger.log('Lista aziende', companiesList, 300);
          res(companiesList);
        },
        error: error => {
          this.logger.log('Error', error, 200);
          rej([]);
        }
      });
    });
  }
  
  formatDateToLocal(date: string): string {
    return new Date(date).toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' });
  }

  checkDateValidity(date: string): boolean {
    return new Date() < new Date(date);
  }

  /** Method that returns the array of strings relative to the ticket channels connected to the element */
  getTicketChannel(dataChannel: any[], ticketChannel: number | string[]): string[] {
    var newArrayString = []
    
    dataChannel.forEach(chan => {
      if (typeof ticketChannel === 'number') {
        if (chan.idChannel.toString() === ticketChannel.toString()) {
          newArrayString.push(chan.name)
        }
      } else {
        ticketChannel.forEach(idChannelFounded => {
          if( chan.idChannel.toString() == idChannelFounded.toString()) {
            newArrayString.push(chan.name)
          }
        });
      }
    });

    return  newArrayString;
  }

  getCategories(idCompany?: string): Promise<any[]> {
    return new Promise((res, rej) => {
      this.listService.getListSelect(`${this.urlCategories}${idCompany ? `?idCompany=${idCompany}` : ''}`).subscribe({
        next: response => {
          const categoriesList = response.results;
          this.logger.log('Lista categorie', categoriesList, 300);
          res(categoriesList);
        },
        error: error => {
          this.logger.log('Error', error, 200);
          rej([]);
        }
      });
    });
  }

  apiErrorHandler(error: any): void {
    this.logger.log('Error', error, 200);
    swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.', 'error');
  }

  // ---------- PARAMS MANAGEMENT METHODS ---------- //

  /**
   * Method that returns the array of strings relative to the ticket data management.
   * 
   * When the company is not available (Soluzione1) the method returns an array with 'FULL' (full ticket management) 
   */
  manageTicketData(): IConfigValue[] {
    if (!(!!this.configurations?.company?.idCompany)) {
      return [{ id: TicketData.fullId, value: TicketData.fullValue }];
    } else {
      const ticketData = this.configurations.attributes.filter(t =>
        (t.name === TicketData.fullName && (/true/).test(t.value)) || (t.name === TicketData.minimalName && (/true/).test(t.value))
      );
      return ticketData.map(data => { return data.name === TicketData.fullName ? { id: TicketData.fullId, value: TicketData.fullValue } : { id: TicketData.minimalId, value: TicketData.minimalValue } });
    }
  }

  /**
   * Method that returns the array of strings relative to the sale modes connected to the element.
   * 
   * When the company is not available (Soluzione1) the method returns an array with '1' (standard) and '2' (customer) 
   */
  manageSaleModes(): IConfigValue[] {
    if (!(!!this.configurations?.company?.idCompany)) {
      return [{ id: SaleMode.standardId, value: SaleMode.standardValue }];
    } else {
      const saleModes = this.configurations.attributes.filter(s =>
        (s.name === SaleMode.standardName && (/true/).test(s.value)) || (s.name === SaleMode.customerName && (/true/).test(s.value))
      );
      return saleModes.map(sale => { return sale.name === SaleMode.standardName ? { id: SaleMode.standardId, value: SaleMode.standardValue } : { id: SaleMode.customerId, value: SaleMode.customerValue } });
    }
  }

  /**
   * Method that returns the array of strings relative to the ticket types connected to the element.
   * 
   * When the company is not available (Soluzione1) the method returns an array with 'T' and 'H' 
   */
  manageTicketTypes(withSubscription?: boolean): IConfigValue[] {
    if (!(!!this.configurations?.company?.idCompany)) {
      return [...[{id: TicketType.ticketId, value: TicketType.ticketValue}, {id: TicketType.hopId, value: TicketType.hopValue}], ...(withSubscription ? [{id: TicketType.subId, value: TicketType.subValue}] : [])];
    } else {
      const ticketTypes = this.configurations.attributes.filter(a =>
        (a.name === TicketType.ticketName && (/true/).test(a.value)) || (a.name === TicketType.hopName && (/true/).test(a.value)) || (a.name === TicketType.subName && (/true/).test(a.value))
      );
      return ticketTypes.map(type => { return type.name === TicketType.ticketName ? {id: TicketType.ticketId, value: TicketType.ticketValue} : {id: TicketType.hopId, value: TicketType.hopValue} });
    }
  }

  /**
   * Method that returns the array of strings relative to the round trips types connected to the element.
   * 
   * When the company is not available (Soluzione1) the method returns an array with 'ONE' (oneway) and 'ROUNDTRIP (two way)' 
   */
  manageRoundTrip(): IConfigValue[] {
    if (!(!!this.configurations?.company?.idCompany)) {
      return [{id: RoundTrip.oneId, value: RoundTrip.oneValue}, {id: RoundTrip.roundId, value: RoundTrip.roundValue}];
    } else {
      const ticketTypes = this.configurations.attributes.filter(a =>
        (a.name === RoundTrip.oneName && (/true/).test(a.value)) || (a.name === RoundTrip.roundName && (/true/).test(a.value) || (a.name === RoundTrip.openName && (/true/).test(a.value)))
      );
      return ticketTypes.map(type => {
        return type.name === RoundTrip.oneName
        ? {id: RoundTrip.oneId, value: RoundTrip.oneValue}
        : type.name === RoundTrip.roundName
        ? {id: RoundTrip.roundId, value: RoundTrip.roundValue}
        : {id: RoundTrip.openId, value: RoundTrip.openValue}
      });
    }
  }

  /**
   * Method that returns the boolean that indicates if the user can select the trip
   * 
   * When the company is not available (Soluzione1) the method returns false by default
   */
  manageShowTrips(): boolean {
    if (!(!!this.configurations?.company?.idCompany)) {
      return false;
    } else {
      return (/true/).test(this.configurations.attributes.find(a => a.name === 'VOUCHER_TRIP_SELECTABLE')?.value);
    }
  }

  /**
   * Method that returns the value of the subscription day
   * 
   * When the company is not available (Soluzione1) the method returns '1' by default
   */
  manageSubDayMonth(): string {
    if (!(!!this.configurations?.company?.idCompany)) {
      return '1';
    } else {
      return this.configurations.attributes.find(a => a.name === 'SUBSCRIPTION_DAY_MONTH')?.value;
    }
  }

  /**
   * Method that returns the value of the max purhcaseable tickets
   * 
   * When the company is not available (Soluzione1) the method returns '10' by default
   */
  manageMaxTicket(): string {
    if (!(!!this.configurations?.company?.idCompany)) {
      return '10'
    } else {
      return this.configurations.attributes.find(a => a.name === 'MAX_TICKET')?.value;
    }
  }

  /**
   * Method that returns the passengers amount minimum value
   * 
   * When the company is not available (Soluzione1) the method returns '1' by default
   */
  manageMinSubPassengers(): string {
    if (!(!!this.configurations?.company?.idCompany)) {
      return '1';
    } else {
      return this.configurations.attributes.find(a => a.name === 'SUBSCRIPTION_MIN_PASSENGERS')?.value;
    }
  }

  /**
   * Method that returns the information about whether the subscription menu has to be displayed
   * 
   * When the company is not available (Soluzione1) the method returns 'false' by default
   */
  manageSubscriptionMenu(): boolean {
    if (!(!!this.configurations?.company?.idCompany)) {
      return false;
    } else {
      return (/true/).test(this.configurations.attributes.find(a => a.name === 'SUBSCRIPTION_MENU')?.value);
    }
  }

  manageSubscriptionTypes(): IConfigValue[] {
    return [{ id: '1', value: 'Giornaliero' }, { id: '7', value: 'Settimanale' }, { id: '30', value: 'Mensile' }, { id: '365', value: 'Annuale' }];
  }

  getPdfFromTickets(tickets: any, alertMsg?: string, path?: string): Promise<void> {
    return new Promise((res, rej) => {
      this.listService.getDoc(pathPdfPrint, tickets).subscribe(
        response => {
          this.pdfDownloadManagement(response, alertMsg, path);
          res();
        }, error => {
          this.apiErrorHandler(error);
          rej();
        }
      );
    });
  }

  getPdfFromTransaction(idTransaction: number): Promise<void> {
    return new Promise((res, rej) => {
      this.listService.getReprintDoc(urlPathReprint + idTransaction).subscribe(
        response => {
          this.pdfDownloadManagement(response);
          res();
        }, error => {
          this.apiErrorHandler(error);
          rej();
        }
      );
    });
  }

  /** Method that deletes an entry from a list by a specific path and in case of error returns the occured issue reference */
  deleteElement(urlDeletePath: string): Promise<void | string> {

    this.loadingService.presentLoader(LoadingLabels.Delete);

    return new Promise((res, rej) => {
      this.listService.delete(urlDeletePath).subscribe(
        (response) => {
          if (response.outcome.success === true) {
            this.logger.log('Response value', response.value, 200);
            res();
          } else {
            this.logger.log('Response', response, 200);
            rej(response.outcome.code +  response.outcome.description);
          }
        }, (error) => {
          this.logger.log('Error', error, 200);
          swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico. Riprova più tardi', 'error');
        }
      );
    });
  }

  // ---------- PRIVATE METHODS ---------- //

  /** Method that manages the download of the additional info (configurations / partners list) when not available */
  private async mangeAdditionalInfo(infoRef: string): Promise<void> {
    switch (infoRef) {
      case 'configurations':
        if (!this.listService.fileConfigurations) {
          await lastValueFrom(this.getAllCompaniesInfo())
            .then((res) => {
              this.listService.fileConfigurations = res.results;
              this.configurations = this.listService.fileConfigurations.find(x => x.company.idCompany === +localStorage.getItem('idCompany'));
            })
            .catch(_ => {
              localStorage.clear();
              this.listService.clearConfigurations();
              this.router.navigate(['\logout\0']);
            });
        }
      break;
      case 'partners':
        if (!this.listService.partners3rd) {
          await lastValueFrom(this.getAllVoucherPartners())
            .then((res) => this.listService.partners3rd = res.results)
            .catch(_ => {
              localStorage.clear();
              this.listService.clearConfigurations();
              this.router.navigate(['\logout\0']);
            });
        }
      break;
    }
  }

  getSelectValues(filterByCompany: boolean = false, url: string, idCompany?: number, resultsRequired?: boolean): Promise<any[]> {
    return new Promise<any[]>((res, rej) => {
      this.listService.getListSelect(url + (filterByCompany ? `${url.includes('?') ? '&' : '?'}idCompany=${idCompany}` : '')).subscribe({
        next: (response) => {
          res(((resultsRequired === null || resultsRequired === undefined) || resultsRequired) ? response.results : response);
        },
        error: _ => {
          swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.Riprova più tardi', 'error');
          rej();
        }
      });
    });
  }

  configureFareValiditySwitchBtn(defaultValue?: string): SwitchConfig {
    return {
      title: 'Periodo validità',
      btn1: { label: 'Viaggio', value: 'T' },
      btn2: { label: 'Calendario', value: 'C' },
      ...(defaultValue) && { defaultValue: (defaultValue === 'T' ? 0 : 1) }
    }
  }

  /** Method that calls the API to get the info of all available companies (instead of using a local .json config file) */
  private getAllCompaniesInfo(): Observable<IS1Response> {
    return this.http.get<IS1Response>(environment.restBaseUrl + pathCompaniesInfo);
  }

  /** Method that gets the list of all the partner channels  */
  private getAllVoucherPartners(): Observable<IS1Response> {
    return this.listService.getListSelect(urlChannelsPath);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
