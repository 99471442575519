import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import swal2, { SweetAlertResult } from 'sweetalert2'
import { UtilsService } from '../../../services/utils.service';
import { LoadingLabels } from '../../../enums/loading.enum';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-wallet-recharge-list',
  templateUrl: './wallet-recharge-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class WalletRechargeListComponent implements OnInit {

 //FORM
 @ViewChild('formRecharge') rechargeForm: NgForm;
 //OGGETTO LISTA ELEMENTI
 dataList: any[] = new Array();
// dataList = [{  name: "BUS01",  brand: "Mercedes",  plate: "FF 000 EB",  status: 1}];
 dataFilter: any[] = new Array();
 //PATH CHIAMATE
 urlDeletePath = "";
 errorMessage: string = "";
 visibleFilter;
 defaultValue;
 stringLenght;
 visibleContentFilter;
 stringLenghtTables;
 companiesList: any[] = new Array();
 urlCompany = "/company/companies";
 typeUser;
 isSoluzione1;
UpOrDown;
classString;



 constructor(
  private logger: LoggingService,
  private router: Router,
  public listService: ListService,
  public utils: UtilsService,
  private loadingService: LoadingService
){
  this.listService.resetList();
  this.listService.configurationServiceCall("/wallet/recharge/list",false,true,1,5);
  this.listService.restURL = "/rechargetype/rechargetypes";
 }

 async ngOnInit() {
   this.UpOrDown = true;
   this.visibleContentFilter = false;
   this.stringLenght = "col-md-3"
   this.stringLenghtTables = "col-md-12";
   this.classString = "theme-config-box"
   this.listService.visibleFilter = false;
   this.companiesList = await this.utils.getCompanyList();
   this.typeUser = this.utils.getTypeUser();
   this.isSoluzione1 = this.utils.isSol1(this.typeUser);
   this.loadElements();
 }

 pageChange(page){
   this.listService.page = page; 
   this.loadElements();
 }

 goToRecharge(){
   
  this.router.navigate(['/wallet/recharge']);
}


 changeStatus(){
   if(this.UpOrDown == true){
     this.UpOrDown = false;
   }else{
     this.UpOrDown = true;
   }
 
 }


 visibleFilters(value){

   if (value  == "open" ){
     this.visibleContentFilter = true;
     this.stringLenghtTables = "col-md-9";
   }else{
     this.visibleContentFilter = false;
     this.stringLenghtTables = "col-md-12";
     this.filterReset();
   }





 }

 //FILTRI
 filterSearch(){
   this.dataFilter = this.rechargeForm.value;
   this.loadElements();
   this.listService.visibleFilter = true;
 }

 filterReset(){
   this.rechargeForm.value.name = ""
   this.rechargeForm.value.brand = ""
   this.rechargeForm.value.idCompany = ""
   this.listService.visibleFilter = false;
   this.loadElements();
    this.rechargeForm.reset();
   //this.loadElements();
 }
 getCompanyName(idCompany){
var stringCompanyName;
   for(let company of this.companiesList){
    
     if(company.idCompany == idCompany){
       stringCompanyName = company.legalBusinessName
      
       return stringCompanyName;
     }
    
   }
 }

 getButtonCircle(statusNumber){
   var buttonColor; 
   if(statusNumber == "1"){
  return   buttonColor =  "btn btn-primary btn-circle"
   }
   if(statusNumber == "2"){
     return   buttonColor =  "btn btn-danger btn-circle"
      }
 }

 getStatusDescription(statusNumber){
   var buttonColor; 
   if(statusNumber =="1"){
  return   buttonColor =  "Attivo"
   }
   if(statusNumber == "2"){
     return   buttonColor =  "Non Attivo"
      }
 }




 //SERVIZIO LISTA ELEMENTI
 loadElements(){
   this.listService.getListFilter(this.dataFilter).subscribe(
     (response) => {
       this.dataList = response.results;
       this.listService.totalRows = response.total;
      

       this.logger.log("Response:",response,300);
       this.errorMessage = "";
     },    
     (error) => { 
       this.logger.log("Error",error,200);
       this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
     }
   );
 }

 selectRow(dataElement){
   this.listService.selectedID = dataElement["id"];
   this.listService.backToList = true;
   localStorage.setItem("dataPassed",JSON.stringify(dataElement));
   this.router.navigate(['/wallet/recharge/edit']);
 }

 newVehicle(){
   this.listService.backToList = true;
   this.router.navigate(['/wallet/recharge/new']);
 }

 deleteVehicle(idVehicle) {
   var mySelf = this;

   swal2.fire({
     title: 'Eliminazione Taglio ricarica',
     text: "Sei sicuro di voler eliminare il taglio?",
     icon: 'question',
     showCancelButton: true,
     confirmButtonText: 'Sì, elimina',
     cancelButtonText: 'No',
     allowOutsideClick: false,
     allowEscapeKey: false,

   }).then(function (ev: SweetAlertResult) {
    if (ev.value) {
      mySelf.deleteElement(idVehicle);
    }
   }, function (dismiss) {
     if (dismiss === 'cancel') {
       swal2.close();
     }
   })

 }

 closeFilter(){
   if( this.classString == "theme-config-box show")
  { this.classString = "theme-config-box "}
  else{
   this.classString = "theme-config-box show"
  }
 }

 deleteElement(idElement) {
   this.urlDeletePath =  "/rechargetype/"+ idElement;

   this.loadingService.presentLoader(LoadingLabels.Delete);
   
   this.logger.log("ID DELETE:",idElement,200);
   
   this.listService.delete(this.urlDeletePath).subscribe(
     (response) => {
   
       if (response.outcome.success === true) {
             
         this.logger.log("Response value",response.value,200);
         swal2.fire("Il taglio è stato eliminato", "","success").then(() => {
           this.loadElements();
         });
       } else {   
         this.errorMessage =  response.outcome.code +  response.outcome.description
         swal2.fire("Attenzione", this.errorMessage,"warning");
       }
     },    
     (error) => {
       this.logger.log("Error",error,200);
       swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.Riprova più tardi","error");
     }
   );
   
 }

}
