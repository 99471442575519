import {NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { ScrollToBottomComponent } from './scroll-to-bottom/scroll-to-bottom.component';
import { CustomerInfoManagerComponent } from './customer-info-manager/customer-info-manager.component';
import { SwitchButtonComponent } from './switch-button/switch-button.component'
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        CheckboxGroupComponent, 
        ScrollToBottomComponent,
        CustomerInfoManagerComponent,
        SwitchButtonComponent
    ],
    imports: [BrowserModule, FormsModule],
    exports: [
        CheckboxGroupComponent,
        ScrollToBottomComponent,
        CustomerInfoManagerComponent,
        SwitchButtonComponent
    ]
})

export class ComponentsModule {}
