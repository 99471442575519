import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { MainViewComponent } from "./main-view.component";
import { FileUploadModule } from 'ng2-file-upload';

@NgModule({
    declarations : [ MainViewComponent ],
    imports      : [BrowserModule, FileUploadModule],
})

export class MainViewModule {}
