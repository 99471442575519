import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ListService, UploadResult, UploadType } from '../../../../services/list.service';
import { LoggingService } from '../../../../services/logging.service';
import { UtilsService } from '../../../../services/utils.service';
import swal2 from 'sweetalert2'

@Component({
  selector: 'app-uploads-archive',
  templateUrl: './uploads-archive.component.html',
  styleUrls: ['../../../../app.component.css']
})
export class UploadsArchiveComponent implements OnInit, OnChanges {
  @Input() filesType: string;
  @Input() downloadable = true;
  @Input() companyId: string;
  @Input() reload: boolean;

  UploadType = UploadType;

  dataList: any[] = [];
  dataFilter: any[] = [];
  errorMessage = '';
  userAdmin = false;

  constructor(
    private listService: ListService,
    private logger: LoggingService,
    protected utils: UtilsService
  ) {
    this.userAdmin = localStorage.getItem('typeUser') === 'SUPERADMIN';
    this.listService.resetList();
  }

  ngOnInit(): void {
    this.loadElements();
  }

  ngOnChanges(): void {
    this.loadElements();
  }

  pageChange(page: number) {
    this.listService.page = page;
    this.loadElements();
  }

  /** Method that manages the download of a specific type of table entry */
  downloadArchivedFile(file: any): void {
    let downloadUrl = '';
    switch (this.filesType) {
      case UploadType.APK:
        downloadUrl = `/obliterator/apk?softwareVersion=${file.version}`;
        break;
      case UploadType.TIMETABLES:
        downloadUrl = `/stop/pdf/${this.companyId ? this.companyId : localStorage.getItem('idCompany')}/stop/${file.fileName.split('.pdf')[0]}`;
        break;
      default:
        downloadUrl = `/file/download/${this.companyId ? this.companyId : localStorage.getItem('idCompany')}/${file.fileName}`;
        break;
    }

    this.listService.downloadObject(downloadUrl, this.filesType).subscribe(
      _ => {
      }, (error) => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  /** Method that applies a style to the circle refferring to the upload state */
  getButtonCircle(uploadResult: string): string {
    let buttonColor: string;
    switch (uploadResult) {
      case UploadResult.OK:
        buttonColor = 'btn btn-primary btn-circle';
        break;
      case UploadResult.ERROR:
        buttonColor = 'btn btn-danger btn-circle';
        break;
      default:
        buttonColor = 'btn btn-circle';
        break;
    }
    return buttonColor;
  }

  /** Method that displays an alert showing the error that occured on GTFS file upload */
  showUploadInfo(data: any): void {
    if (this.userAdmin && data.esito === UploadResult.ERROR) {
      swal2.fire('Errore importazione', data.message, 'error');
    }
  }

  // ---------- PRIVATE METHODS ---------- //

  /** Method that loads the list of elements that have to be displayed in the table */
  private loadElements(): void {
    const filesUrl = this.listService.generateFilesUrl(this.filesType);

    this.listService.configurationServiceCall(filesUrl, false, true, this.listService.page, 5);
    this.listService.restURL = filesUrl;

    this.dataFilter['orderBy'] = 'lastUpdate';
    this.dataFilter['direction'] = 'DESC';

    if (this.companyId) {
      this.dataFilter['idCompany'] = this.companyId;
    }

    this.dataFilter['fileType'] = this.listService.generateDownloadFileType(this.filesType);

    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.logger.log(`Lista file ${this.filesType}`, this.dataList, 300);
      }, (error) => {
        this.logger.log('Error', error, 200);
      }
    )
  }

}
