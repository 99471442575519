import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import { UtilsService } from '../../../services/utils.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { LoadingService } from '../../../services/loading.service';
import { LoadingLabels, LoadingSubtitles } from '../../../enums/loading.enum';
defineLocale('it', itLocale);

@Component({
  selector: 'app-statistics-earnings',
  templateUrl: './statistics-earnings.component.html',
  styleUrls: ['../../../app.component.css']
})
export class StatisticsEarningsComponent implements OnInit {

  @ViewChild('formStatsEarn') statsEarnForm: NgForm;

  // OGGETTO LISTA ELEMENTI & FILTRI
  // dataList: any[] = new Array();
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  dataCompany: any[] = new Array();
  daterangepickerModel: Date[];
  dataResale: any[] = new Array();
  dataDesk: any[] = new Array();
  dataEcommerce: any[] = new Array();
  dataRoute: any[] = new Array();
  dataChannel: any[] = new Array();
  dataCategories: any[] = new Array();
  dataPaymentTypes: any[] = new Array();

  channelArray: any[] = new Array();
  stringFirstDay;
  stringLastDay;
  modalRef: BsModalRef;


  totalAmount = 0;
  totalCash = 0;
  totalCoupon = 0;
  totalPos = 0;
  totalOthers = 0;
  totalSpayg = 0;
  avgPrice = 0;
  totalCommerce = 0;
  totalQuickBuy = 0;
  totalRivenditaInterna = 0;
  totalRivendita = 0;
  totalABordo = 0;
  totalOperatoreMobile = 0;
  totalNonVersato = 0;
  totalApi = 0;

  descriptionAmount;
  checkList: any[] = new Array()

  monthName;

  // PATH CHIAMATE
  urlDeletePath = '';
  urlChannelPath = '/ticket/channel/channels';
  urlDeskPath = '/desk/desks';
  urlResalePath = '/resale/resales'
  urlEcommercePath = '/ecommerce/ecommerces'
  urlCompanyPath = '/company/companies';
  urlRoutePath = '/route/routes';
  urlCategories = '/category/categories-grouped?idCompany=';

  // view varie SELECT
  viewEc = false;
  viewDk = false;
  viewRiv = false;
  withUserPaymentsFlag = false;
  showDetailsFlag = false;

  errorMessage = '';

  // DATEPICKER FILTRI
  public dateSellTo = {
    dateFormat: 'dd-mm-yyyy',
    inline: false,
    editableDateField: false
  };

  public dateSellFrom = {
    dateFormat: 'dd-mm-yyyy',
    inline: false,
    editableDateField: false
  };

  dateStartModel;
  dateLastModel;
  typeUser;
  isSoluzione1;
  totalData;
  colorTheme = 'theme-orange';
  bsConfig: Partial<BsDatepickerConfig>;

  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();

  locale = 'it';
  locales = listLocales();



  constructor(
    private modalService: BsModalService,
    public utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private localeService: BsLocaleService,
    private loadingService: LoadingService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall('/statistics/earnings', false, true, 1, 12);
    this.listService.restURL = '/stats/earnings';
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.maxDate.setDate(lastDay.getDate());
    this.bsRangeValue = [firstDay, this.maxDate];
    this.localeService.use(this.locale);
  }

  async ngOnInit() {
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      {dateInputFormat: 'DD-MM-YYYY'},
      {rangeInputFormat: 'DD-MM-YYYY'}
    );
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);


     this.stringFirstDay = this.utils.formatDate(firstDay)
     this.stringLastDay = this.utils.formatDate(lastDay)

    this.monthName = this.getMonthName()
    this.totalCommerce = 0.0
    this.totalQuickBuy = 0.0

    // CHIAMATE PER SELECT
    await this.getChannels();
    await this.getDesks();
    await this.getResales();
    await this.getEcommerces();
    await this.getRoutes();
    await this.getCategories();
    this.getPaymentTypes();

    // CHIAMATA LISTA ELEMENTI
    this.loadElements(true);

    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    if (this.isSoluzione1) {
      this.getCompanies()
    }
  }

  getMonthName() {
    const monthNames = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
    'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
  ];

  const d = new Date();
  return monthNames[d.getMonth()];
  }

  pageChange(page) {
    this.listService.page = page;
    this.loadElements(false);
  }

  // FILTRI
  filterSearch() {
    this.dataFilter = this.statsEarnForm.value;
    this.dataFilter['bsDaterangepicker'] = 'all'

    this.descriptionAmount = 'Importo totale'

    this.stringFirstDay = this.utils.stringTotalFRomDate(this.bsRangeValue[0])
    this.stringLastDay = this.utils.stringTotalFRomDate(this.bsRangeValue[1])

    this.dataFilter['dateSellFrom'] = this.stringFirstDay
    this.dataFilter['dateSellTo'] =   this.stringLastDay

    const string = this.channelArray.toString();
    this.dataFilter['idTicketChannel'] = string

    this.listService.visibleFilter = true;
    this.dataFilter['withUserPayments'] = this.withUserPaymentsFlag;

    this.loadElements(false);
  }

  filterReset() {
    this.descriptionAmount = 'Importo Totale di ' + this.monthName


    this.statsEarnForm.value.idTicketChannel = ''
    this.statsEarnForm.value.idCompany = ''
    this.statsEarnForm.value.paymentType = ''
    this.statsEarnForm.value.idFareCategoryGroup = ''
    this.statsEarnForm.reset();


    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.maxDate.setDate(lastDay.getDate());
    this.bsRangeValue = [firstDay, this.maxDate];

    this.stringFirstDay = this.utils.stringTotalFRomDate(this.bsRangeValue[0])
    this.stringLastDay = this.utils.stringTotalFRomDate(this.bsRangeValue[1])



    this.listService.visibleFilter = false;
    this.loadElements(true);
  }


  getPaymentType(string) {
    return this.utils.checkTypePayment(string);
  }

  getPaymentTypes() {
    this.utils.paymentTypes().subscribe({
      next: (response) => {
        this.dataPaymentTypes = response;
      },
      error: (error) => {
        this.logger.log('Error', error, 200);
      }
    });
  }


  // SERVIZI SELECT
  async getChannels() {
    this.dataChannel = await this.listService.manageListValues(this.urlChannelPath, 'Lista canali');
  }

  getCompanies() {
    this.listService.getListSelect(this.urlCompanyPath).subscribe((response) => {
      this.dataCompany = response.results;
      this.logger.log('Lista canali', this.dataChannel, 300);
    },
      (error) => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  async getResales() {
    this.dataResale = await this.listService.manageListValues(this.urlResalePath, 'Lista rivendite');
  }

  async getDesks() {
    this.dataDesk = await this.listService.manageListValues(this.urlDeskPath, 'Lista desk');
  }

  async getEcommerces() {
    this.dataEcommerce = await this.listService.manageListValues(this.urlEcommercePath, 'Lista ecomm');
  }

  async getRoutes() {
    this.dataRoute = await this.listService.manageListValues(this.urlRoutePath);
  }

  async getCategories() {
    this.dataCategories = await this.listService.manageListValues(`${this.urlCategories}${this.isSoluzione1 ? this.statsEarnForm.value.idCompany : ''}`, 'Lista categorie');
  }

  // SERVIZIO LISTA ELEMENTI
  loadElements(isFirstCall) {
    this.loadingService.presentLoader(LoadingLabels.DataLoading, LoadingSubtitles.BigDataLoading);

    if (isFirstCall === true ) {
      this.dataFilter['dateSellFrom'] = this.stringFirstDay;
      this.dataFilter['dateSellTo'] = this.stringLastDay;
    }

    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.totalData = response.data.totalData;
        this.dataList = response.data.items;
        this.listService.totalRows = response.data.total;
        this.totalAmount = this.totalData.total?.totalAmount;
        this.totalCash = this.totalData.cash?.totalAmount;
        this.totalCoupon = this.totalData.coupon?.totalAmount;
        this.totalOthers = this.totalData.other?.totalAmount;
        this.totalSpayg = this.totalData.spayg?.totalAmount;
        this.totalPos = this.totalData.pos?.totalAmount;
        this.totalCommerce = this.totalData.web?.totalAmount;
        this.totalQuickBuy = this.totalData.quickBuy?.totalAmount;
        this.totalApi = this.totalData.api?.totalAmount;
        this.totalOperatoreMobile = this.totalData.mobileOperator?.totalAmount;
        this.totalNonVersato = this.totalData.notPaid?.totalAmount;
        this.totalRivendita = this.totalData.reseller?.totalAmount;
        this.totalRivenditaInterna = this.totalData.desk?.totalAmount;
        this.totalABordo = this.totalData.onBoard?.totalAmount;
        this.avgPrice = this.totalData.avgPrice?.totalAmount;
        if (isFirstCall === true) {
          this.descriptionAmount = 'Importo totale di ' + this.monthName
        }
        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
      },
      (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    ).add(() => {
      this.loadingService.hideLoader();
    });
  }

  addOrRemoveInData(data) {

    let exist;
    exist = this.checkList.indexOf(data) > -1

    if (exist !== false) {
      this.removeData(data);
    } else {
      this.putInChecked(data)
    }


  }

  removeData(data) {
    this.checkList.splice(this.checkList.indexOf(data), 1);
  }

  putInChecked(data) {
    this.checkList.push(data);
  }

  getidTicket(template: TemplateRef<any> ) {
    this.openModal(template)
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide()
  }

  checkName(string) {
    let value: string;
    if ( string === 'Wallet recharge') {
    value = 'Ricarica'
    } else {
      value = 'Vendita Biglietto'
    }
    return value;
  }

  checkboxAction(e, action: number) {
    if (e.target.checked) {
      action === 1 ? this.showDetailsFlag = true : this.withUserPaymentsFlag = true;
    }  else {
      action === 1 ? this.showDetailsFlag = false : this.withUserPaymentsFlag = false
    }
  }

  viewEcommerce(e) {
    if (e.target.checked) {
      this.viewEc = true;
    }  else {
      this.viewEc = false;
    }
  }

  viewReseller(e) {
    if (e.target.checked) {
      this.viewRiv = true;
    }  else {
      this.viewRiv = false;
    }
  }

  viewDesk(e) {
    if (e.target.checked) {
      this.viewDk = true;
    }  else {
      this.viewDk = false;
    }
  }

  setIdChannel(e, kind) {
    let i = 0;
    for (let val of this.channelArray) {
      if ( val.toString() === kind.toString()) {
        this.channelArray.splice(i, 1);
        this.switchSubChannels(e, kind);
        return;
      }
      i++;
    }
    this.channelArray.push(kind.toString());
    this.switchSubChannels(e, kind);
  }


  isCheckChannel(idChannel) {
    for (let i of this.channelArray) {
      if ( i.toString() === idChannel.toString()) { return true; }
    }
    return false;
  }

  switchSubChannels(e, kind) {
    switch (kind) {
      case 4: // eCommerce
        this.viewEcommerce(e);
        break;
      case 3: // Reseller
        this.viewReseller(e);
        break;
      case 5: // Desk
        this.viewDesk(e);
        break;
    }
  }

}


