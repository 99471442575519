import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal2 from "sweetalert2";
import { ListService, SubmitActionType } from '../../../services/list.service';
import { LoggingService } from '../../../services/logging.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-sales-channels-manage',
  templateUrl: './sales-channels-manage.component.html',
  styleUrls: ['./sales-channels-manage.component.css']
})
export class SalesChannelsManageComponent implements OnInit {

  @ViewChild('channelForm') formChannel: NgForm;

  kindTitle: string;
  kindManage: number;
  dataChannel: TicketChannel;
  id: number;
  idCompany: number;
  errorMessage:string;
  domainsList: any[] = new Array();

  domainsPath = "/multiselect/ticketChannel/domain";
  serviceToCallPath = "/ticket-channel";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private listService: ListService,
    private logger: LoggingService
  ) {
    //Se creo nuovo canale vendita
    if (this.route.snapshot.toString().indexOf("new") != -1) {
      this.kindTitle = "Nuovo";
      this.kindManage = 1;
    } else {
      //se modifico canale vendita
      this.kindTitle = "Modifica";
      this.kindManage = 0;

      this.id = this.listService.selectedID;
      this.idCompany = 1;

      this.getChannelInfo();
    }
  }

  ngOnInit(): void {
    this.listService.resetList();
    this.listService.backToList = true;
    this.listService.listRouting = "/salesChannels";
    this.getDomains();
  }

  async getChannelInfo(): Promise<void> {
    const res = await this.listService.manageListValues(`${this.serviceToCallPath}/${this.id}`, null, false);
    this.dataChannel = res.data;
  }

  async getDomains(): Promise<void> {
    this.domainsList = await this.listService.manageListValues(this.domainsPath);
  }

  onSubmit(): void {
    this.dataChannel = this.formChannel.value;

    if (!this.kindManage) {
      this.dataChannel.idChannel = this.id;
    }

    this.listService.manageSubmitAction(
      this.kindManage ? SubmitActionType.CREATE : SubmitActionType.EDIT,
      this.dataChannel,
      this.serviceToCallPath,
      '/salesChannels',
      'canale di vendita'
    );
  }

  back() {
    this.router.navigate(["/salesChannels"]);
  }

}

export interface TicketChannel {
  domain: string;
  idChannel: number;
  name: string;
}