import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService, SubmitActionType } from "../../../services/list.service";
import { LoggingService } from "../../../services/logging.service";
import { AuthService } from "../../../services/auth.service";
import { UtilsService } from '../../../services/utils.service';

@Component({
    selector: 'app-tickets-typology-manage',
    templateUrl: './tickets-typology-manage.component.html',
    styleUrls: ['../../../app.component.css']
})
export class TicketsTypologyManageComponent implements OnInit {
    //form html
    @ViewChild('ticketForm') ticketForm: NgForm;

    //testo in HTML per tipo pagina
    kindTitle; kindManage;
    //URL servizio da chiamare, se post o put dipenderà da dove arriviamo
    serviceToCallPath = "/ticket/type";
    //oggetto contenente dati component
    ticketTypologyData: any = new Object;

    errorMessage;
    pathToCallCompaniesPath = "/company/companies"
    companiesList: any[] = new Array();
    typeUser;
    isSol1;

    tempValidity;

    constructor(private utils: UtilsService, private route: ActivatedRoute, private authService: AuthService, private logger: LoggingService, public listService: ListService, private router: Router) {
        //Se creo nuovo veicolo

        if (route.snapshot.toString().indexOf("new") != -1) {
            this.kindTitle = "Nuova";
            this.kindManage = 1;
        } else { //se modifico veicolo
            this.kindTitle = "Modifica";
            this.kindManage = 0;
            if (localStorage.getItem("dataPassed") == "") { this.router.navigate(['/tickets/typology']); return; }
            this.ticketTypologyData = JSON.parse(localStorage.getItem("dataPassed"));
            localStorage.setItem("dataPassed", ""); //svuoto dato in localstorage
        }
    }

    @ViewChild('f') signupForm: NgForm;

    ngOnInit() {
        this.listService.resetList();
        this.listService.backToList = true;
        this.listService.listRouting = "/tickets/typology";
        this.getCompanies();
        this.typeUser = this.utils.getTypeUser();
        this.isSol1 = this.utils.isSol1(this.typeUser);

    }

    getCompanies() {
        this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe((response) => {
            this.companiesList = response.results;
            this.logger.log("Lista aziende", this.companiesList, 300);
        },
            (error) => {
                this.logger.log("Error", error, 200);
            }
        );
    }

    onSubmit(): void {
        this.ticketTypologyData = this.ticketForm.value;
        if (!this.kindManage) {
            this.ticketTypologyData.name = this.ticketForm.value.name;
        }

        this.listService.manageSubmitAction(
            this.kindManage ? SubmitActionType.CREATE : SubmitActionType.EDIT,
            this.ticketTypologyData,
            this.serviceToCallPath,
            '/tickets/typology',
            'tipolgia'
        );
    }

    back() {
        this.router.navigate(['/tickets/typology']);
    }

}

