<div class="row" style="padding-top: 20px"></div>
<div class="col-md-12">
    <div class="ibox float-e-margins ">
        <div class="ibox-title">
            <h2 class="col-xs-11">
                <strong>Dettagli Azienda di trasporto</strong>
            </h2>
            <button class="col-xs-1" style="float: right;margin-right: 8px;" type="button" class="btn btn-success btn-outline " (click)="selectRow()">
                <i class="fa fa-pencil"></i>
            </button>
        </div>
        <div class="ibox-content">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div style="cursor: pointer" (click)="goToVehicles()" class="widget style1 red-bg">
                        <div class="row">
                            <div class="col-xs-4">
                                <i class="fa fa-bus fa-5x"></i>
                            </div>
                            <div class="col-xs-8 text-right">
                                <span> Veicoli </span>
                                <h2 class="font-bold">{{companyDetails?.numVehicle}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div  style="cursor: pointer" (click)="goToPrinters()" class="widget style1 navy-bg">
                        <div class="row">
                            <div class="col-xs-4">
                                <i class="fa fa-print fa-5x"></i>
                            </div>
                            <div class="col-xs-8 text-right">
                                <span> Stampanti di proprietà </span>
                                <h2 class="font-bold">{{companyDetails?.numPrinter}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div  style="cursor: pointer" (click)="goToResale()" class="widget style1 lazur-bg">
                        <div class="row">
                            <div class="col-xs-4">
                                <i class="fa fa-shopping-basket fa-5x"></i>
                            </div>
                            <div class="col-xs-8 text-right">
                                <span> Rivendite associate </span>
                                <h2 class="font-bold">{{companyDetails?.numResale}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div   style="cursor: pointer" (click)="goToObliterators()" class="widget style1 yellow-bg">
                        <div class="row">
                            <div class="col-xs-4">
                                <i class="fa fa-check-square-o fa-5x"></i>
                            </div>
                            <div class="col-xs-8 text-right">
                                <span> Obliteratrici </span>
                                <h2 class="font-bold">{{companyDetails?.numObliterator}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 30px" class="row">
                <div style="float: right" class="col-md-3">
                    <h2>Incasso mensile</h2>
                    <h1 class="no-margins">{{companyDetails?.earningsMonthAmount | number:'1.2-2'}} €</h1>
                    <span *ngIf="companyDetails?.earningsMonthSupplementAmount != 0">&nbsp;+ (suppl. {{companyDetails?.earningsMonthSupplementAmount | number : '1.2-2'}} €)</span>
            </div>
        </div>
    </div>
</div>
</div>