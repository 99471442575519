import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilsService } from "../../../services/utils.service";
import { ListService, SubmitActionType } from "../../../services/list.service";
import { LoggingService } from "../../../services/logging.service";
import { NgForm } from "@angular/forms";

@Component({
  selector: 'app-lines-manage',
  templateUrl: './lines-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class LinesManageComponent implements OnInit {

  @ViewChild("lineForm") formLine: NgForm;

  kindTitle: string;
  kindManage: number;
  idCompany: number;
  routeId: string;
  typeUser: string;
  isSoluzione1: boolean = false;
  companiesList: any[] = new Array();
  linesList: any[] = new Array();
  dataLine: LineModel;
  

  urlCompanies = "/company/companies";
  urlLines = "/route/routes";
  serviceToCallPath = "/route/routes/company";

  constructor(
    private route: ActivatedRoute,
    private utils: UtilsService,
    private listService: ListService,
    private logger: LoggingService,
    private router: Router
  ) {
    if (this.route.snapshot.toString().indexOf("new") !== -1) {
      this.kindTitle = "Nuova";
      this.kindManage = 1;
    } else {
      this.kindTitle = "Modifica";
      this.kindManage = 0;
    }
    this.getCompanies();
  }

  async ngOnInit() {
    await this.getLines();
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
  }

  getCompanies() {
    this.listService.getListSelect(this.urlCompanies).subscribe(
      response => {
        this.companiesList = response.results;
        this.logger.log("Lista rivenditori", this.companiesList, 300);
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  async getLines() {
    this.linesList = await this.listService.manageListValues(this.urlLines, 'Lista Linee assegnabili');
  }

  back() {
    this.router.navigate(["/lines/list"]);
  }

  onSubmit(): void {
    this.dataLine = this.formLine.value;
    
    this.listService.manageSubmitAction(
      SubmitActionType.CREATE,
      this.dataLine,
      this.serviceToCallPath,
      '/lines/list',
      'linea'
    );
  }

}

export interface LineModel {
  idCompany?: number;
  routeId: string;
}
