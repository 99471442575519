import { Component, OnInit, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import { UtilsService } from "../../../services/utils.service";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import swal2, { SweetAlertResult } from 'sweetalert2'
import { LoadingLabels } from '../../../enums/loading.enum';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-obliterators-list',
  templateUrl: './obliterators-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class ObliteratorsListComponent implements OnInit {

  @ViewChild('formFilter') filterForm: NgForm;


  errorMessage: string = "";
  dataFilter: any[] = new Array();
  vehiclesList: any[] = new Array();
  obliterators: any[] = new Array();
  companiesList: any[] = new Array();
  dataVersionObliterator: any[] = new Array();
  urlVehiclesPath = "/vehicle/vehicles";
  updateObliteratorVersionPath = "/obliterator/target-version";
  urlCompany = "/company/companies";
  urlVersionObliteratorPath = "/obliterator/apk/apks";
  urlDeletePath;
  companyVisible;
  visibleFilter;
  defaultValue;
  UpOrDown;
  typeUser;
  isSoluzione1;
  modalRef: BsModalRef;

  ObliteratorModel: any = new Object()
  targetVersion



  constructor(
    private toastr: ToastrService,
    private modalService: BsModalService,
    private utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private loadingService: LoadingService
  ) {
    //this.toastr.setRootViewContainerRef(vcr);
    this.listService.resetList();
    this.listService.restURL = "/obliterator/obliterators";

    this.listService.configurationServiceCall("/obliterators/list", false, true, 1, 12);
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
  }

  async ngOnInit() {
    //CHIAMATE PER SELECT
    await this.getVehicles();
    await this.getVersion();
    //CHIAMATA LISTA ELEMENTI
    this.loadElements();
    this.UpOrDown = true;
    this.companyVisible = this.utils.visibleCompany(this.companyVisible);
    this.companiesList = await this.utils.getCompanyList();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide()
  }

  viewModal(template: TemplateRef<any>, data) {
    this.openModal(template)
    this.ObliteratorModel = null;
    this.ObliteratorModel = data;

  }


  viewFilterModal(template: TemplateRef<any>) {
    this.openModal(template)

  }


  onKey(event: any) { // without type info
    this.targetVersion = event.target.value;
  }

  onChangeVersion(Version) {
    this.targetVersion = Version;
  }


  pageChange(page) {
    this.listService.page = page;
    this.loadElements()
  }

  filterSearch() {
    this.dataFilter = this.filterForm.value;

    this.listService.visibleFilter = true;

    this.loadElements();
  }
  getButtonCircle(statusNumber) {
    var buttonColor;
    if (statusNumber == 1) {
      return buttonColor = "btn btn-primary btn-circle"
    }
    if (statusNumber == 0) {
      return buttonColor = "btn btn-danger btn-circle"
    }
  }

  filterReset() {
    this.filterForm.value.serialNumber = ""
    this.filterForm.value.status = ""
    this.filterForm.value.idVehicle = ""
    this.filterForm.value.macAddress = ""
    this.filterForm.value.idCompany = ""
    this.listService.visibleFilter = false;
    this.loadElements();
    this.filterForm.reset();
    //this.loadElements();
  }

  //SERVIZI SELECT
  async getVehicles() {
    this.vehiclesList = await this.listService.manageListValues(this.urlVehiclesPath, 'Lista veicoli');
  }


  async getVersion() {
    this.dataVersionObliterator = await this.listService.manageListValues(this.urlVersionObliteratorPath, 'Lista veicoli');
  }

  getStatusDescription(statusNumber) {
    var buttonColor;
    if (statusNumber == 1) {
      return buttonColor = "Attivo"
    }
    if (statusNumber == 0) {
      return buttonColor = "Non Attivo"
    }
  }
  getBusinessName(id) {
    var string;
    for (let company of this.companiesList)
      if (company.idCompany == id) {
        return string = company.legalBusinessName
      }
  }

  getVeihcleName(idV) {
    var string;
    for (let vehicle of this.vehiclesList)
      if (vehicle.id == idV) {
        return string = vehicle.name
      }
  }

  checkVersion(version, target) {
    var value;
    if (version == target) {
      value = "btn btn-primary"
    } else {
      value = "btn btn-warning"
    }
    return value
  }


  checkVersionTitle(version, target) {
    var value;
    if (version == target) {
      value = "Le Versioni sono allinate"
    } else {
      value = "Le Versioni sono differenti"
    }
    return value
  }

  checkVersionIcon(version, target) {
    var value;
    if (version == target) {
      value = "fa fa-thumbs-up"
    } else {
      value = "fa fa-warning"
    }
    return value
  }


  updateVersion() {
    let payload = { targetVersion: this.targetVersion, obliteratorIds: [this.ObliteratorModel.idObliterator] }
    /*this.ObliteratorModel.softwareVersionTarget = this.targetVersion
    this.ObliteratorModel.idCompany = null
    this.ObliteratorModel.obliterationCount = null;
    this.ObliteratorModel.lastSynchronization = null;

    */
    this.listService.edit(payload, this.updateObliteratorVersionPath).subscribe(
      (response) => {
        this.closeModal();
        if (response.outcome.success === true) {
          swal2.close();
          swal2.fire("La versione target dell'obliteratrice è stata modificata", "", "success");
          // this.router.navigate(['/obliterators/list']);
          this.closeModal();
          this.loadElements();

        } else {
          this.errorMessage = response.outcome.code + response.outcome.description
          swal2.fire("Attenzione", this.errorMessage, "warning");
        }
      },
      (error) => {
        this.closeModal();
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.", "error");
      }
    );
  }

  updateAllVersion() {
    let payload = { targetVersion: this.targetVersion, obliteratorIds: [] }

    for (let o of this.obliterators) {

      o.softwareVersionTarget = this.targetVersion
      o.obliterationCount = null;
      o.lastSynchronization = null;
      payload.obliteratorIds.push(o.idObliterator);
    }
    this.listService.edit(payload, this.updateObliteratorVersionPath).subscribe(
      (response) => {
        this.closeModal();
        this.loadElements();
        if (response.outcome.success === true) {

          this.toastr.success('Allineamento concluso con successo');

        } else {
          this.toastr.error('Ops', 'Qualcosa è andato storto!');
        }
      },
      (error) => {
        this.closeModal();
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.", "error");
      }
    );




  }



  //SERVIZIO LISTA ELEMENTI
  loadElements() {
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.obliterators = response.results;
        this.listService.totalRows = response.total;

        this.logger.log("Response:", response, 300);
        this.errorMessage = "";
      },
      (error) => {
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  changeStatus() {
    if (this.UpOrDown == true) {
      this.UpOrDown = false;
    } else {
      this.UpOrDown = true;
    }

  }

  selectRow(dataElement) {
    this.listService.backToList = true;
    localStorage.setItem("dataPassed", JSON.stringify(dataElement));
    this.router.navigate(['/obliterators/edit']);
  }

  checkStatus(status) {
    return this.utils.checkStatus(status);
  }

  newObliterator() {
    this.listService.backToList = true;
    this.router.navigate(['/obliterators/new']);
  }


  deleteObliterator(idObliterator) {

    var mySelf = this;

    swal2.fire({
      title: 'Eliminazione Obliteratrice',
      text: "Sei sicuro di voler eliminare l'obliteratrice?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,

    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.deleteElement(idObliterator);
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })

  }

  deleteElement(idObliterator) {
    this.urlDeletePath = "/obliterator/" + idObliterator;

    this.loadingService.presentLoader(LoadingLabels.Delete);

    this.logger.log("ID DELETE:", idObliterator, 200);

    this.listService.delete(this.urlDeletePath).subscribe(
      (response) => {

        if (response.outcome.success === true) {

          this.logger.log("Response value", response.value, 200);
          swal2.fire("Eliminazione completata", "L'obliteratrice è stato eliminata con successo", "success").then((ev: SweetAlertResult) => {
            if (ev.value) {
              this.loadElements();
            }
          });
        } else {
          this.errorMessage = response.outcome.code + response.outcome.description
          swal2.fire("Attenzione", this.errorMessage, "warning");
        }
      },
      (error) => {
        this.logger.log("Error", error, 200);
        swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.Riprova più tardi", "error");
      }
    );

  }

}

