import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { LoggingService } from "../../../services/logging.service";
import { AuthService } from "../../../services/auth.service";
import { NgForm } from "@angular/forms";
import { LoadingLabels } from '../../../enums/loading.enum';
import { LoadingService } from '../../../services/loading.service';
import swal2 from 'sweetalert2'

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['../../../app.component.css']
})
export class ActivateComponent implements OnInit {

  @ViewChild('forgotForm') forgotForm: NgForm;

  private sub: any;
  public account = {
    firstLoginToken: "",
    password: "",
  };
  public barLabel: string = "Efficacia :";
  showPassword: string = "password";
  icon: string = "fa fa-eye"
  
  errorMessage = "";
  activateMessage = "La password deve comprendere 5 caratteri, e per essere efficace devono esserci una maiuscola, un numero e un carattere speciale";


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logger: LoggingService, 
    private authService: AuthService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
      
    this.sub = this.route.params.subscribe(params => {     
      this.account.firstLoginToken = params['firstLoginToken']; 
    });
  }


  showNewPassword(){
    
    if ( this.showPassword === "text" ) {
     this.showPassword = "password"
     this.icon = "fa fa-eye"
    } else {
      this.showPassword = "text"
      this.icon = "fa fa-eye-slash"
    }
     
  }
    
  onSubmit(): void {
    
    this.account.password = this.forgotForm.value.password;

    this.loadingService.presentLoader(LoadingLabels.Activation);

    this.authService.activate(this.account).subscribe(
        (response) => {
     
          if (response.outcome.success === true) {

        
             swal2.close();
             swal2.fire(
              'Attivazione',
              'Utente attivato',
              'success'
            );
             this.router.navigate(['/login']);
            // login successfull
        
            
           
          } else if (response.outcome.success === false && response.outcome.code === "0009"){
            this.errorMessage = "Gentile cliente, il link di attivazione da lei utilizzato è scaduto. Per ricevere un nuovo link contattare l'assistenza"
            swal2.fire("Errore", this.errorMessage,"error");
          } else {
            // login failed
             this.errorMessage = "Credenziali non valide."
            swal2.fire("Errore", this.errorMessage,"error");
          }
        },    
        (error) => {
          this.logger.log("Error",error,200);
          this.errorMessage = "Si è verificato un errore tecnico.";
            swal2.fire("Errore", this.errorMessage,"error");
          }
      );
    }
    
    
}
