import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserModule} from "@angular/platform-browser";
import { SubscritionListComponent } from './subscrition-list/subscrition-list.component';
import { SubscriptionTypologyComponent } from './subscription-typology/subscription-typology.component';
import { SubscriptionFareComponent } from './subscription-fare/subscription-fare.component';
import { SubscriptionTypologyManageComponent } from './subscription-typology-manage/subscription-typology-manage.component';
import { SubscriptionFareManageComponent } from './subscription-fare-manage/subscription-fare-manage.component';
import { SubscriptionSellComponent } from './subscription-sell/subscription-sell.component';
import { SubscriptionListManageComponent } from './subscription-list-manage/subscription-list-manage.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ComponentsModule } from '../../common/components/components.module';



@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule, BsDatepickerModule.forRoot(),
    ComponentsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [SubscritionListComponent, SubscriptionTypologyComponent, SubscriptionFareComponent, SubscriptionTypologyManageComponent, SubscriptionFareManageComponent, SubscriptionSellComponent, SubscriptionListManageComponent]
})
export class SubscriptionsModule {}