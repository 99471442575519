import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService, SubmitActionType } from '../../../services/list.service';
import { LoggingService } from '../../../services/logging.service';
import { UtilsService } from '../../../services/utils.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { listLocales } from 'ngx-bootstrap/chronos';
import { DatePipe } from '@angular/common';
import { SwitchConfig } from '../../../common/components/switch-button/switch-button.component';
defineLocale('it', itLocale);

@Component({
  selector: 'app-subscription-fare-manage',
  templateUrl: './subscription-fare-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class SubscriptionFareManageComponent implements OnInit, AfterViewInit {
  @ViewChild('ticketRatesForm') ticketRatesForm: NgForm;

  locale = 'it';
  locales = listLocales();
  // testo in HTML per tipo pagina
  kindTitle;
  kindManage;
  // URL servizio da chiamare, se post o put dipenderà da dove arriviamo
  serviceToCallPath = '/ticket/fare';
  // form html
  bsConfig: Partial<BsDatepickerConfig>;
  daterangepickerModel: Date[];
  bsRangeValue: Date[];
  // oggetto contenente dati component
  ticketFaresData: any;
  stops: any[] = new Array()
  filterStops: any[] = new Array()
  lines: any[] = new Array()
  companies: any[] = new Array()
  categories: any[] = new Array();
  pathListCategories = '/category/categories?type=S' //  filtro per abbonamenti
  pathRules = '/ticket/fare/fares/rules/'
  urlChannelPath;
  originStops: any[] = new Array();
  sol1OrSa = false;
  pathListStops = '/stop/stops/v2';
  errorMessage;
  stopsListPath;
  lineListPath;
  pathToCallCompaniesPath;
  isCheckApp
  isCheckEcommerce
  isCheckResale
  isCheckDesk;
  tempLineValue;
  destinationStops: any[] = new Array();
  channelArray: any[] = new Array();
  dataChannel: any[] = new Array();
  colorTheme = 'theme-orange';
  counterRules: any[] = new Array();
  model = {
    routeId: null,
    blockId: null,
    originId: null,
    destinationId: null,
    tripId: null,
    choseKind: '1',
    kindName: 'LINEA',
    decided: true
  };
  showInfo = false;
  validity: string;
  switchButtonConfig: SwitchConfig;

  constructor(
    private utils: UtilsService,
    private localeService: BsLocaleService,
    private route: ActivatedRoute,
    private logger: LoggingService,
    public listService: ListService,
    private router: Router
  ) {
    // Se creo nuovo veicolo
    this.bsConfig = Object.assign(
      {}, { containerClass: this.colorTheme }, { dateInputFormat: 'DD-MM-YYYY' }, { rangeInputFormat: 'DD-MM-YYYY' }
    );
    this.localeService.use(this.locale)

    if (this.route.snapshot.toString().indexOf('new') !== -1) {
      this.kindTitle = 'Nuova';
      this.tempLineValue = 'New'
      this.kindManage = 1;
      this.isCheckApp = true;
      this.isCheckDesk = true;
      this.isCheckEcommerce = true;
      this.isCheckResale = true;
      this.counterRules.push(this.model);
    } else { // se modifico veicolo
      this.kindTitle = 'Modifica';
      this.kindManage = 0;
      
      if (localStorage.getItem('dataPassed') === '') {
        this.router.navigate(['/tickets/rates']);
        return;
      }

      this.ticketFaresData = JSON.parse(localStorage.getItem('dataPassed'));

      let dateString = this.ticketFaresData['dateFrom']
      let newDate = new Date(dateString);
      let dateStringTo = this.ticketFaresData['dateTo']
      let newDateTo = new Date(dateStringTo);

      const date = new Date();
      const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
      const lastDay = new Date(newDateTo.getFullYear(), newDateTo.getMonth(), newDateTo.getDate());
      this.bsRangeValue = [firstDay, lastDay];

      this.channelArray = this.ticketFaresData['ticketChannel'];
      this.getRules(this.ticketFaresData['idTicketFare'])
      this.ticketFaresData.routeId = this.ticketFaresData.routeShortName;

      localStorage.setItem('dataPassed', ''); // svuoto dato in localstorage
    }

    this.switchButtonConfig = this.utils.configureFareValiditySwitchBtn(this.ticketFaresData?.dateType);
  }

  ngOnInit() {
    this.listService.resetList();
    this.listService.backToList = true;
    this.listService.listRouting = '/subscription/fares'
    this.stopsListPath = '/stop/stops'
    this.lineListPath = '/route/routes'
    this.pathToCallCompaniesPath = '/company/companies'
    this.urlChannelPath = '/ticket/channel/channels';
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.ticketFaresData && this.ticketFaresData['idCompany']) {
      this.ticketRatesForm.value.idCompany = this.ticketFaresData['idCompany'];
    }

    await this.getSelectStops();
    await this.getSelectLines();
    await this.getCategories();
    await this.getStopsFrom();
    await this.getChannel();

    /** SA features */
    this.sol1OrSa = this.utils.checkSadminOrSol1();
    if (this.sol1OrSa) {
      this.getCompanies();
    }
  }

  async getStopsFrom(): Promise<void> {
    let requestUrl = this.pathListStops;
    if (this.ticketRatesForm && this.ticketRatesForm.value.idCompany) {
      requestUrl += `?idCompany=${this.ticketRatesForm.value.idCompany}`;
    }
    this.originStops = await this.listService.manageListValues(requestUrl, 'Lista fermate partenza');
  }

  async getRules(id: number): Promise<void> {
    this.counterRules = await this.listService.manageListValues(this.pathRules + id, 'Lista rules');
    this.counterRules.forEach(c => {
      if (c.routeId !== null) {
        c.kindName = 'LINEA'
      }
      if (c.blockId !== null) {
        c.kindName = 'TRATTA'
      }
      if (c.originId !== null) {
        c.kindName = 'TRA DUE SPECIFICHE FERMATE'
      }

      if (c.tripId !== null) {
        c.kindName = 'CORSA'
      }
    });
  }

  async onChangeStop(id: number) {
    await this.getStopsTo(id);
  }


  async getStopsTo(idStop: number): Promise<void> {
    this.destinationStops = await this.listService.manageListValues(this.pathListStops + '/' + idStop, 'Lista fermate fine');
  }


  isChecked(event) {

    if (event.target.checked) {
    } else {
      this.ticketRatesForm.value.toStop = null
      this.filterStops = []
    }
  }


  findList(idLine) {
    if (this.tempLineValue !== 'New') {
      this.filterStops = [];
      for (let stop of this.stops) {
        if (stop.routeShortName === idLine) {
          this.filterStops.push(stop)
        }
      }
    }
  }


  onChangeLine(idLine) {
    let string;

    string = idLine.substring(3)
    this.filterStops = []
    for (let stop of this.stops) {
      if (stop.routeShortName === string) {
        this.filterStops.push(stop)
      }
    }
  }

  async getSelectStops() {
    this.stops = await this.listService.manageListValues(this.stopsListPath, 'Lista aziende');
  }

  async getSelectLines() {
    let requestUrl = this.lineListPath;
    if (this.ticketRatesForm && this.ticketRatesForm.value.idCompany) {
      requestUrl += `?idCompany=${this.ticketRatesForm.value.idCompany}`;
    }
    this.lines = await this.listService.manageListValues(requestUrl, 'Lista linee');
  }

  getCompanies() {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe((response) => {
      this.companies = response.results;
      this.logger.log('Lista aziende', this.companies, 300);
    },
      (error) => {
        this.logger.log('Error', error, 200);
      }
    );
  }


  async getCategories() {
    this.showInfo = false;
    
    let requestUrl = this.pathListCategories;
    if (this.ticketRatesForm && this.ticketRatesForm.value.idCompany) {
      requestUrl += `&idCompany=${this.ticketRatesForm.value.idCompany}`;
    }
    this.categories = await this.listService.manageListValues(requestUrl, 'Lista Categorie');
    
    if (this.categories) {
      this.showInfo = true;
    }
  }

  async getChannel() {
    let requestUrl = this.urlChannelPath;
    if (this.ticketRatesForm && this.ticketRatesForm.value.idCompany) {
      requestUrl += `?idCompany=${this.ticketRatesForm.value.idCompany}`;
    }
    this.dataChannel = await this.listService.manageListValues(requestUrl, 'Lista canali');
  }

  setIdChannel(kind) {
    let i = 0;
    for (let val of this.channelArray) {
      if (val.toString() === kind.toString()) {
        this.channelArray.splice(i, 1);
        return;
      }
      i++;
    }
    this.channelArray.push(kind.toString());
  }

  isCheckChannel(idChannel) {
    for (let i of this.channelArray) {
      if (i.toString() === idChannel.toString()) { return true; }
    }
    return false;
  }

  chooseLineSubscription() {
    this.model['routeId'] = this.ticketRatesForm.value.routeId;
    this.counterRules = [];
    this.counterRules.push(this.model);
  }

  onSubmit(): void {
    const dateFrom = new DatePipe('en-EN').transform(
      this.bsRangeValue[0],
      'yyyy-MM-dd'
    );
    const dateTo = new DatePipe('en-EN').transform(
      this.bsRangeValue[1],
      'yyyy-MM-dd'
    );

    const requestData: any = new Object();

    requestData.dateFrom = dateFrom;
    requestData.dateTo = dateTo;
    requestData.description = this.ticketRatesForm.value.description;
    requestData.price = this.ticketRatesForm.value.price;
    requestData.categoryId = this.ticketRatesForm.value.categoryId;
    requestData.idTicketChannel = this.channelArray;
    requestData.fareRules = this.counterRules;
    requestData.tripMaxDay = this.ticketRatesForm.value.tripMaxDay;
    requestData.tripMaxPeriod = this.ticketRatesForm.value.tripMaxPeriod;
    requestData.dateType = this.validity;

    if (!this.kindManage) {
      requestData.fareId = this.ticketFaresData['idTicketFare'];
      requestData.categoryId = this.ticketFaresData['idCategory'];
    }

    this.ticketFaresData = this.ticketRatesForm.value;

    this.listService.manageSubmitAction(
      this.kindManage ? SubmitActionType.CREATE : SubmitActionType.EDIT,
      requestData,
      this.serviceToCallPath,
      '/subscription/fares',
      'tariffa'
    );
  }

  returnToList() {
    this.router.navigate(['/subscription/fares'])
  }

  async onChangeCompany(): Promise<void> {
    await this.getSelectLines();
    await this.getCategories();
    await this.getStopsFrom();
    await this.getChannel();
  }

  validitySwitch(val: string): void {
    this.validity = val;
  }

}

