import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { ListService } from '../../../services/list.service';
import { LoggingService } from '../../../services/logging.service';
import { LoadingService } from '../../../services/loading.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoadingLabels, LoadingSubtitles } from '../../../enums/loading.enum';
import swal2 from 'sweetalert2'

@Component({
  selector: 'app-ticket-fares-uploader',
  templateUrl: './ticket-fares-uploader.component.html',
  styleUrls: ['./ticket-fares-uploader.component.css']
})
export class TicketFaresUploaderComponent implements OnInit {
  @Input() idCompany: number;
  @Input() idFare: number;
  
  @Output() modalClose: EventEmitter<void> = new EventEmitter();
  @Output() uploadEvent: EventEmitter<void> = new EventEmitter();
  
  modalRef: BsModalRef = new BsModalRef();
  uploader: FileUploader;
  urlUploadCsv = '/ticket/fare/import';
  fileToUpload: File | null = null;
  uploadCode: string = 'stop';

  constructor(
    private listService: ListService,
    private logger: LoggingService,
    private loadingServce: LoadingService
  ) {
    this.uploader = new FileUploader(null);
  }

  ngOnInit(): void { }

  handleCsvUpload(files: FileList): void {
    if (this.uploader.queue.length > 1) {
      this.uploader.removeFromQueue(this.uploader.queue[0])
    };
    this.fileToUpload = files.item(0);
  }

  toggleUploadCode(code: string): void {
    this.uploadCode = code;
  }

  closeModal(): void {
    this.modalClose.emit();
  }

  uploadCsv() {
    this.loadingServce.presentLoader(LoadingLabels.DataLoading, LoadingSubtitles.BigDataLoading);
    const reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);
    reader.onload = () => {
      const base64File = reader.result.toString().substring(reader.result.toString().indexOf(',') + 1);
      const payload = {
        idCompany: +this.idCompany,
        fileEncoded: base64File,
        code: this.uploadCode,
        ...(this.idFare) && {fareId: this.idFare}
      }
      
      this.listService.newObject(payload, this.urlUploadCsv)
        .subscribe(response => {
          this.loadingServce.hideLoader();
          if (response.outcome.success === true) {
            swal2.fire('CSV caricato con successo', '', 'success');
          } else {
            swal2.fire('Attenzione', 'Errore nell\'importazione. Controlla le indicazioni e riprova.', 'warning');
          }
          this.uploadEvent.emit();
        },
        error => {
          this.logger.log('Error', error, 200);
          this.loadingServce.hideLoader();
          swal2.fire(
            'Errore',
            'Spiacente, si è verificato un errore tecnico.',
            'error'
          );
          this.uploadEvent.emit();
        }
      );
    };
  }

}
