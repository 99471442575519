import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ListService } from '../../../services/list.service';
import { UtilsService } from '../../../services/utils.service';
import { LoggingService } from '../../../services/logging.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TicketCategoryType } from '../../../enums/configuration.enum';
import swal2, { SweetAlertResult } from 'sweetalert2';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-ticket-category',
  templateUrl: './ticket-category.component.html',
  styleUrls: ['../../../app.component.css', './ticket-category.component.css'],
})
export class TicketCategoryComponent implements OnInit {

  @ViewChild('searchForm') searchForm: NgForm;
  @ViewChild('categoryModal') categoryModalTemplate: TemplateRef<any>;

  dataList: Array<any> = new Array();
  errorMessage = '';
  isSoluzione1: any;
  typeUser: any;
  showActive = 1;
  activeTab = 'GRUPPI';
  idCompany: number;
  pathToCallCompaniesPath = '/company/companies';
  companiesList: any;

  TicketCategoryType = TicketCategoryType;

  constructor(
    private listService: ListService,
    private router: Router,
    private utils: UtilsService,
    private logger: LoggingService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser)

    if ( !this.isSoluzione1 ) {
      this.loadElements();
    } else {
      this.loadCompanies()
    }
  }

  async loadElements() {
    let path = '/category';
    if ( this.isSoluzione1 ) {
      if (this.idCompany != null && this.showActive === 1 && this.activeTab === 'GRUPPI' ) {
        path += '/categories-grouped?idCompany=' + this.idCompany;
      } else if ( this.idCompany != null && this.showActive === 0 && this.activeTab === 'GRUPPI' ) {
        path += '/categories-grouped?idCompany=' + this.idCompany + '&deleted=true';
      } else if ( this.idCompany != null && this.activeTab === 'CATEGORIE_DISATTIVATE' ) {
        path += '/categories?idCompany=' + this.idCompany + '&deleted=true';
      }
    } else {
      if ( this.showActive === 1 && this.activeTab === 'GRUPPI' ) {
        path += '/categories-grouped';
      } else if ( this.showActive === 0 && this.activeTab === 'GRUPPI' ) {
        path += '/categories-grouped?deleted=true';
      } else if ( this.activeTab === 'CATEGORIE_DISATTIVATE' ) {
        path += '/categories?deleted=true';
      }
    }

    let resp = await firstValueFrom(this.listService.getListSelect(path));
    if ( this.utils.checkResponse(resp) ) {
      // this.dataList = resp.results;
      if ( resp.total > 0) {
        this.dataList = resp.results.map( x => {
          if ( this.activeTab === 'GRUPPI' ) {
            x.expanded = false ;
          }
          if ( x.deleted == null ) {
            x.deleted = false;
          }
          return x;
        });
        this.errorMessage = '';
      } else {
        this.errorMessage = 'Nessun risultato';
      }
    } else {
      swal2.fire('Errore' , 'Spiacente, si è verificato un errore tecnico.' , 'error');
      this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
    }
  }

  async updateElement( item2Update ) {
    let path = '/category';
    if ( !item2Update.categoryId ) {
      path += '/categories-grouped'
      delete item2Update.expanded
    }
    let resp: any = await this.listService.edit( item2Update , path ).toPromise();
    if ( resp.outcome.success === true ) {
      this.loadElements();
    } else {
      swal2.fire('Errore' , 'Spiacente, si è verificato un errore tecnico.' , 'error');
      this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
    }
  }

  toggleDetails( index ) {
    this.dataList[index].expanded = !this.dataList[index].expanded
  }

  toggleOnOff( item , index = null , event ) {
    let indexOfChild: number;
    if ( item.categoryId && this.activeTab === 'GRUPPI' ) {
      let arrayCategories: Array<any> = this.dataList[index].categories;
      indexOfChild = arrayCategories.indexOf( item );
      if ( indexOfChild !== -1 ) {
        this.dataList[index].categories[indexOfChild].deleted = event;
      }
    } else if ( this.activeTab === 'CATEGORIE_DISATTIVATE' ) {
      item.deleted = event;
    } else {
      this.dataList[index].deleted = event;
    }
    const textON = 'Stai per attivare la categoria "<b>' + item.name + '</b>". Attivandolo renderai possibile permetterai la generazione di ticket con la sua tariffa.';
    const textOFF = 'Sei sicuro di voler disattivare la categoria "<b>' + item.name + '</b>". Disattivando la categoria non potrai generare ticket utilizzano la sua tariffa';

    swal2.fire({
      title: 'Attenzione',
      html: event ? textOFF : textON ,
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Indietro'
    }).then((result: SweetAlertResult) => {
      if (result.value) {
        if ( item.categoryId && this.activeTab === 'GRUPPI') {
        } else if ( this.activeTab === 'CATEGORIE_DISATTIVATE' ) {
        } else {
          this.dataList[index].categories = item.categories.map( x => {
            x.deleted = this.dataList[index].deleted;
            return x;
          })
        }
        this.updateElement( item );
      } else {
        item.deleted = !item.deleted;
      }
    }, _ => {
      if ( item.categoryId ) {
        this.dataList[index].categories[indexOfChild].deleted = !event;
      } else {
        this.dataList[index].deleted = !event;
      }
    })

  }

  async toggleShowActive( btnValue ) {
    this.showActive = btnValue;
    await this.loadElements();
  }

  async tabSelector( event , tab_name) {
    this.activeTab = tab_name;
    await this.loadElements();
  }

  async searchCompanyCategory() {
    this.idCompany = this.searchForm.value.idCompany;
    await this.loadElements();
  }

  loadCompanies() {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe((response) => {
        this.companiesList = response.results;
        this.searchCompanyCategory();
      }, (error) => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  getCompanyName(idCompany: number) {
    if (idCompany) {
      let stringCompanyName: string;
      for (let company of this.companiesList) {
        if (company.idCompany === idCompany) {
          stringCompanyName = company.legalBusinessName
          return stringCompanyName;
        }
      }
    }
  }

  openModal() {
    this.modalService.show(this.categoryModalTemplate);
  }

  newCategory(categoryType: string) {
    this.modalService.hide();
    this.listService.backToList = true;
    this.router.navigate(['/categories/new', categoryType]);
  }

  selectRow(dataElement: any, categoryType: string) {
    this.listService.selectedID = dataElement[categoryType === TicketCategoryType.categoriesGroup ? 'groupId' : 'categoryId'];
    this.listService.backToList = true;
    this.router.navigate(['/categories/edit', categoryType]);
  }

}
