import { Injectable } from '@angular/core';
import swal2, { SweetAlertResult } from 'sweetalert2'

@Injectable()
export class LoadingService {

    /** Method that creates a loader using a title and an eventual subtitle (text) */
    presentLoader(title: string, text?: string): Promise<SweetAlertResult> {
        return new Promise(resolve => {
            swal2.fire({
                title,
                text,
                allowEscapeKey: false,
                allowOutsideClick: false,
                imageUrl: 'assets/loader/logo-bouncing.gif',
                imageAlt: 'PTK',
                showConfirmButton: false,
            }).then((res: SweetAlertResult) => {
                resolve(res);
            });
        });
    };

    /** Closes the list retrieve loader */
    hideLoader(): void {
        swal2.close();
    }

}
