import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ListService } from "../../../services/list.service";

@Component({
  selector: 'app-resales-details',
  templateUrl: './resales-details.component.html',
  styleUrls: ['../../../app.component.css']
})
export class ResalesDetailsComponent implements OnInit {

  constructor(
    private router: Router,
    private listService: ListService
  ) {
    this.listService.listRouting = "/companies/details";
  }

  vehicleTotal;
  printersTotal;
  resaleTotal;
  obliteratorTotal;
  amount;
  urlCompany;
  errorMessage;
  pathDashboardResale

  resaleDashboard: any = new Array();
  companiesList: any[] = new Array();
  filterCompaniesList: any[] = new Array();

  ngOnInit() {
    this.pathDashboardResale = "/dashboard/resale"
    this.getDashboardResale()
  }


  async getDashboardResale() {
    this.resaleDashboard = await this.listService.manageListValues(this.pathDashboardResale, null, false);
  }

  goToPrinters() {
    this.router.navigate(["/printers/list"]);
  }

}
