import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../services/logging.service';
import { ListService } from '../../services/list.service';
import { UtilsService } from '../../services/utils.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { environment } from '../../../environments/environment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import swal2, { SweetAlertResult } from 'sweetalert2'
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-research-trip-line',
  templateUrl: './research-trip-line.component.html',
  styleUrls: ['../../app.component.css']
})
export class ResearchTripLineComponent implements OnInit {

  // FORM
  @ViewChild('formLines') linesForm: NgForm;
  // OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  dataLines: any[] = new Array();
  companiesList: any[] = new Array();
  urlLine = '/route/routes'
  urlCompany = '/company/companies';
  directionList: any[] = new Array();
  directionValue;
  directionString;
  idLine;
  page;
  stringDataLine;
  pathRoutesList;
  pathLinetripList;
  routeLongName;
  totalRow;
  stopsDataList: any[] = new Array();
  tripList: any[] = new Array();

  roleUser
  typeUser

  // PATH CHIAMATE
  urlDeletePath = '';
  urlDeleteTripPath = '/trip/cancelledtriplist';
  urlRestoreTripPath = '/trip/resumeTripCancelled';
  urlEditedTripPath = '/trip/editedTrip';
  errorMessage = '';
  modalRef: BsModalRef;

  // TRIP PREDICTION
  tripIdOnFocus = '';
  urlTripPrediction = environment.restBaseUrl + '/stats/trip-prediction';
  tripPredictions: any[] = [];
  tripTypes: { code: string, tickets: number }[] = [];
  colorTheme = "theme-orange";
  bsConfig: Partial<BsDatepickerConfig>;

  // TRIP ACTION CENTRALIZED TOOL
  tripActionStrings = {
    0: { // delete
      confrimSwalTitle: "Cancellazione corsa",
      confrimSwalMsg: "Sei sicuro di voler cancellare la corsa?",
      swalTitle: "Cancellazione in corso...",
      successMsg: "La corsa è stata cancellata",
      path: this.urlDeleteTripPath
    },
    1: { // restore
      confrimSwalTitle: "Ripristino corsa",
      confrimSwalMsg: "Sei sicuro di voler ripristinare la corsa?",
      swalTitle: "Ripristino in corso...",
      successMsg: "La corsa è stata ripristinata",
      path: this.urlRestoreTripPath
    },
    2: { // sendInfo
      confrimSwalTitle: "Comunicazione modifica corsa",
      confrimSwalMsg: "Inviare la comunicazione di modifica agli utenti?",
      swalTitle: "Invio email in corso...",
      successMsg: "Comunicazione inviata agli utenti interessati",
      path: this.urlEditedTripPath
    }
  }

  sentEditMsgs: string[] = [];

  constructor(
    public utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private modalService: BsModalService,
    private loadingService: LoadingService
  ) {
    this.listService.resetList();
    this.page = 1;
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
  }

  async ngOnInit() {
    await this.getLines();

    this.roleUser = this.utils.getRoleUser();
    this.typeUser = this.utils.getTypeUser();

    this.pathRoutesList = '/route/routes';
    this.pathLinetripList = '/trip/linetriplist';

    this.companiesList = await this.utils.getCompanyList();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalService.hide();
  }

  onChange(idRoute) {
    this.tripList = []
    this.idLine = idRoute;
    this.sentEditMsgs = [];

    this.getDirections()
  }

  onDateChanged(stringData: Date) {
    if (stringData) {
      this.stringDataLine = this.utils.convertDateToISO(stringData);
      this.getHourTrip();
    }
  }


  async getHourTrip() {
    this.tripList = []
    const filterPAth =
      '?date=' +
      this.stringDataLine +
      '&line=' +
      this.idLine +
      '&directionId=' +
      this.directionValue + '&paging=true&page=' + this.page + '&rows=10';

    const trips = await this.listService.manageListValues(this.pathLinetripList + filterPAth, 'Lista corse', false);
    this.tripList = trips.results;
    this.totalRow = trips.total;
  }

  pageChange(page) {
    this.page = page;
    this.getHourTrip();
  }

  getStops(tripId, template: TemplateRef<any>) {
    for (let t of this.tripList) {
      if (t.tripId === tripId) {
        this.stopsDataList = t.stopsData
      }
    }
    this.openModal(template);

  }

  // FILTRI
  filterSearch() {
    this.dataFilter = this.linesForm.value;
    this.loadElements();
    this.listService.visibleFilter = true;
  }

  filterReset() {
    this.linesForm.value.routeShortName = ''
    this.listService.visibleFilter = false;
    this.loadElements();
    this.linesForm.reset();
  }

  async getDirections() {
    const line = this.idLine;
    const stringPath = '/' + line;
    
    this.directionList = await this.listService.manageListValues(this.pathRoutesList + stringPath, 'Lista listini');
  }

  getDirection(directionValue) {
    const string = directionValue.substring(3);
    this.directionValue = string;
    this.sentEditMsgs = [];

    for (let d of this.directionList) {
      if (d.directionId === this.directionValue) {
        this.routeLongName = d.routeLongName
        this.directionString = d.arrival
      }
    }

    if (this.stringDataLine !== null && this.stringDataLine !== '') {
      this.getHourTrip();
    }
  }

  async getLines() {
    this.dataLines = await this.listService.manageListValues(this.urlLine, 'Lista Linee');
  }

  // SERVIZIO LISTA ELEMENTI
  loadElements() {
    this.sentEditMsgs = [];
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;

        this.logger.log('Response:', response, 300);
        this.errorMessage = '';
      },
      (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
      }
    );
  }

  selectRow(dataElement) {
    this.listService.selectedID = dataElement['idRoute'];
    this.listService.backToList = true;
    localStorage.setItem('dataPassed', JSON.stringify(dataElement));
    this.router.navigate(['/lines/edit']);
  }

  /** Method called on trip action button click event */
  tripAction(idTrip: string, action: number) {
    const that = this;
    swal2.fire({
      title: this.tripActionStrings[action].confrimSwalTitle,
      text: this.tripActionStrings[action].confrimSwalMsg,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: `Sì`,
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        that.actionOnTrip(action, idTrip);
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })

  }

  /** Method that manages all the possible operations on a trip element (delete / restore / sendInfo), calling a POST api with the same data */
  actionOnTrip(action: number, idTrip: string) {
    const data = { tripId: idTrip, tripDate: this.stringDataLine + ' 00:00:00.000000 ' };

    this.loadingService.presentLoader(this.tripActionStrings[action].swalTitle);

    this.logger.log('ID DELETE:', idTrip, 200);

    this.listService.newObject(data, this.tripActionStrings[action].path).subscribe(
      (response) => {
        if (response.outcome.success === true) {
          this.logger.log('Response value', response.value, 200);
          if (action === 2) {
            this.sentEditMsgs.push(idTrip);
          }
          swal2.fire(this.tripActionStrings[action].successMsg, '', 'success').then((ev: SweetAlertResult) => {
            if (ev.value) {
              this.getHourTrip();
            }
          });
        } else {
          this.errorMessage = response.outcome.code + response.outcome.description;
          swal2.fire('Attenzione', this.errorMessage, 'warning');
        }
      },
      (error) => {
        this.logger.log('Error', error, 200);
        swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.Riprova più tardi', 'error');
      }
    );
  }

  getTripPrediction(tripId: string, template: TemplateRef<any>): void {
    this.tripIdOnFocus = tripId;
    this.tripPredictions = [];
    this.tripTypes = [];
    const filters = { tripId, tripDate: this.stringDataLine };
    this.listService.getList(this.urlTripPrediction, filters).subscribe(
      (response) => {
        this.logger.log('Response value', response.value, 200);
        this.tripPredictions = response.results;

        if (this.tripPredictions) {
          this.sortTripPredictions();
        }

        this.openModal(template);
      },
      (error: Error) => {
        this.logger.log('Error', error, 200);
        swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.Riprova più tardi', 'error');
      }
    )
  }

  // ---------- PRIVATE METHODS ---------- //

  private sortTripPredictions(): void {
    this.tripPredictions.map(
      t => { return t.voucherEmitChannel ? t.voucherEmitChannel : 'ALTRO' }
    ).forEach(type => this.tripTypes.find(v => v.code === type)
      ? this.tripTypes.find(v => v.code === type).tickets++
      : this.tripTypes.push({ code: type, tickets: 1 })
    );
  }

}
