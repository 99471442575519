import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LoggingService } from "../../../services/logging.service";
import { ListService, SubmitActionType } from "../../../services/list.service";
import { AuthService } from "../../../services/auth.service";
import { UtilsService } from "../../../services/utils.service";

@Component({
  selector: 'app-desk-manage',
  templateUrl: './desk-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class DeskManageComponent implements OnInit {

  //form html
  @ViewChild("vehicleForm") formVehicle: NgForm;

  //testo in HTML per tipo pagina
  kindTitle;
  kindManage;
  //URL servizio da chiamare, se post o put dipenderà da dove arriviamo
  serviceToCallPath = "/desk";
  //oggetto contenente dati component
  dataVehicle: VehicleModel = new VehicleModel();
  companiesList: any[] = new Array();
  pathToCallCompaniesPath = "/company/companies";

  urlSpecificResalePath = "/user/";
  isSoluzione1;
  typeUser;

  errorMessage;
  id;
  idCompany;

  constructor(
    private utils: UtilsService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private logger: LoggingService,
    public listService: ListService,
    private router: Router
  ) {
    //Se creo nuovo veicolo
    if (route.snapshot.toString().indexOf("new") != -1) {
      this.kindTitle = "Nuovo";
      this.kindManage = 1;
    } else {
      //se modifico veicolo
      this.kindTitle = "Modifica";
      this.kindManage = 0;
      if (localStorage.getItem("dataPassed") == "") {
        this.router.navigate(["/desk/list"]);
        return;
      }
      this.dataVehicle = JSON.parse(localStorage.getItem("dataPassed"));

      this.id = this.dataVehicle.id;

      this.idCompany = 1;

      localStorage.setItem("dataPassed", ""); //svuoto dato in localstorage
    }
  }

  ngOnInit() {
    this.listService.resetList();
    this.listService.backToList = true;
    this.listService.listRouting = "/desk/list";
    this.getCompanies();
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
  }

  getCompanies() {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe(
      response => {
        this.companiesList = response.results;
        this.logger.log("Lista rivenditori", this.companiesList, 300);
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  onSubmit(): void {
    this.dataVehicle = this.formVehicle.value;
    if (!this.kindManage) {
      this.dataVehicle = this.formVehicle.value;
      this.dataVehicle.id = this.id;
      this.dataVehicle["status"] = "1"
      this.dataVehicle.idCompany = this.formVehicle.value.idCompany;
    }

    this.listService.manageSubmitAction(
      this.kindManage ? SubmitActionType.CREATE : SubmitActionType.EDIT,
      this.dataVehicle,
      this.serviceToCallPath,
      '/desk/list',
      'desk'
    );
  }

  back() {
    this.router.navigate(["/desk/list"]);
  }
}

export class VehicleModel {
  brand;
  id;
  idCompany;
  name;
  plate;
  maxSeat;
  status;
  description;

  constructor() { }
}

