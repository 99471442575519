import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import swal2 from 'sweetalert2'

@Component({
  selector: 'app-sales-details',
  templateUrl: './sales-details.component.html',
  styleUrls: ['../../../app.component.css']
})
export class SalesConsultationDetailsComponent implements OnInit {

 constructor(private logger: LoggingService, private router: Router, public listService: ListService) {
    this.listService.listRouting = "/orders/list";
    this.listService.restURL = "/order/orders";
    this.listService.backToList = false;
    this.listService.paging = true; // server pagination
    this.listService.rows = 10;
    this.listService.page = 1;
  }

  public beginValidity = {
    // other options...
    dateFormat: 'yyyy-mm-dd',
    inline: false,
    editableDateField: false
  };

  public endValidity = {
    // other options...

    dateFormat: 'yyyy-mm-dd',
    inline: false,
    editableDateField: false
  };

  // Initialized to specific date (09.10.2018).
  private model: Object = { date: { year: 2018, month: 10, day: 9 } };



  errorMessage: string = "";
  orders: any[] = new Array();
  deletePath;
  commissions: any[] = new Array();
  commisionListPath;
  suppliers: any[] = new Array();
  supplierListPath;

  visibleFilter;
  defaultValue;
i
  idSupFilter = null;
  idCommFilter = null;
  idStatusFilter = null;


  qrCodeWindow;
  stringQrCode;

  ngOnInit() {
    this.supplierListPath = "/suppliers/suppliers"
    this.deletePath = "/admin/order/";
    this.commisionListPath = "/commissions/commissions";

    this.qrCodeWindow = false;
    this.stringQrCode = "ND"

    this.getOrders();
    this.getCommissions();
    this.getSuppliers();
    
    if (this.listService.idSupplierFilter || this.listService.idCommissionFilter) {
      this.visibleFilter = true
    }
  }

  pageChange(page) {
    this.listService.page = page;
    this.getOrders();
  }

  getOrders() {
    this.listService.getGenericFilterResult(this.idSupFilter, this.idCommFilter, null, null, null, this.idStatusFilter).subscribe(
      (response) => {

        this.orders = response.results;
        this.listService.totalRows = response.total;
        this.logger.log("Response:", response, 300);
        this.errorMessage = "";
      },
      (error) => {
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  getSuppliers() {
    this.listService.getList(this.supplierListPath).subscribe((response) => {
      this.suppliers = response.results;
      this.logger.log("Lista fornitori: ", this.suppliers, 300);
    },
      (error) => {
     
      }
    );
  }

  selectRow(idOrder, nameOrder) {
    this.listService.selectedID = idOrder;
 //   this.listService.orderName = nameOrder;
    this.listService.backToList = true;
    this.router.navigate(['/orders/edit/' + idOrder]);
  }

  goToRecharge(){
    this.router.navigate(['/wallet/recharge']);
  }

  viewQrCode(stringQr){
this.qrCodeWindow = true
this.stringQrCode = stringQr
  }

  closeQrCodeTicketDetails(){
    this.qrCodeWindow = false

  }

  getCommissions() {
    this.listService.getList(this.commisionListPath).subscribe((response) => {
      this.commissions = response.results;
      this.logger.log("Lista commesse ", this.commissions, 300);
    },
      (error) => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  newOrder() {
    this.listService.backToList = true;
    this.router.navigate(['/orders/new']);
  }

  reset() {
    this.idSupFilter = null;
    this.idCommFilter = null;
    this.idStatusFilter = null;
    this.visibleFilter = false;

    this.getOrders()
  }

  
}


