import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { Router } from "@angular/router";
import {
  NgForm
} from "@angular/forms";
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import swal2 from "sweetalert2";
import { CookieService } from 'ngx-cookie-service';
import { UUID } from "angular2-uuid";
import { UtilsService } from "../../../services/utils.service";
import { ToastrService } from 'ngx-toastr';
import { SoldService } from "../../../services/soldHelper.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { LoadingLabels } from "../../../enums/loading.enum";
import { LoadingService } from "../../../services/loading.service";

@Component({
  selector: 'app-subscription-sell',
  templateUrl: './subscription-sell.component.html',
  styleUrls: ['../../../app.component.css']
})
export class SubscriptionSellComponent implements OnInit {

  public dateFrom = {
    // other options...
    dateFormat: "dd-mm-yyyy",
    inline: false,
    editableDateField: false,
    disableUntil: this.utils. yesterdayDate()
 //   showWeekNumbers: true, 
 //   disableWeekdays: ['tu', 'we', 'th', 'tu', 'fr', 'sa', 'su']
    
   
  };

  printerList: any[] = new Array();
  fareList: any[] = new Array();
  dataPriceList: any[] = new Array();
  filterPriceList: any[] = new Array();

  dataResponseTickets: any[] = new Array();


  pathUrlFareList;
  pathUrlPriceList;
  pathUrlPrinterList;
  pathCompleteSell;

  visiblePriceList;
  visibleMonthList;
  visiblePickerDate;

  chooseList;
  listNameString;
  fareNameString; 
  ticketTotalPrice;
  lineString; 
  idCardString;
  stringDataLine;
  stringDataLineYear;
  defaultValuePcUUID;
  defaultIdPrinter;
  serialNumber;
  errorMessage;
  typeUser;
  soldChannel;
  pathChekPdfAvaiable;
  isPdfEnabled;
  todayString;


  channel;
  colorTheme = "theme-orange";
  bsConfig: Partial<BsDatepickerConfig>;

  ticketModelHelper  = new TicketModel(); 

  @ViewChild("formSalesNewSubscription") salesNewSubscriptionForm: NgForm;
  constructor(
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    public utils: UtilsService,
    public cookieService: CookieService,
    public toastr: ToastrService,
    private soldHelper: SoldService,
    private loadingService: LoadingService
  ) {
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" }
    );
  }

  async ngOnInit() {


    this.todayString = this.utils.today()
    this.typeUser = this.utils.getTypeUser();

    this.soldChannel =  this.soldHelper.getTicketChannel(this.typeUser)
    this.channel = this.utils.getSoldChannel()

    this.pathUrlFareList = "/ticket/fare/fares";
    this.pathUrlPriceList = "/ticket/pricelist/pricelists?subscription=any&validOn=" + this.todayString+"&idChannel="+this.channel;
    this.pathUrlPrinterList = "/printer/printers"
    this.pathCompleteSell = "/ticket";
    await this.getFareList();
    await this.getPriceList();
    await this.getPrinterList();
    this.pathChekPdfAvaiable = "/ticket/pdfenable"

    this.detectUUID();

    this.visiblePriceList = false;
    this.ticketTotalPrice = 0.00;
    await this.checkEnable()
  }

  retrievePcUUID(printerList) {
    for (let p of printerList) {
      if (p.pcUUID == this.defaultValuePcUUID) {
        this.defaultIdPrinter = p.id;
        this.serialNumber = p.serialNumber;
      }
    }
  }
  detectUUID() {
    if (
      this.cookieService.get("BU-UUID") == undefined
    ) {
      let uuid = UUID.UUID();
      this.cookieService.set("BU-UUID", uuid);
    }
    this.defaultValuePcUUID = this.cookieService.get(
      "BU-UUID");
  }


  async checkEnable() {
    const res = await this.listService.manageListValues(this.pathChekPdfAvaiable, 'Risposta PDF', false);
    this.isPdfEnabled = res.outcome.success;
  }

  onChange(dataList){
    var stringTest = dataList.split(":")[1];
    var stringidPriceList = stringTest.substring(1);

    this.chooseList = true;

    var priceList = this.soldHelper.getServicePriceList(this.filterPriceList,stringidPriceList)
    var ticketHelper = new TicketModel()
   
    ticketHelper.idPriceList = priceList.idTicketPricelist;
    ticketHelper.tripMaxDay = priceList.tripMaxDay;
    ticketHelper.tripMaxPeriod = priceList.tripMaxPeriod;
    ticketHelper.subscription = priceList.subscription;
    ticketHelper.idCompany = priceList.idCompany;
    ticketHelper.price = priceList.price;


    this.ticketModelHelper = ticketHelper
    this.listNameString = priceList.name
    this.ticketTotalPrice = priceList.price
    this.lineString = priceList.idTrip

    if(priceList.subscription == "30"){
      this.visibleMonthList = true;
      let copy = this.getCopyOfOptions();
      copy.showWeekNumbers = false;
      copy.disableWeekdays = []
      this.dateFrom = copy;
      this.visiblePickerDate = false;
      
    }
    if(priceList.subscription == "365") {
      this.visiblePickerDate = true;
      this.visibleMonthList = false;
      let copy = this.getCopyOfOptions();
      copy.showWeekNumbers = false;
      copy.disableWeekdays = []
      this.dateFrom = copy;
      
      
    }
    if(priceList.subscription == "7"){
      this.visiblePickerDate = true;
      this.visibleMonthList = false;
      let copy = this.getCopyOfOptions();
      copy.showWeekNumbers = true;
      copy.disableWeekdays = ['tu', 'we', 'th', 'tu', 'fr', 'sa', 'su']
      this.dateFrom = copy;
    }
  }



  getCopyOfOptions() {
    return JSON.parse(JSON.stringify(this.dateFrom));
  }

  onChangeMonth(valueMonth){
    var stringTest = valueMonth.split(":")[1];
    var stringValueMonth = stringTest.substring(1);

    this.stringDataLine = stringValueMonth;
  }


  onChangeYear(valueYear){
    var stringTest = valueYear.split(":")[1];
    var stringValueYear = stringTest.substring(1);

    this.stringDataLineYear = stringValueYear;
  }

  async getFareList() {
    this.fareList = await this.listService.manageListValues(this.pathUrlFareList, 'Tariffe');
  }

  async getPrinterList() {
    this.printerList = await this.listService.manageListValues(this.pathUrlPrinterList, 'Stampanti');
    this.retrievePcUUID(this.printerList);
  }

  async getPriceList() {
    this.dataPriceList = await this.listService.manageListValues(this.pathUrlPriceList, 'Listini');
  }

  getSerialNumber(id) {

    this.serialNumber = null
    var stringTest = id.split(":")[1];
    var stringtrip = stringTest.substring(1);
    var idPrinter = stringtrip;
    for (let p of this.printerList) {
      if (p.id == idPrinter) {
        this.serialNumber = p.serialNumber;
      }
    }
  }

  onChangeFare(idFare) {
   this.clearAll();

   var stringTest = idFare.split(":")[1];
   var stringidFare = stringTest.substring(1);

   this.fareNameString = this.soldHelper.getfareName(stringidFare, this.fareList );


    if (
      this.salesNewSubscriptionForm.value.list != null &&
      this.salesNewSubscriptionForm.value.list != ""
    ) {
      this.salesNewSubscriptionForm.value.list = " ";
      this.salesNewSubscriptionForm.controls["list"].reset();
    }

    this.filterPriceList = [];

    var stringFare = idFare.substring(3);

    for (let p of this.dataPriceList) {
      if (p.idTicketFare == stringFare && p.tripMaxDay != null) {
        this.filterPriceList.push(p);
      }
    }
    if (this.dataPriceList.length > 0) {
      this.visiblePriceList = true;
    } else {
      this.visiblePriceList = false;
    }
  }

  changeIdNumber(value){
    this.idCardString = value
  }


  onDateChanged(date){
    this.stringDataLine = date
  }

  clearAll() {
    this.visiblePriceList = false;
    this.visibleMonthList = false;
    this.visiblePickerDate = false;
    this.listNameString = null;
    this.chooseList = false;
    this.ticketTotalPrice = null;
    this.fareNameString = null;
    this.lineString = null
    this.stringDataLine = null;
    this.ticketModelHelper = null;

  }


  completeSold(){
    var ticket = new Ticket()

    var arrayList: any[] = new Array();

    if(this.serialNumber == undefined || this.serialNumber == null){
      //CONTROLLO POI ELIMINO
      localStorage.setItem("PDF", "printPDF");
    }else{
      localStorage.setItem("PDF", "noPDF");
    }

    ticket.idCard = this.salesNewSubscriptionForm.value.idCard
    ticket.tripMaxPeriod = this.ticketModelHelper.tripMaxPeriod
    ticket.tripMaxDay = this.ticketModelHelper.tripMaxDay
    ticket.subscription =  this.ticketModelHelper.subscription
    ticket.idCompany = this.ticketModelHelper.idCompany
    ticket.idTicketPricelist = this.ticketModelHelper.idPriceList
    ticket.tolerance = 0
    
    var date;   
    if(this.salesNewSubscriptionForm.value.date == null || this.salesNewSubscriptionForm.value.date == undefined )
    {
      date =  this.utils.stringToServerFormat( this.salesNewSubscriptionForm.value.month +this.salesNewSubscriptionForm.value.year);
    }else{
      date =  this.utils.convertDateToISO( this.salesNewSubscriptionForm.value.date);
    }

    ticket.usableFrom = date  +  " 00:00:00"
    ticket.printerSerialNumber = this.serialNumber
    ticket.price = this.ticketModelHelper.price
    ticket.idTicketChannel = this.soldChannel
    arrayList.push(ticket)

    var ticketToSend = new TicketCreate();
    ticketToSend.tickets = arrayList;

    if(this.serialNumber != null || this.serialNumber != undefined)
      this.loadingService.presentLoader(LoadingLabels.TicketPrint);

    this.listService.newObject(ticketToSend, this.pathCompleteSell).subscribe(
      response => {
        if (response.outcome.success === true) {
          //   this.dataResponseTickets = response.results

          var count = 0;

          for (let t of response.results) {
            if (t.outcome.success == true) {
              this.toastr.success('Biglietto correttamente stampato', 'Vendita completata');
              this.dataResponseTickets.push(t);
            }
            if (t.outcome.code == "0019") {
              count = count + 1;
              this.toastr.error(
                "",
                "Il biglietto non è stato stampato perchè la stampante non è pronta per la stampa"
              );
              this.dataResponseTickets.push(t);
            }
            if (t.outcome.code == "0018") {
              count = count + 1;
              this.toastr.error(
                "",
                "Il biglietto non è stato stampato perchè la stampante non è raggiungibile"
              );
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0020") {
              count = count + 1;
              this.toastr.error("", "Il biglietto non è stato stampato");
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0021") {
              count = count + 1;
            this.toastr.error(
              "",
              "Il biglietto non è stato stampato, perche non è stata individuata la stampante"
            );
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0022") {
              count = count + 1;
            this.toastr.error(
              "",
              "Il biglietto non è stato stampato, perche la stampante non è raggiungibile"
            );
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0023") {
              count = count + 1;
              this.toastr.error("", "Il biglietto non è stato stampato");
              this.dataResponseTickets.push(t);
            }

            if (t.outcome.code == "0024" || t.outcome.code == "0025") {
              count = count + 1;
            this.toastr.error("", "Errore nella stampa del biglietto");
              this.dataResponseTickets.push(t);
            }

            if(count == 0 && (this.serialNumber == null || this.serialNumber == undefined)) {
              swal2.fire("Il pdf è pronto per essere stampato", "", "success");
            }

            if(count == 0 && (this.serialNumber != null || this.serialNumber != undefined)) {
              swal2.fire("La stampa è avvenuta con successo", "", "success");
            } 
            if(count > 0 ) {
              swal2.fire(
                "Attenzione",
                "Alcuni biglietti potrebbero non essere stati stampati",
                "warning"
              );
            }
          }

          //PASSARE AL SOMMARIO IN QUESTO PUNTO PASSANDOGLI L'ARRAY RICEVUTO DAL SERVER
          localStorage.setItem(
            "dataResponseTicket",
            JSON.stringify(this.dataResponseTickets)
          );
          this.router.navigate(["/summarySale"]);
        } else {
          if (response.outcome.code == "0013") {
            this.errorMessage =
              "Il credito all'interno del wallet non è sufficiente per completare l'acquisto";
          } else if (response.outcome.code == "0015") {
            this.errorMessage =
              "Il credito all'interno del wallet non è sufficiente per completare l'acquisto";
          }else if (response.outcome.code == "0014") {
            this.errorMessage =
              "Il credito all'interno del wallet non è sufficiente per completare l'acquisto";
          }else {
            this.errorMessage = "Generic Error";
          }

          swal2.fire("Attenzione", this.errorMessage, "warning");
        }
      },
      error => {
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        swal2.fire(
          "Errore",
          "Spiacente, si è verificato un errore tecnico.",
          "error"
        );
      }
    );

  }

}



export class TicketCreate {
  tickets: any[] = Array();
  constructor() {}
}




export class TicketModel {
  ar;
  rate;
  amount;
  totalPrice;
  idPriceList;
  tolerance;
  price;
  idCompany;
  typeTicketString;
  listNameString;
  hour;
  idTrip;
  date;
  dateReturn;
  hourReturn;
  directionIdR;
  directionR;
  idTripR;
  rateId;
  direction;
  directionId;
  line;
  lineID;
  idCard;
  tripMaxPeriod;
  tripMaxDay;
  subscription;
  constructor() {}
}

export class Ticket {
  id;
  idCompany;
  idLinkedTicket;
  idTicketPricelist;
  idTicketChannel;
  idTrip;
  usableFrom;
  usableTo;
  tolerance;
  price;
  printerSerialNumber;
  idCard;
  tripMaxPeriod;
  tripMaxDay;
  subscription;

  
  constructor() {}
}