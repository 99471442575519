import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import swal2 from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import { UtilsService } from '../../../services/utils.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-sales-summary',
  templateUrl: './sales-summary.component.html',
  styleUrls: ['../../../app.component.css']
})
export class SalesSummaryComponent implements OnInit {
  errorMessage;
  arrayTicket: any[] = new Array();

  arrayTicketPrinted: any[] = new Array();
  arrayTicketError: any[] = new Array();
  arrayTicketToConfirm: any[] = new Array();
  checkPDF;
  private checkTemplatePDF = '';

  modalRef: BsModalRef;

  pathConfirmedPayment;
  pathPdfPrint;
  paymentTypes;

  constructor(
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    public utils: UtilsService,
    public cookieService: CookieService,
    public toastr: ToastrService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.pathConfirmedPayment = '/ticket/sellconfirm';
    this.pathPdfPrint = '/ticket/sellprint';

    if (localStorage.getItem('dataResponseTicket') === '') {
      this.router.navigate([this.listService.listRouting]);
      return;
    }
    this.arrayTicket = JSON.parse(localStorage.getItem('dataResponseTicket'));

    localStorage.setItem('dataResponseTicket', '');

    this.printedControll(this.arrayTicket);
    this. checkPDF = localStorage.getItem('PDF');
    this.checkTemplatePDF = localStorage.getItem('templatePDF');
    localStorage.removeItem('templatePDF');

    localStorage.setItem('PDF', '');

    this.getPaymentTypes();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  print(tickets) {
    // const tickets = this.createArrayTicketToPrint(this.arrayTicket);

    this.closeModal()
    this.utils.getPdfFromTickets(tickets, 'La vendita è stata confermata', this.listService.listRouting);
  }

  printedControll(arrayTicket) {
    for (let t of arrayTicket) {
      if (t.outcome.success === true) {
        this.arrayTicketPrinted.push(t);
      } else {
        this.arrayTicketError.push(t);
      }
    }
  }

  deleteAll() {}

  reprint() {}

  testClose() {
    this.closeModal()
  }

  confirmSold(payType) {
   //  const tickets = this.createArrayTicketToConfirm(this.arrayTicket);

    const tickets = this.createArrayTicketToConfirmWithPayment(this.arrayTicket, payType);
    this.listService.edit(tickets, this.pathConfirmedPayment).subscribe(
      response => {
        if (response.outcome.success === true) {
          if (this.checkPDF === 'printPDF') {
            this.print(tickets)
          } else {
            this.router.navigate([this.listService.listRouting]);
            this.closeModal()
            swal2.fire('Il processo di vendita è stato completato', '', 'success');
          }

        } else {
          this.errorMessage =
            response.outcome.code + response.outcome.description;
          swal2.fire('Attenzione', this.errorMessage, 'warning');
        }
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
        swal2.fire(
          'Errore',
          'Spiacente, si è verificato un errore tecnico.',
          'error'
        );
      }
    );
  }

  // createModel to send to server with printed ticket
  createArrayTicketToConfirm(array) {
    for (let t of array) {
      if (t.outcome.success === true) {
        const ticketToConfirm = new TicketToConfirm();
        ticketToConfirm.codeEncrypted = t.codeEncrypted;
        ticketToConfirm.idTicket = t.idTicket;
        this.arrayTicketToConfirm.push(ticketToConfirm);
      }
    }
    const ticketConfirmed = new TicketConfirmed();
    ticketConfirmed.tickets = this.arrayTicketToConfirm;
    ticketConfirmed.total = this.arrayTicketToConfirm.length;
    ticketConfirmed.operationCode = 1;

    return ticketConfirmed;
  }

  createArrayTicketToConfirmWithPayment(array, payType) {
    for (let t of array) {
      if (t.outcome.success === true) {
        const ticketToConfirm = new TicketToConfirm();
        ticketToConfirm.codeEncrypted = t.codeEncrypted;
        ticketToConfirm.idTicket = t.idTicket;
        this.arrayTicketToConfirm.push(ticketToConfirm);
      }
    }
    const ticketConfirmed = new TicketConfirmed();
    ticketConfirmed.tickets = this.arrayTicketToConfirm;
    ticketConfirmed.total = this.arrayTicketToConfirm.length;
    ticketConfirmed.operationCode = 1;
    ticketConfirmed.paymentType = payType;

    if ( this.checkTemplatePDF ) {
      ticketConfirmed['template'] = this.checkTemplatePDF;
    }

    return ticketConfirmed;
  }

  createArrayTicketToPrint(array) {
    for (let t of array) {
      if (t.outcome.success === true) {
        const ticketToConfirm = new TicketToConfirm();
        ticketToConfirm.codeEncrypted = t.codeEncrypted;
        ticketToConfirm.idTicket = t.idTicket;
        this.arrayTicketToConfirm.push(ticketToConfirm);
      }
    }
    const ticketConfirmed = new TicketConfirmed();
    ticketConfirmed.tickets = this.arrayTicketToConfirm;
    ticketConfirmed.total = this.arrayTicketToConfirm.length;

    return ticketConfirmed;
  }

  // Creation model to send to server to delete the transaction
  createArrayTicketToDelete(array) {
    for (let t of array) {
      const ticketToConfirm = new TicketToConfirm();
      ticketToConfirm.codeEncrypted = t.codeEncrypted;
      ticketToConfirm.idTicket = t.idTicket;
      this.arrayTicketToConfirm.push(ticketToConfirm);
    }
    const ticketConfirmed = new TicketConfirmed();
    ticketConfirmed.tickets = this.arrayTicketToConfirm;
    ticketConfirmed.total = this.arrayTicketToConfirm.length;

    // Verificate
    ticketConfirmed.operationCode = 0;

    return ticketConfirmed;
  }

  deleteSold() {
    const tickets = this.createArrayTicketToDelete(this.arrayTicket);
    this.listService.edit(tickets, this.pathConfirmedPayment).subscribe(
      response => {
        if (response.outcome.success === true) {
          swal2.fire('I biglietti sono stati Annullati', '', 'success');

          this.router.navigate([this.listService.listRouting]);
        } else {
          this.errorMessage =
            response.outcome.code + response.outcome.description;
          swal2.fire('Attenzione', this.errorMessage, 'warning');
        }
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
        swal2.fire(
          'Errore',
          'Spiacente, si è verificato un errore tecnico.',
          'error'
        );
      }
    );
  }

  triggerModal(template: TemplateRef<any>) {
    this.openModal(template)
  }

  closeModal() {
    this.modalRef.hide()
  }

  getPaymentTypes() {
    this.utils.paymentTypes().subscribe({
      next: (response) => {
        this.paymentTypes = response;
      },
      error: (error) => {
        this.logger.log('Error', error, 200);
      }
    });
  }

}

export class TicketModel {
  ar;
  rate;
  amount;
  totalPrice;
  idPriceList;
  tolerance;
  price;
  idCompany;
  typeTicketString;
  listNameString;
  hour;
  idTrip;
  date;
  dateReturn;
  hourReturn;
  directionIdR;
  directionR;
  idTripR;
  rateId;
  direction;
  directionId;
  line;
  lineID;
  constructor() {}
}

export class Ticket {
  id;
  idCompany;
  idLinkedTicket;
  idTicketPricelist;
  idTicketChannel;
  idTrip;
  usableFrom;
  usableTo;
  tolerance;
  price;
  printerSerialNumber;
  constructor() {}
}

export class TicketCreate {
  tickets: any[] = Array();
  constructor() {}
}

export class TicketConfirmed {
  tickets: any[] = Array();
  total;
  operationCode;
  paymentType;
  constructor() {}
}

export class TicketToConfirm {
  idTicket;
  codeEncrypted;
  constructor() {}
}
