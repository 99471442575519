import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogViewerComponent } from '../dialog/dialog-viewer.component';
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import swal2, { SweetAlertResult } from 'sweetalert2'
import { DocumentsViewerComponent } from "../documents-viewer/documents-viewer.component";
import { LoadingLabels } from '../../../enums/loading.enum';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-documentsList',
  templateUrl: './documents-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class DocumentsListComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private logger: LoggingService,
    private router: Router,
    public listService: ListService,
    private loadingService: LoadingService
  ) {
    this.listService.listRouting = "/orders/list";
    this.listService.paging = true; // server pagination
    this.listService.page = 1;
  }

  id: number;
  private sub: any;
  noChecklist = false;

  errorMessage: string = "";
  documents: any[] = new Array();
  isSupplier;
  requestValidationPath;
  approveValidationPath;
  rejectValidationPath;
  nameOrder;
  idDocToConfirm;
  statusDocToConfirm;
  viewMask: boolean = true;
  viewDoc: boolean = false;
  statusOrder;
  sendMailReportPath;

  @ViewChild('viewerDiv') viewerDiv: DocumentsViewerComponent;
  @ViewChild('dialogView') dialogView: DialogViewerComponent;

  ngOnInit() {
    this.requestValidationPath = "/checklist/submit/";
    this.approveValidationPath = "/approval/checklist/approve/"
    this.sendMailReportPath = "/approval/checklist/send/"
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
    });
    this.rejectValidationPath = "/approval/checklist/reject"
    this.listService.restURL = "/order/" + this.id + "/checklists"
    this.getDocs();

    this.statusOrder = localStorage.getItem("statusOrder");


    if (localStorage.getItem("role") === "SUPPLIER") {
      this.isSupplier = true
    } else {
      this.isSupplier = false
    }
  }

  pageChange(page) {
    this.listService.page = page;
    this.getDocs();

  }
  getDocs() {
    this.listService.getGenericFilterResult(null, null, null, null, null, null).subscribe(
      (response) => {

        // Verificare outcome

        this.documents = response.results;
        this.listService.totalRows = response.total;
        this.logger.log("Response:", response, 300);
        this.errorMessage = "";
        this.noChecklist = (this.documents.length == 0);

      },
      (error) => {
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";


      }
    );
  }

  selectRow(idChecklist, year, month, status) {
    this.listService.selectedID = idChecklist;
    this.listService.backToList = true;

    localStorage.setItem("idOrderToPass", String(this.id));
    localStorage.setItem("yearToPass", String(year));
    localStorage.setItem("monthToPass", String(month));
    localStorage.setItem("statusChecklist", status)
    this.router.navigate(['/orders/checklists/details/' + idChecklist]);
    //alert("Selected checklist: " + idChecklist);
  }

  newOrder() {
    this.router.navigate(['/orders/new']);
  }

  newDocument() {
    this.router.navigate(['/documents/new/doc']);
  }

  requestValidationDocuments(idOrder) {
    this.loadingService.presentLoader(LoadingLabels.Validation);

    this.listService.edit(null, this.requestValidationPath + idOrder).subscribe((response) => {
      if (response.outcome.success === true) {
        swal2.fire("Validazione inviata", "La richiesta di validazione è stata inviata con successo ", "success");
        this.getDocs();

      } else {
        swal2.fire("Attenzione", "Verifica di aver caricato tutti i documenti", "warning");
        this.logger.log("Response", response, 200);
        //alert(response);
      }
    },
      (error) => {
        swal2.fire("Errore", "Errore durante la validazione. Riprova più tardi.", "error");
        // this.logger.log("Error",error,200);
      }
    );
  }


  ApproveValidationDocuments(idCheckList) {

    this.loadingService.presentLoader(LoadingLabels.Validation);
    var mySelf = this;
    this.listService.edit(null, this.approveValidationPath + idCheckList).subscribe((response) => {
      if (response.outcome.success === true) {
        swal2.fire("Validazione approvata", "La richiesta di validazione è stata approvata con successo ", "success");

        this.getDocs();

        mySelf.sendMailReport(idCheckList)

      } else {
        this.logger.log("Response", response, 200);
        swal2.fire(response.outcome.message);
      }
    }
    );
  }





  sendMailReport(idCheckList) {
    var mySelf = this;
    swal2.fire({
      title: 'Invio report',
      text: "Desideri inviare il report al fornitore e agli approvatori?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, invia',
      cancelButtonText: 'No',
    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.sendMail(idCheckList);
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })
  }



  sendMail(idCheckList) {
    this.loadingService.presentLoader(LoadingLabels.Default);

    this.listService.getObject(this.sendMailReportPath + idCheckList).subscribe((response) => {
      if (response.outcome.success === true) {
        swal2.fire("Mail Spedita", "Il report relativo all'ordine è stato inviato al fornitore e agli approvatori", "success");
        this.getDocs();
      } else {
        this.logger.log("Response", response, 200);
        swal2.fire(response.outcome.message);
      }
    },
      (error) => {
        // this.logger.log("Error",error,200);
      }
    );
  }


  modalOpen(idDoc, status) {
    this.dialogView.statusDocToConfirm = status;
    this.dialogView.idDocToConfirm = idDoc;
    this.dialogView.kindDocument = "checklist"
  }

  closeDocViewer() {
    this.viewMask = true;
  }





  conditionallyApprove(idCheckList, status) {
    let mySelf = this;
    swal2.fire({
      title: 'Approva documento con riserva',
      text: 'Aggiungi una motivazione',
      input: 'textarea',
      inputPlaceholder: 'Scrivi la motivazione',
      showCloseButton: false,
      showCancelButton: false,
      confirmButtonText: 'Conferma',
      confirmButtonColor: '#ed5565',
    }).then(function (text) {
      if (text) {
        mySelf.revisionCheckList(idCheckList, text, status)
      } else {
        swal2.fire('Motivazione non inserita', 'Inserire una motivazione valida', 'error');
      }
    }, function (dismiss) {
      if (dismiss === 'close') {
        swal2.close();
      }
    });

  }






  rejectSwal(idCheckList, status) {
    let mySelf = this;
    swal2.fire({
      title: 'Respingi documento',
      text: 'Aggiungi una motivazione',
      input: 'textarea',
      inputPlaceholder: 'Scrivi la motivazione',
      showCloseButton: false,
      showCancelButton: false,
      confirmButtonText: 'Conferma',
      confirmButtonColor: '#ed5565',
    }).then(function (text) {
      if (text) {
        mySelf.revisionCheckList(idCheckList, text, status)
      } else {
        swal2.fire('Motivazione non inserita', 'Inserire una motivazione valida', 'error');
      }
    }, function (dismiss) {
      if (dismiss === 'close') {
        swal2.close();
      }
    });

  }






  revisionCheckList(idCheckList, msgNote, status) {

    this.loadingService.presentLoader(LoadingLabels.Validation);
    let mySelf = this;
    this.listService.revision(idCheckList, msgNote, status, "checklist").subscribe(
      (response) => {

        if (response.outcome.success === true) {
          swal2.fire("Operazione confermata", "Status aggiornato con successo", "success").then(function (ev: SweetAlertResult) {
            if (ev.value) {
              mySelf.getDocs();
            }
          })
          //TO DO: CHIUDERE SCHERMATA?
        } else {
          swal2.fire("Errore", "Errore durante l'operazione. Riprova più tardi.", "error");
          this.logger.log("Response", response, 200);
        }
      },
      (error) => {
        this.logger.log("Error", error, 200);

      }
    );

  }
}
