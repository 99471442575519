import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class InterceptedHttp implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.getRequestOptionArgs(req));
  }

  getRequestOptionArgs(request: HttpRequest<any>): any {
    if (!request.headers) {
      return request.clone({...request, headers: new HttpHeaders()});
    } else {
      return request.clone({
        setHeaders: {
          'X-Auth-Token': localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }
      });
    }
  }

}
