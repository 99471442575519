import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ListService } from '../../../services/list.service';
import { LoggingService } from '../../../services/logging.service';
import { Router } from '@angular/router';
import { ICompany, IExternal } from '../../../interfaces/configuration.interface';
import swal2, { SweetAlertResult } from 'sweetalert2';
import { LoadingLabels } from '../../../enums/loading.enum';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-external-list',
  templateUrl: './external-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class ExternalListComponent implements OnInit {

  @ViewChild('formExternalKeys') externalKeysForm: NgForm;

  //OGGETTO LISTA ELEMENTI & FILTRI
  dataList: IExternal[] = new Array();
  dataFilter: any[] = new Array();
  companiesList: ICompany[] = new Array();

  //PATH CHIAMATE
  urlCompany = '/company/companies';
  urlExternal = '/external/externals';

  errorMessage: string = '';

  constructor(
    public listService: ListService,
    private logger: LoggingService,
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.listService.resetList();
    this.listService.restURL = this.urlExternal;
    this.listService.configurationServiceCall("/utility/external/list", false, true, 1, 12);
  }

  ngOnInit(): void {
    this.listService.visibleFilter = false;
    this.getCompanies();
    this.loadElements();
  }

  filterSearch(): void {
    this.dataFilter = this.externalKeysForm.value;
    this.listService.visibleFilter = true;
    this.loadElements();
  }

  filterReset(): void {
    this.externalKeysForm.value.idCompany = 'all';
    this.externalKeysForm.value.code = null;
    this.externalKeysForm.value.status = null;
    this.externalKeysForm.reset();
    this.listService.visibleFilter = false;
    this.loadElements();
  }

  newExternal(): void {
    this.listService.backToList = true;
    this.router.navigate(['/utility/external/new']);
  }

  selectRow(external: IExternal): void {
    this.listService.backToList = true;
    localStorage.setItem('dataPassed', JSON.stringify(external));
    this.router.navigate(['/utility/external/edit']);
  }

  deleteExternal(externalId: number): void {
    var mySelf = this;
    
    swal2.fire({
      title: 'Disattivazione chiave esterna',
      text: "Sei sicuro di voler disattivare la chiave esterna?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((ev: SweetAlertResult) => {
      if (ev.value) {
        mySelf.deleteElement(externalId);
      }
    }, (dismiss) => {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    });
  }

  /** Method that populates company column getting the value from the list */
  getBusinessName(id: number): string {
    for (let company of this.companiesList) {
      if (company.idCompany == id) {
        return company.legalBusinessName
      }
    }
  }

  /** Method that populates company column getting the value from the list */
  getConfigurationCompany(idCompany: number): string {
    return this.companiesList.find(c => c.idCompany === idCompany)
      ? this.companiesList.find(c => c.idCompany === idCompany).legalBusinessName
      : '';
  }

  // --------- PRIVATE METODS --------- //

  private getCompanies() {
    this.listService.getListSelect(this.urlCompany).subscribe({
      next: (response) => {
        this.companiesList = response.results;
        this.logger.log('Lista aziende', this.companiesList, 300);
      },
      error: (error) => {
        this.logger.log('Error', error, 200);
      }
    });
  }

  //SERVIZIO LISTA ELEMENTI
  private loadElements(): void {
    this.listService.getListFilter(this.dataFilter).subscribe(
      (response) => {
        this.dataList = response.results;
        this.listService.totalRows = response.total;
        this.logger.log("Response: ", response, 300);
        this.errorMessage = "";
      },    
      (error) => { 
        this.logger.log("Error: ", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
      }
    );
  }

  private deleteElement(externalId: number) {
    const urlDeletePath =  `/external/${externalId}`

    this.loadingService.presentLoader(LoadingLabels.Deactivation);
    
    this.listService.delete(urlDeletePath).subscribe({
      next: (response) => {
        if (response.outcome.success === true) {
          swal2.fire("Disattivazione completata", "La chiave esterna è stata disattivata con successo", "success").then((ev: SweetAlertResult) => {
            if (ev.value) {
              this.loadElements();
            }
          });
        } else {   
          this.errorMessage =  response.outcome.code +  response.outcome.description
          swal2.fire("Attenzione", this.errorMessage,"warning");
        }
      },
      error: (error) => {
        this.logger.log("Error",error,200);
        swal2.fire("Errore","Spiacente, si è verificato un errore tecnico.Riprova più tardi","error");
      }
    });
  }

}
