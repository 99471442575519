import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ListService } from '../../../../services/list.service';
import { LoggingService } from '../../../../services/logging.service';
import { Router } from '@angular/router';
import { ICompany, IPass } from '../../../../interfaces/configuration.interface';
import swal2, { SweetAlertResult } from 'sweetalert2';
import { LoadingLabels } from '../../../../enums/loading.enum';
import { LoadingService } from '../../../../services/loading.service';

@Component({
  selector: 'app-users-passes',
  templateUrl: './users-passes-list.component.html',
  styleUrls: ['../../../../app.component.css']
})
export class UsersPassesComponent implements OnInit {

  @ViewChild('passesForm') passesForm: NgForm;

  roleUser: string;
  typeUser: boolean;
  isSoluzione1: boolean;
  isCompanyAdminstrator: boolean;
  visiblePaging: boolean;
  showFilters: boolean;
  modalRef: BsModalRef;

  companiesList: ICompany[] = [];
  dataList: IPass[] = [];
  dataFilter: any[] = [];
  errorMessage: string;
  uploadedFile: File;

  pathToCallCompaniesPath = '/company/companies';
  pathToUploadCsv = '/pass/uploadCSV'

  constructor(
    public listService: ListService,
    private utils: UtilsService,
    private modalService: BsModalService,
    private logger: LoggingService,
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.listService.resetList();
    this.listService.configurationServiceCall("/users/passes", false, true, 1, 10);
    this.listService.restURL = '/pass';
    this.roleUser = this.utils.getRoleUser();
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
    this.isCompanyAdminstrator = this.utils.viewAdministratorCompany(this.typeUser, this.roleUser);
  }

  ngOnInit(): void {
    this.getCompanies();
    if (this.isCompanyAdminstrator) {
      this.loadElements();
    }
  }

  pageChange(page: number): void {
    this.listService.page = page;
    this.loadElements();
  }

  newPass(): void {
    if (this.passesForm.value.idCompany !== 'all') {
      this.listService.selectedCompany = this.passesForm.value.idCompany;
    }
    this.listService.backToList = true;
    this.router.navigate(['/users/passes/new']);
  }

  onCompanySelect(): void {
    this.dataList = [];
    this.listService.visibleFilter = false;

    if (this.passesForm.value.idCompany === 'all') {
      this.showFilters = false;
    } else {
      this.showFilters = true;
      this.filterSearch(true);
    }
  }

  openUploadModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

  handleFilesInput(file: File): void {
    this.uploadedFile = file;
  }

  uploadCSV(): void {
    this.loadingService.presentLoader(LoadingLabels.Default);

    const companyId = this.passesForm?.value.idCompany;
    const formData = new FormData();
    
    formData.append('file', this.uploadedFile, this.uploadedFile.name);
    if (this.isSoluzione1 && companyId) {
      formData.append('idCompany', companyId); 
    }

    this.listService.newObject(formData, this.pathToUploadCsv).subscribe((response) => {
      if (response.outcome.success) {
        this.modalRef.hide();
        swal2.fire('File tessere caricato correttamente', '', 'success');
        this.loadElements();
      } else {
        const errorMessage =  response.outcome.code +  response.outcome.description
        swal2.fire('Attenzione', errorMessage, 'warning');
      }
    }, _ => {
      swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.', 'error');
    });
  }

  filterSearch(fromLoad: boolean = false): void {
    this.dataFilter = this.passesForm.value;

    if (!fromLoad) {
      this.listService.visibleFilter = true;
    };

    this.loadElements();
  }

  filterReset(): void {
    this.passesForm.value.name = '';
    this.passesForm.value.code = '';
    this.passesForm.value.description = '';
    this.listService.visibleFilter = false;
    this.passesForm.reset();
    this.loadElements();
  }

  getCompanyName(idCompany: number): string {
    let stringCompanyName: string;
    for (let company of this.companiesList) {
      if (company.idCompany === idCompany) {
        stringCompanyName = company.legalBusinessName
        return stringCompanyName;
      }
    }
  }

  getButtonCircle(deleted: boolean): string {
    return deleted ? 'btn btn-danger btn-circle' : 'btn btn-primary btn-circle';
  }

  selectRow(dataElement: any): void {
    this.listService.backToList = true;
    localStorage.setItem('dataPassed', JSON.stringify(dataElement));
    this.router.navigate(['/users/passes/edit']);
  }

  deletePass(idPass: number): void {
    let mySelf = this;

    swal2.fire({
      title: 'Eliminazione tessera',
      text: 'Sei sicuro di voler eliminare la tessera?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        const urlDeletePath =  '/pass/' + idPass;
        mySelf.utils.deleteElement(urlDeletePath).then(_ => {
          swal2.fire('Eliminazione completata', 'Tessera eliminata con successo', 'success').then((ev: SweetAlertResult) => {
            if (ev.value) {
              mySelf.loadElements();
            }
          });
        }).catch(err => {
          mySelf.errorMessage = err;
          swal2.fire('Attenzione', mySelf.errorMessage, 'warning');
        });
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })
  }

  // ---------- PRIVATE METHODS ---------- //

  private getCompanies(): void {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe((response) => {
    this.companiesList = response.results;
    this.logger.log("Lista aziende", this.companiesList, 300);
    }, (error) => {
      this.logger.log("Error", error, 200);
    });
  }

  private loadElements(): void {
    this.dataFilter['notAssociated'] = null;
    this.listService.getListFilter(this.dataFilter).subscribe((response) => {
      this.dataList = response.results;
      this.listService.totalRows = response.total;

      this.visiblePaging = this.listService.totalRows > 10 ? true : false;

      this.logger.log('Response: ',response,300);
      this.errorMessage = '';
    }, (error) => {
      this.logger.log('Error', error, 200);
      this.errorMessage = 'Non è stato possibile recuperare la lista delle tessere, si prega di riprovare';
    });
  }

}
