import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ListService } from "../../../services/list.service";

@Component({
  selector: "app-companies-details",
  templateUrl: "./companies-details.component.html",
  styleUrls: ["../../../app.component.css"]
})
export class CompaniesDetailsComponent implements OnInit {
  constructor(
    private router: Router,
    public listService: ListService
  ) {
    this.listService.listRouting = "/companies/details";
  }

  idCompany;
  model = {
    idCompany: 1
  };
  errorMessage;
  pathUrlCompanyDetails;
  companyDetails: any = new Object();

  async ngOnInit() {
    this.idCompany = localStorage.getItem("idCompany");
    this.pathUrlCompanyDetails = "/dashboard/company";
    await this.getDashboard();
  }

  selectRow() {
    this.model.idCompany = this.idCompany;
    localStorage.setItem("dataPassed", JSON.stringify(this.model));
    this.listService.backToList = true;
    this.router.navigate(["/companies/edit"]);
  }

  async getDashboard() {
    this.companyDetails = await this.listService.manageListValues(this.pathUrlCompanyDetails,  'Oggetto Dashboard', false);
  }

  goToVehicles() {
    this.router.navigate(["/vehicles/list"]);
  }

  goToPrinters() {
    this.router.navigate(["/printers/list"]);
  }

  goToResale() {
    this.router.navigate(["/resales/list"]);
  }
  goToObliterators() {
    this.router.navigate(["/obliterators/list"]);
  }
}
