import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from "../../../services/list.service";
import { FileUploader, FileItem, FileUploaderOptions } from 'ng2-file-upload';
import { DocumentsViewerComponent } from '../documents-viewer/documents-viewer.component';
import { DialogViewerComponent } from '../dialog/dialog-viewer.component';
import { LoadingLabels } from '../../../enums/loading.enum';
import { LoadingService } from '../../../services/loading.service';
import swal2, { SweetAlertResult } from 'sweetalert2';

const URL = 'https://185.56.9.94/prs-rest/rest/document/uploadDocChecklist';
const AUTHTOKEN = localStorage.getItem("token");
const HEADER = 'X-AUTH-TOKEN';

@Component({
  selector: 'app-documents-checklist-details',
  templateUrl: './documents-checklist-details.component.html',
  styleUrls: ['../../../app.component.css']
})

export class DocumentsChecklistDetailsComponent implements OnInit {

  id: number;
  private sub: any;
  idSupplier;
  statusUpload;
  checklistItems: any[] = new Array();
  singleCheckItemList: any[] = new Array();
  msgNote: string;
  pathUrlCodeDoc;
  yearsDefaultValue;
  monthsDefaultValue;
  pathCheckListUrl;
  pathDoc;
  codeControlUpload;
  approveString;
  deniedString;
  isAppr;
  conditionallyString;
  rejectString;
  statusDocToConfirm;
  idDocToConfirm;
  uploadButtonVisible;
  visible;
  urlDeletePath;
  lastCodeSelected;
  office;
  monthOrder; yearOrder; nameOrder;
  statusCheckList;
  requestValidationPath;
  errorMessage;
  isAmm;
  statusOrder;


  //variabili per ViewPdf
  viewMask: boolean = true;
  viewDoc: boolean = false;

  @ViewChild('viewerDiv') viewerDiv: DocumentsViewerComponent;
  @ViewChild('dialogView') dialogView: DialogViewerComponent;
  @ViewChild('f') visualForm: NgForm;

  constructor(
    private logger: LoggingService,
    private route: ActivatedRoute,
    public listService: ListService,
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.listService.listRouting = "/orders/checklists/";
  }

  public uploader: FileUploaderCustom = new FileUploaderCustom({ url: URL, authTokenHeader: HEADER, authToken: AUTHTOKEN }, this);
  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }


  ngOnInit() {


    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
    });

    this.pathCheckListUrl = "/checklist/" + this.id + "/documents"
    this.idSupplier = localStorage.getItem("idSupplier");
    this.statusUpload = false;
    this.isApprover();
    this.isAmministrator();
    this.getCode();
    this.requestValidationPath = "/checklist/submit/"
    this.statusCheckList = localStorage.getItem("statusChecklist")
    this.uploader.authToken = localStorage.getItem("token");
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.yearsDefaultValue = 2017
    this.monthsDefaultValue = '01'
    this.pathDoc = "/document/";
    this.approveString = "approve";
    this.uploadButtonVisible = false;
    this.visible = false;
    this.lastCodeSelected = "toSelect"
    this.office = localStorage.getItem("idOffice");
    this.statusOrder = localStorage.getItem("statusOrder");

  }

  getCode() {
    this.listService.getList(this.pathCheckListUrl).subscribe((response) => {

      this.checklistItems = []
      var list = response.results;
      for (let d of list) {

        var count = 0;


        for (let doc of d.documents) {
          count++
          if (doc.codeDocumentType === "00" || doc.codeDocumentType === "01" || doc.codeDocumentType === "05") {

            if (this.statusCheckList === "APPROVED") {

            }



            else if (doc.status === "APPROVED" || doc.status === "CONDITIONALLY_APPROVED" || doc.status === "NEW") {

            } else {
              this.checklistItems.push(d);
            }

          }
          else if (count <= 1) {
            this.checklistItems.push(d);


          }

          else if (count > 2) {

          }
        }
      }
    });

  }


  requestValidationDocuments() {
    this.loadingService.presentLoader(LoadingLabels.Validation);
    this.listService.edit(null, this.requestValidationPath + this.id).subscribe((response) => {
      if (response.outcome.success === true) {
        swal2.fire("Validazione inviata", "La richiesta di validazione è stata inviata con successo ", "success");
        this.router.navigate(['orders/checklists/']);

      } else {
        swal2.fire("Attenzione", "Verifica di aver caricato tutti i documenti", "warning");
        this.logger.log("Response", response, 200);
      }
    },
      (error) => {
        this.logger.log("Error", error, 200);
        swal2.fire("Errore", "Errore durante la validazione. Riprova più tardi.", "error");
      }
    );
  }



  conditionallyApprove(idDocument, status) {
    let actualComponent = this;
    swal2.fire({
      title: 'Approvazione con riserva',
      text: 'Aggiungi una motivazione',
      input: 'textarea',
      inputPlaceholder: 'Scrivi una motivazione',
      showCloseButton: false,
      showCancelButton: false,
      confirmButtonText: 'Conferma',
      confirmButtonColor: '#ed5565',
    }).then(function (text) {
      if (text) {
        actualComponent.revisionDocument(idDocument, text, status)
      } else {
        swal2.fire('Motivazione non inserita', 'Inserire una motivazione valida', 'error');
      }
    }, function (dismiss) {
      if (dismiss === 'close') {
        swal2.close();
      }
    });


  }

  rejectSwal(idDocument, status) {
    let actualComponent = this;
    swal2.fire({
      title: 'Rifiuto documento',
      text: 'Aggiungi una motivazione',
      input: 'textarea',
      inputPlaceholder: 'Scrivi una motivazione',
      showCloseButton: false,
      showCancelButton: false,
      confirmButtonText: 'Conferma',
      confirmButtonColor: '#ed5565',
    }).then(function (text) {
      if (text) {
        actualComponent.revisionDocument(idDocument, text, status)
      } else {
        swal2.fire('Motivazione non inserita', 'Inserire una motivazione valida', 'error');
      }
    }, function (dismiss) {
      if (dismiss === 'close') {
        swal2.close();
      }
    });

  }

  revisionDocument(idDocument, msgNote, status) {
    let mySelf = this;
    this.listService.revision(idDocument, msgNote, status, "document").subscribe(
      (response) => {

        if (response.outcome.success === true) {
          swal2.fire("Operazione confermata", "Status aggiornato con successo", "success").then(function (ev: SweetAlertResult) {
            if (ev.value) {
              mySelf.getCode();
            }
          })
          //TO DO: CHIUDERE SCHERMATA?
        } else {
          swal2.fire("Errore", "Errore durante l'operazione. Riprova più tardi.", "error");
          this.logger.log("Response", response, 200);
        }
      },
      (error) => {
        this.logger.log("Error", error, 200);

      }
    );
  }

  multipleUpload(code) {

    this.loadingService.presentLoader(LoadingLabels.Upload);

    this.uploader.options.additionalParameter = {
      codeDocumentType: code,
      year: localStorage.getItem("yearToPass"),
      month: localStorage.getItem("monthToPass"),
      idOrder: localStorage.getItem("idOrderToPass"),
      dtReleased: "",
      dtValidityStart: "",
      dtValidityEnd: ""
    }
    this.uploader.uploadAllFiles(this);

  }


  deleteDocument(idDocument) {
    this.urlDeletePath = "/document/" + idDocument;
    let mySelf = this;
    swal2.fire({
      title: 'Eliminazione file',
      text: "Sei sicuro di voler eliminare il file?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.callServiceDeleteDocument();
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })

  }

  callServiceDeleteDocument() {
    this.listService.delete(this.urlDeletePath).subscribe(
      (response) => {

        if (response.outcome.success === true) {
          swal2.fire("Eliminazione completata", "Il documento è stato eliminato con successo", "success");
          this.getCode();

        } else {
          this.logger.log("Response", response, 200);
        }
      },
      (error) => {
        this.logger.log("Error", error, 200);

      }
    );
  }


  Upload(item, code) {

    this.uploader.options.additionalParameter = {
      codeDocumentType: code,
      dtReleased: null,
      dtValidityStart: null,
      dtValidityEnd: null
    }

    item.upload();
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      var res = JSON.parse(response);

      if (res.outcome.success) {
        this.statusUpload = true;
        item.remove();
        this.getCode()
        swal2.fire("Caricamento file", "Il file è stato caricato correttamente", "success")

      }
      else {
        var msgTxt;
        if (res.results[0].outcome == "0013")
          msgTxt = "Un file con questo nome è già presente";
        else
          msgTxt = "Non è stato possibile completare l'operazione. Riprova più tardi.";

        swal2.fire("Errore", msgTxt, "error");
      }

    }

  }


  test(code) {

    this.codeControlUpload = code;
    if (this.lastCodeSelected !== this.codeControlUpload && this.lastCodeSelected !== "toSelect") {
      this.uploader.queue = this.uploader.queue.slice(-1)
    }
    this.uploadButtonVisible = true;
    this.lastCodeSelected = code;

  }

  removeFromList(item) {
    item.remove();
    this.uploader.queue.splice(item)
    this.codeControlUpload = null
    this.getCode();
  }

  isApprover() {
    if (localStorage.getItem("role") === "APPROVER") {
      this.isAppr = true
    } else {
      this.isAppr = false
    }
  }

  isAmministrator() {
    if (localStorage.getItem("role") === "ADMINISTRATOR") {
      this.isAmm = true;
    } else {
      this.isAmm = false;
    }
  }

  closeDocViewer() {
    this.viewMask = true;
  }

  download(idDoc, status: String, infos: String, docsOffice: String, administrator: boolean) {
    this.viewMask = false;

    if (localStorage.getItem("statusChecklist") === "APPROVED") status = "CHECKLIST_APPROVED"; //STATUS CHE NON ESISTE QUINDI QUANDO CONTROLLA NON VISUALIZZA I PULSANTI
    if (docsOffice != this.office && !administrator) status = "OFFICE_ROLE_DENIED";
    if (this.statusCheckList != "SUBMITTED") status = "NOT_SUBMITTED_YET";

  }

  downloadTemplate(filename: String) {
    this.viewMask = false;

  }

  modalOpen(idDoc, status) {
    this.dialogView.statusDocToConfirm = status;
    this.dialogView.idDocToConfirm = idDoc;
    this.dialogView.kindDocument = "document"
  }

  approvaRiservaRespingiDoc(idDoc, status) {
    this.dialogView.statusDocToConfirm = status;
    this.dialogView.idDocToConfirm = idDoc;
    var mySelf = this;
    var titleBox;
    if (status == 'reject') titleBox = 'Rifiuta documento';
    else titleBox = 'Approva documento con riserva';

    swal2.fire({
      title: titleBox,
      text: 'Aggiungi una motivazione',
      input: 'textarea',
      inputPlaceholder: 'Scrivi la motivazione',
      showCloseButton: false,
      showCancelButton: false,
      confirmButtonText: 'Conferma',
      confirmButtonColor: '#ed5565',
    }).then(function (text) {
      if (text) {
        mySelf.approveReservedOrReject(idDoc, text, status);
      } else {
        swal2.fire('Motivazione non inserita', 'Inserire una motivazione valida', 'error');
      }
    }, function (dismiss) {
      if (dismiss === 'close') {
        swal2.close();
      }
    });

  }


  approveDocumentFromViewer(idDoc) {
    this.approve(idDoc);
  }





  approveReservedOrReject(idDoc, msgNote, status) {
    this.listService.revision(idDoc, msgNote, status, "document").subscribe(
      (response) => {

        if (response.outcome.success === true) {
          this.getCode();
          this.closeDocViewer();
          //TO DO: CHIUDERE SCHERMATA?
        } else {
          if (response.outcome.code === "0012") {
            this.errorMessage = "Non hai il permesso di operare sul documento";
            swal2.fire("Attenzione", this.errorMessage, "warning");
          } else
            swal2.fire("Errore", "Il documento non è stato approvato o respinto. Riprova più tardi.", "error");
        }
      },
      (error) => {
        //this.logger.log(error);

      }
    );
  }

  approve(idDoc) {

    this.listService.approve(idDoc).subscribe(
      (response) => {

        if (response.outcome.success === true) {
          swal2.fire("Approvazione confermata", "Documento approvato con successo", "success")
            .then((ev) => {
              if (ev.value) {
                this.getCode();
                this.codeControlUpload = null;
                this.closeDocViewer();
              }
            });
        } else {
          swal2.fire("Errore", "Il documento non è stato approvato. Riprova più tardi.", "error");
          this.logger.log("Response", response, 200);
        }
      },
      (error) => {
        this.logger.log("Error", error, 200);

      }
    );

  }

  isStatus(nameStatus) {

    return;
  }

}


export class FileUploaderCustom extends FileUploader {

  docComponent: DocumentsChecklistDetailsComponent;

  constructor(options: FileUploaderOptions, doc: DocumentsChecklistDetailsComponent) {
    super(options);
    this.docComponent = doc
  }

  uploadAllFiles(scope: any): any {

    scope.presentLoader(LoadingLabels.Default);

    var xhr = new XMLHttpRequest();
    var sendable = new FormData();
    var fakeitem: FileItem = null;
    this.onBuildItemForm(fakeitem, sendable);
    var response: any

    for (const item of this.queue) {
      item.isReady = true;
      item.isUploading = true;
      item.isUploaded = false;
      item.isSuccess = true;
      item.isCancel = false;
      item.isError = false;
      item.progress = 0;
      item.withCredentials = false;

      if (typeof item._file.size !== 'number') {
        throw new TypeError('The file specified is no longer valid');
      }
      sendable.append("files", item._file, item.file.name);
    }

    if (this.options.additionalParameter !== undefined) {
      Object.keys(this.options.additionalParameter).forEach((key) => {
        sendable.append(key, this.options.additionalParameter[key]);
      });
    }

    xhr.onload = () => {
      var gist = (xhr.status >= 200 && xhr.status < 300) || xhr.status === 304 ? 'Success' : 'Error';
      var method = 'on' + gist + 'Item';
      this[method](fakeitem, null, xhr.status, null);

    };
    xhr.onerror = () => {
      this.onErrorItem(fakeitem, null, xhr.status, null);
    };


    xhr.onabort = () => {
      this.onErrorItem(fakeitem, null, xhr.status, null);
    };

    xhr.open("POST", this.options.url, true);
    xhr.withCredentials = false;
    if (this.options.headers) {
      for (var _i = 0, _a = this.options.headers; _i < _a.length; _i++) {
        var header = _a[_i];
        xhr.setRequestHeader(header.name, header.value);
      }
    }
    if (this.authToken) {
      xhr.setRequestHeader(this.authTokenHeader, this.authToken);
    }
    xhr.send(sendable);



    return xhr.onloadend = () => {
      var res = JSON.parse(xhr.response);
      if (res.outcome.success) {
        swal2.fire("Caricamento file", "Il file è stato caricato correttamente", "success").then((ev) => {
          if (ev.value) {
            this.docComponent.getCode();
            this.docComponent.uploader.queue = [];
            this.docComponent.codeControlUpload = "ND"
            this.docComponent.visible = false;
            return res
          }
        });
      }
      else {

        swal2.fire("Ops!", "Il file non è stato caricato", "warning").then((value) => {
          this.docComponent.getCode();
          this.docComponent.uploader.queue = [];
          this.docComponent.codeControlUpload = "ND"
          this.docComponent.visible = false;
          return res
        });
      }

    }
  };
}

