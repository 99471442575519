import { Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {ListService} from "../../../services/list.service";
import { LoggingService } from "../../../services/logging.service";
import { ToastrService } from 'ngx-toastr';
import swal2 from 'sweetalert2'

@Component({
  selector: 'app-user-profile-edit',
  templateUrl: './user-profile-edit.component.html',
  styleUrls: ['../../../app.component.css']
})
export class UserProfileEditComponent implements OnInit {

  private sub: any;
  serviceToCallPath;
  urlRolesPath = "/user/roles";
  urlUserPath = "/user";

  //form html
  @ViewChild('formUserProfile') userProfileForm: NgForm;
  //oggetto contenente dati component
  dataUser: any;
  roleList: any[] = new Array();

  errorMessage;

  constructor(private logger: LoggingService, private route: ActivatedRoute, public listService: ListService,  private router: Router,
    public toastr: ToastrService, 
    vcr: ViewContainerRef){
 //   this.toastr.setRootViewContainerRef(vcr);
  }

  ngOnInit() {
    //ricevo ID dal path
    this.sub = this.route.params.subscribe(async _ => {
      //   this.serviceToCallPath = "/user/"+ "9"; //params['id'];
      this.serviceToCallPath = "/user"
      this.getUser();
      await this.getRoles();
    });

    if (this.userProfileForm) {
      this.userProfileForm.statusChanges.subscribe(
        (status) => this.logger.log("Error:",status,200)
      );
    }
    
  }

  async getRoles() {
    this.roleList = await this.listService.manageListValues(this.urlRolesPath, 'Lista ruoli');
  }

  getNotify(){
    this.toastr.success('', 'Modifica completata!');
  }

  getError(){
    this.toastr.error('', 'Qualcosa è andato storto!');
  }

  getUser(){
      this.listService.getObject(this.serviceToCallPath).subscribe( (response) => {

        this.dataUser = response;
        this.logger.log("Response:",response,300);
     
      },    
      (error) => { 
        this.logger.log("Error",error,200);
   
      }
    );
  }

  changePwd(){
    this.router.navigate(['/change-pwd']);
  }


  onSubmit() : void {
    //Al submit modifico solo i 3 campi che posso modificare da frontend e lascio invariati gli altri
    this.dataUser["name"] = this.userProfileForm.value.nome; 
    this.dataUser["surname"] = this.userProfileForm.value.surname;
    this.dataUser["phone"] = this.userProfileForm.value.phone; 

    this.listService.edit(this.dataUser,this.urlUserPath).subscribe({
      next: response => {
        if (response.outcome.success === true) {
          this.getNotify();     
          this.logger.log("Response",response,200);
       
        } else {
          this.getError();
          this.errorMessage = response.outcome.code +  response.outcome.description
          swal2.fire("Attenzione", this.errorMessage, "warning");
        }
      },
      error: error => {
        this.logger.log("Error",error,200);
        swal2.fire("Errore", "Spiacente, si è verificato un errore tecnico.","error");
      }
    });
  }

}

