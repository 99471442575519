import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal'
import { CookieService } from 'ngx-cookie-service';

// App views
import {MainViewModule} from './views/main-view/main-view.module';
import {LayoutsModule} from './common/layouts/layouts.module';
import { UsersModule } from './views/users/users.module';
import { ActivitiesModule } from './views/activities/activity.module';

import { DocumentsModule} from './views/documents/documents.module';
import { WalletModule} from './views/wallet/wallet.module'

// App modules/components
import {AuthModule} from './views/auth/auth.module';
import {OrdersModule} from './views/orders/orders.module';

// App services
import { ChartsService } from './charts/charts.service';
import { LoggingService } from './services/logging.service';
import { ListService } from './services/list.service';
import { AuthService } from './services/auth.service';
import { UtilsService } from './services/utils.service';
import { LoadingService } from './services/loading.service';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';

// Factory
// import {httpFactory} from './http.factory';
import { UserProfileModule } from './views/user-profile/userProfile.module';
import { PrintersModule } from './views/printers/printers.module';
import { SalesModule } from './views/sales/sales.module';
import { VehiclesModule } from './views/vehicles/vehicles.module';
import { ObliteratorsModule } from './views/obliterators/obliterators.module';
import { LinesModule } from './views/lines/lines.module';
import { TripsModule } from './views/trips/trips.module';
import { TicketsModule } from './views/tickets/tickets.module';
import { StatisticsModule } from './views/statistics/statistics.module';
import { ResalesModule } from './views/resales/resales.module';
import { CompaniesModule } from './views/companies/companies.module';
import { VouchersModule } from './views/voucher/voucher.module';

import { StopsModule } from './views/stops/stops.module';


import { StopTimesModule } from './views/stop-times/stopTimes.module';
import { DateTripsModule } from './views/date-trips/dateTrips.module';

import { ResearchModule } from './views/research-trip-line/research.module';
import { WalletRechargeModule } from './views/wallet-recharge/walletRecharge.module';
import { SubscriptionsModule } from './views/subscription/subscription.module';
import { SoldService } from './services/soldHelper.service';
import { RecoverPdfModule } from './views/recover-pdf/recoverPdf.module';
import { registerLocaleData } from '@angular/common';
import localeit from '@angular/common/locales/it'
import {ToastrModule } from 'ngx-toastr';
import { DeskModule } from './views/desk/desk.module';
import { BlocksModule } from './views/blocks/blocks.module';
import { ZoneModule } from './views/zones/zones.module';
import { UtilityModule } from './views/utility/utility.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { InterceptedHttp } from './http.interceptor';
import { SalesChannelsModule } from './views/sales-channels/sales-channels.module';

registerLocaleData(localeit, 'it');

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [
        // Angular modules
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        // Views
        MainViewModule,
        AuthModule,
        OrdersModule,
        LayoutsModule,
        UsersModule,
        DateTripsModule,
        DocumentsModule,
        DeskModule,
        ActivitiesModule,
        WalletModule,
        UserProfileModule,
        PrintersModule,
        SalesModule,
        VehiclesModule,
        BlocksModule,
        ZoneModule,
        ObliteratorsModule,
        LinesModule,
        TripsModule,
        TicketsModule,
        VouchersModule,
        StatisticsModule,
        ResalesModule,
        CompaniesModule,
        StopsModule,
        StopTimesModule,
        ResearchModule,
        WalletRechargeModule,
        SubscriptionsModule,
        RecoverPdfModule,
        ToastrModule,
        UtilityModule,
        // Modules
        TabsModule.forRoot(),
        NgbModule,
        RouterModule.forRoot(ROUTES, { useHash: false }),
        ModalModule.forRoot(),
        SalesChannelsModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptedHttp,
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'it-IT' },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        ChartsService,
        LoggingService,
        AuthService,
        ListService,
        UtilsService,
        SoldService,
        CookieService,
        BsModalService,
        LoadingService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }

